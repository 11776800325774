<template>
  <section class="submit-assign-section">
    <div class="containe">
      <div class="content-area">
        <h1>Accessibility  Policy</h1>
        <br />
        <p>
          Accessibility is a fundamental aspect of all the educational
          technology applications developed by Edufide Inc. We are committed to
          making our applications accessible to all users, including those with
          disabilities. In compliance with the Web Content Accessibility
          Guidelines (WCAG) 2.0, we have developed the following accessibility
          policy.
        </p>
        <p>
          <strong>Our applications will be:</strong>
        </p>
        <div>

        <ul>
          <li>
            <span><strong>1. </strong></span>
            <strong>Perceivable:</strong> <p> All content in our applications will be presented in a
            clear and understandable format. All text will be readable by
            assistive technology, and images will have alt text descriptions.</p>
          </li>
          <li>
            <span><strong>2. </strong></span>
            <strong>Operable:</strong> <p> The applications will be navigable by keyboard, and all
            interactive elements will have a clear focus indicator. We will
            avoid using any elements that can cause seizures or other physical
            reactions.</p>
          </li>
          <li>
            <span><strong>3. </strong></span>
            <strong>Understandable:</strong> <p> The applications will be designed in a way that is
            easy to understand, regardless of a user's language or cognitive
            abilities. We will use clear and concise language and avoid using
            jargon or technical terms.</p>
          </li>
          <li>
            <span><strong>4. </strong></span>
            <strong>Robust:</strong> <p> We will use the latest web technologies to ensure the
            applications are compatible with a wide range of assistive
            technologies, such as screen readers and speech recognition
            software.</p>
          </li>
          <li>
            <span><strong>5. </strong></span>
            <strong>Bias-Free:</strong> <p> We will do our best to avoid introducing any biases in
            our applications. We will ensure that our applications do not use
            language or images that stereotype or discriminate against any group
            of people based on their race, ethnicity, gender, sexual
            orientation, disability, or any other characteristic.</p>
          </li>
          <li>
            <span><strong>6. </strong></span>
            <strong>Testing:</strong> <p> We will conduct regular accessibility testing to ensure
            that the applications are fully accessible. We will also gather
            feedback from users with disabilities to identify areas for
            improvement.</p>
          </li>
          <li>
            <span><strong>7. </strong></span>
            <strong>Accessibility support:</strong> <p> We will provide a way for users to report
            accessibility issues and receive support for using the applications
            with assistive technology.</p>
          </li>
          <li>
            <span><strong>8. </strong></span>
            <strong>Ongoing commitment:</strong> <p>  We are committed to ongoing accessibility
            efforts, and will continue to make improvements to our applications
            to ensure that they are accessible to all users. </p>
          </li>
        </ul>
        </div>
        <br />
        <div>
          <p>
            We are dedicated to providing inclusive and accessible educational
            technology applications that are free from bias. By following these
            guidelines and committing to ongoing accessibility efforts, we
            strive to make our applications a welcoming and inclusive
            environment for all users.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  },
};
</script>

<style scoped>
.pt-none {
  padding-top: 0;
}
.mt-25 {
  margin-top: 25px;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px;
}
.ml-23 {
  margin-left: 23px;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.r-color {
  color: #ff0000;
}
.flex-box {
  display: flex;
}
.flex-box span {
  margin-right: 8px;
}
.submit-assign-section {
  background: #f4f4f5;
  padding: 50px 15px 50px;
  min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
  width: 1140px;
  margin: 0 auto;
}
.submit-assign-section .content-area {
  text-align: left;
}
.submit-assign-section .content-area h1 {
  padding: 0;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
  font-size: 13px;
  color: #92969b;
  font-weight: 300;
  margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
  font-size: 16px;
  color: #2c3e50;
  line-height: 26px;
}
.submit-assign-section .content-area p span {
  color: #2e75bf;
}
.submit-assign-section .content-area .card {
  max-width: 320px;
  padding: 10px 15px;
  background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
  padding: 0;
}
.submit-assign-section .content-area .list-view {
  margin-bottom: 15px;
}
.submit-assign-section .content-area .list-view li {
  font-size: 15px;
  color: #2c3e50;
  list-style: disc;
  line-height: 30px;
}
.submit-assign-section .content-area .card ul li a {
  font-size: 15px;
  color: #2e75bf;
  font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
  color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
  color: #2c3e50;
  margin-right: 3px;
}
.submit-assign-section .content-area h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  text-decoration: underline;
}
.submit-assign-section .content-area h3 {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
.submit-assign-section .content-area img {
  margin-left: 23px;
  margin-bottom: 20px;
  border: 1px solid #d8dbdf;
}
.pt-55 {
  padding-top: 55px;
}
.count-details {
  width: 100%;
  max-width: 900px;
  background: #ffffff;
  border: 1px solid #cfd4d6;
}
.count-details .count-box {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #cfd4d6;
}
.count-details .count-box h3 {
  width: 50%;
  font-size: 15px;
  margin-bottom: 0;
  padding: 6px 10px 4px;
}
.count-details .count-box h3 span {
  font-weight: 400;
}
.count-details .count-box p {
  width: 50%;
  font-size: 15px;
  margin-bottom: 0 !important;
  padding: 7px 10px 5px !important;
  border-left: 1px solid #cfd4d6;
}
@media (min-width: 920px) and (max-width: 1150px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 919px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
  .submit-assign-section .content-area img {
    margin-left: 0;
    max-width: 100%;
  }
  .count-details .count-box {
    flex-direction: column;
  }
  .count-details .count-box h3 {
    width: 100%;
  }
  .count-details .count-box p {
    width: 100%;
    border-left: 0;
  }
}
@media (min-width: 340px) and (max-width: 767px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
  .submit-assign-section .content-area img {
    margin-left: 0;
    max-width: 100%;
  }
  .submit-assign-section .content-area h2 {
    line-height: 30px;
  }
  .submit-assign-section .content-area h1 {
    font-size: 22px;
    line-height: 34px;
  }
  .ml-23 {
    margin-left: 0;
  }
  .ml-50 {
    margin-left: 0 !important;
  }
  .pt-55 {
    padding-top: 20px;
  }
  .count-details .count-box {
    flex-direction: column;
  }
  .count-details .count-box h3 {
    width: 100%;
  }
  .count-details .count-box p {
    width: 100%;
    border-left: 0;
  }
  .ml-15 {
    margin-left: 0 !important;
  }
  .ml-40 {
    margin-left: 0 !important;
  }
}
</style>