<template>
<div>
  <main class="js-reveal">
    <!-- ============================================================= SECTION – HERO ============================================================= -->

    <section id="hero">
      <div id="owl-main" class="owl-carousel owl-one-item">
        <div
          class="item img-bg-center edufideblue"
          style="background-image: url(images/art/slider01.jpg);"
        >
          <div class="container">
            <div class="caption vertical-center text-center">
              <h1 class="fadeInDown-1 lmedium-bg light-color text-center">Assign2</h1>
              <p
                class="fadeInDown-2 light-color"
              >A hyper-efficient collaborative grading tool for online, in-class and paper based assessments</p>
              <div class="fadeInDown-3">
                <a href="contact" class="btn btn-large">Get a demo</a>
              </div>
              <!-- /.fadeIn -->
            </div>
            <!-- /.caption -->
          </div>
          <!-- /.container -->
        </div>
        <!-- /.item -->
      </div>
      <!-- /.owl-carousel -->
    </section>

    <!-- ============================================================= SECTION – HERO : END ============================================================= -->

    <!-- ============================================================= SECTION – SERVICES ============================================================= -->


    <!-- ============================================================= SECTION – SERVICES : END ============================================================= -->

    <!-- ============================================================= SECTION – HOWITWORKS ============================================================= -->

    <section id="howitworks">
      <div class="container inner-top inner-bottom-sm">
        <div class="row">
          <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header>
              <h1>How it works for assignments</h1>
            </header>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row inner-top-sm text-center">
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/work01.jpg"> -->
            </div>
            <h2>Create</h2>
            <p
              class="text-small"
            >Create an assignment online in Assign2 with multiple questions. Markdown and LaTeX are supported.</p>
          </div>
          <!-- /.col -->
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-669646034-2048x2048.jpg"> -->
            </div>
            <h2>Upload</h2>
            <p
              class="text-small"
            >Students upload their work as either PDF or Image or take pictures with their cell phone camera.</p>
          </div>

          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Grade</h2>
            <p
              class="text-small"
            >Assign2 grading interface is hyper-efficient. Assessment can be graded very quickly. Multiple graders can work at the same time.</p>
          </div>
          <!-- /.col -->

          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Return</h2>
            <p
              class="text-small"
            >Once graded assignments can be returned to students for review. All the feedback and scores are included.</p>
          </div>
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Analyse</h2>
            <p
              class="text-small"
            >Assign2 provides student performance analysis for assessments. Statistics on grading speed are also provided.</p>
          </div>
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Improve</h2>
            <p
              class="text-small"
            >Assign2 provides analysis on the design of the assessment itself and the efficient question is in differentiating between students.</p>
          </div>          
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>

    <section id="howitworks">
      <div class="container inner-top inner-bottom-sm">
        <div class="row">
          <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header>
              <h1>How it works for in person exams</h1>
            </header>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row inner-top-sm text-center">
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/work01.jpg"> -->
            </div>
            <h2>Create</h2>
            <p
              class="text-small"
            >Create an exam by uploading a pdf file. This file can be authored in your favourite editor.</p>
          </div>
          <!-- /.col -->
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-669646034-2048x2048.jpg"> -->
            </div>
            <h2>Print</h2>
            <p
              class="text-small"
            >Assign2 generates as many printable exams as you need. Both single sided and double sided printing is supported.</p>
          </div>

          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Deliver</h2>
            <p
              class="text-small"
            >Exams are distributed and collected in class. Assign2 adds a standard ID sheet on the first page for students to fill out their info.</p>
          </div>
          <!-- /.col -->

          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Scan</h2>
            <p
              class="text-small"
            >Exams are scanned into Assign2. This process matches each student to their exam automatically. Multiple choice questions are graded automatically.</p>
          </div>
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Grade</h2>
            <p
              class="text-small"
            >Assign2 grading interface is hyper-efficient. Assessment can be graded very quickly. Multiple graders can work at the same time.</p>
          </div>
          <!-- /.col -->

          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Return</h2>
            <p
              class="text-small"
            >Once graded assignments can be returned to students for review. All the feedback and scores are included.</p>
          </div>
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Analyse</h2>
            <p
              class="text-small"
            >Assign2 provides student performance analysis for assessments. Statistics on grading speed are also provided.</p>
          </div>
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Improve</h2>
            <p
              class="text-small"
            >Assign2 provides analysis on the design of the assessment itself and how efficient each question is in differentiating between students.</p>
          </div>    
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>



    <!-- ============================================================= SECTION – HOWITWORKS : END ============================================================= -->

    <!-- ============================================================= SECTION – PRICING TABLES ============================================================= -->

    <section id="pricing-tables">
      <div class="container inner">
        <div class="row">
          <!-- <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header>
              <h1>Transparent, comprehensive pricing, no surprises</h1>
              <p></p>
            </header>
          </div> -->
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row pricing col-4-custom">
          <div class="col-xl-4 col-md-6 inner-top-sm">
            <div class="plan">
              <header>
                <h2>Free Class</h2>

                <div class="price">
                  <span class="currency">$</span>
                  <span class="amount">0</span>
                  <span class="period">/ Class</span>
                </div>
                <!-- /.price -->

                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">
                <li>
                  <i class="fas fa-users"></i>1 class
                  <br />
                  <small>per instructor</small>
                </li>
                <li>
                  <i class="icon-switch"></i>No LMS integration
                </li>
              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>          
          <div class="col-xl-4 col-md-6 inner-top-sm">
            <div class="plan">
              <header>
                <h2>Single Class</h2>

                <div class="price">
                  <span class="currency">Contact us</span>
                  <!-- <span class="amount">1</span> -->
                  <!-- <span class="period">/ Student / Class</span> -->
                </div>
                <!-- /.price -->

                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">
                <li>
                  <i class="fas fa-users"></i>Per class
                  <br />
                  <small>per class</small>
                </li>
                <li>
                  <i class="icon-switch"></i>LTI integration
                </li>
              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>
          <!-- /.col -->


          <!-- /.col -->


          <!-- /.col -->

          <div class="col-xl-4 col-md-6 inner-top-sm">
            <div class="plan">
              <header>
                <h2>Campus Wide</h2>

                <div class="price">
                  <span class="currency">Contact us</span>
                  <!-- <span class="amount">1</span> -->
                  <!-- <span class="period">/ FTE / Yr</span> -->
                </div>
                <!-- /.price -->
                <div>
                  <span class="amount">
                    <!-- <small>Valid for signups before Fall 2022</small> -->
                  </span>
                </div>
                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">
                <!-- <li>
                  <i class="fas fa-dollar-sign"></i>35,000 Max
                </li> -->
                <li>
                  <i class="icon-docs"></i>Unlimited usage campus wide
                  <br />
                  <small>no limits on usage</small>
                </li>
                <li>
                  <i class="icon-switch"></i>LMS + LTI integration
                </li>

              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
        <div class="row">
          <div class="col-lg-12 col-md-9 mx-auto text-left">
            <h5 class="font-italic dark-color">
Published price structure may change, but will be honoured for existing contracted clients. Our price changes for current clients are tied to the US inflation per year.              </small>
            </h5>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>


    <!-- ============================================================= SECTION – PRICING TABLES : END ============================================================= -->
  </main>
</div>
</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style scoped>
.price {
  height: 50px;
}
.features {
  height: 190px;
}
</style>