<template>
  <header>
    <div class="navbar">
      <div class="navbar-header">
        <div class="container">
          <!-- <ul class="info">
            <li>
              <a href="#">
                <i class="icon-mail-1 contact"></i> info@edufide.com
              </a>
            </li>
            <li>
              <i class="icon-mobile contact"></i> 1 833 EDU FIDE
            </li>
          </ul> -->
          <!-- /.info -->

          <!-- <ul class="social">
						<li><a href="#"><i class="icon-s-facebook"></i></a></li>
						<li><a href="#"><i class="icon-s-gplus"></i></a></li>
						<li><a href="#"><i class="icon-s-twitter"></i></a></li>
						<li><a href="#"><i class="icon-s-pinterest"></i></a></li>
						<li><a href="#"><i class="icon-s-behance"></i></a></li>
						<li><a href="#"><i class="icon-s-dribbble"></i></a></li>
          </ul>-->

          <!-- /.social -->

          <!-- ============================================================= LOGO MOBILE ============================================================= -->

          <a class="navbar-brand" href="/">
            <img src="images/logo.svg" class="logo" alt />
          </a>

          <!-- ============================================================= LOGO MOBILE : END ============================================================= -->

          <a
            class="navbar-toggler btn responsive-menu float-right"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <i class="icon-menu-1"></i>
          </a>
        </div>
        <!-- /.container -->
      </div>
      <!-- /.navbar-header -->
      <div class="yamm">
        <div class="navbar-collapse collapse">
          <div class="container">
            <!-- ============================================================= LOGO ============================================================= -->

            <a class="navbar-brand" href="/">
              <img src="images/logo.svg" class="logo" alt />
            </a>

            <!-- ============================================================= LOGO : END ============================================================= -->

            <!-- ============================================================= MAIN NAVIGATION ============================================================= -->

            <ul class="nav navbar-nav float-right">
              <li class="dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">Products</a>

                <ul class="dropdown-menu">
                  <li>
                    <a href="assign2">Assign2</a>
                  </li>
                  <!-- <li><a href="testminder.html">Testminder</a></li> -->
                  <li>
                    <a href="stream2">Stream2 (beta)</a>
                  </li>
                  <li>
                    <a href="smartexammonitor">Smart Exam Monitor (beta)</a>
                    <!-- <a href="#">
                      Smart Exam Monitor
                      <br />
                      <small>(coming soon)</small>
                    </a> -->
                  </li>
                </ul>
                <!-- /.dropdown-menu -->
              </li>
              <!-- /.dropdown -->

            
              <!-- /.dropdown -->

              <!-- <li class="dropdown">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">About</a>

                <ul class="dropdown-menu">
                  <li>
                    <a href="values">Core values</a>
                    
                  </li>
                </ul>
              </li> -->
              <!-- /.dropdown -->
              <li >
                <a href="knowledgebase" >Help</a>


                <!-- /.dropdown-menu -->
              </li>

              <li >
                <a href="contact" >Contact</a>


                <!-- /.dropdown-menu -->
              </li>
              <!-- /.dropdown -->
            </ul>
            <!-- /.nav -->

            <!-- ============================================================= MAIN NAVIGATION : END ============================================================= -->
          </div>
          <!-- /.container -->
        </div>
        <!-- /.navbar-collapse -->
      </div>
      <!-- /.yamm -->
    </div>
    <!-- /.navbar -->
  </header>
</template>

<script>
export default {};
</script>

<style>
</style>