<template>
  <main>
    <!-- ============================================================= SECTION – HERO ============================================================= -->

    <section id="contact-form">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header class="text-center">
              <h1 class="text-center mt-2">Get started</h1>
              <p>Let us show you how Assign2 can help you grade 2-4 times faster than traditional grading. Assign2 supports both student submitted assignments and paper exams. Best of all, Assign2 is free for your first course.</p>
            </header>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 outer-top-md inner-right-sm">

                <h2>Book a walk-through</h2>
                <p>Quick, 15 minute, online demo of Assign2. We will walk through setting up your free course.</p>
                <vue-calendly url="https://calendly.com/edufide/15demo?hide_event_type_details=1&hide_gdpr_banner=1" ></vue-calendly>

              </div>
              <!-- ./col -->

              <div class="col-md-6 outer-top-md inner-left-sm border-left demorighvideopanel">
                <h2>What is Assign2</h2>
                <iframe id="ytVideo" style="width: 100%; height: 400px;" src="https://www.youtube.com/embed/AIuwrVPHumM?enablejsapi=1&origin=https://edufide.com&rel=0" title="Introducting Assign2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <!-- /.contacts -->
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      formData: {
		  name: "",
		  email: "",
		  subject: "",
		  message: "",
	  },
	  emailret: ""
    };
  },
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  },
  methods: {
	  postForm: function () {
		  console.log(this.formData)
		  axios.post("https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/", this.formData). then(({data}) => {
			//   console.log(data)
			this.formData.name = ""
			this.formData.email = ""
			this.formData.subject = ""
			this.formData.message = ""
			this.emailret = "Message sent. Someone will contact you shortly."
		  })
	  }

  },
};
</script>

<style>
@media only screen and (max-width: 760px) {
  .demorighvideopanel {
    margin-top: -70px;
  }
}
</style>