<template>
  <main>
    <!-- ============================================================= SECTION – HERO ============================================================= -->

    <section
      id="hero"
      class="light-bg img-bg-bottom img-bg-softer"
      style="background-image: url(images/art/slider03.jpg);"
    >
      <div class="container inner">
        <div class="row">
          <div class="col-lg-8 col-md-9">
            <header>
              <h1>idealistically funded Edtech</h1>
              <!-- <p>Magnis modipsae que voloratati andigen daepeditem quiate aut labor. Laceaque quiae
              sitiorem rest non restibusaes es dem tumquam.</p>-->
            </header>
          </div>
          <!-- /.col -->
        </div>
        <!-- ./row -->
      </div>
      <!-- /.container -->
    </section>

    <!-- ============================================================= SECTION – HERO : END ============================================================= -->

    <!-- ============================================================= SECTION – SERVICES ============================================================= -->


			


    <section id="portfolio-post">
      <div class="container inner">
					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">Who or what is Edufide?</h2>
								<p class="text-normal eftextalign">Edufide Inc is a start-up founded by members of the higher ed community. For decades, we have witnessed the
								philosophical mismatch between technology vendors' business models and the universities.
								
              Edufide is our attempt to change that.</p>
              <p class="text-normal eftextalign">
                At our core, we are still members of the higher-ed community, some of us still work for large universities.
            
              </p>
								<p class="text-normal eftextalign"></p>
							</header>
              <!-- <a href="#" class="btn">Check out our products and services</a> -->
						</div><!-- /.col -->
					</div><!-- /.row -->

					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">What is the problem with the status-quo?</h2>
								<p class="text-normal eftextalign">In an ideal world, multiple universities would get together, 
									fund development of EdTech products and share them with everyone. Right people would be hired to 
									design and scale these products. In practice, that has not always worked. Most "open-source" products stagnate 
									or atleast fall behind commercial competitors. Open-source does not mean free, as 
									infrastructure costs and skills needed to run enterprise wide systems get very high, very quickly. 
								</p>
								<p>
								
								</p>
							</header>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">How is Edufide solving these issues?</h2>
								<p class="text-normal eftextalign">
								We have created a business model that lets us collaborate with universities to build ideal 
								products. We use our internally designed, cost efficient cloud platform to build these products. 
								We are then able to provide these solutions to multiple institutions in a very cost effective 
								manner.
								</p>
							</header>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">How is Edufide funding product development?</h2>
								<p class="text-normal eftextalign">Edufide is a bootstrapped start-up, i.e it is funded by its founders. 
                  This allows us the freedom of making all the decisions ourselves without the pressures that come with venture funding.</p>
				  			<p class="text-normal eftextalign">While we are very efficient with our resources, we need to grow to become truly sustainable.</p>
							</header>
						</div>
					</div>




					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">How does Edufide price its products?</h2>
								<p class="text-normal eftextalign">As a "core value", we price our products to cover development, maintenance, hosting costs and a reasonable profit (all of which is reinvested back into further development right now). We have made investments in developing a technology stack with cost efficiency at par with silicon valley companies. We have proven the efficiency of this stack with one of the largest universities in Canada.</p>
							</header>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">How do we know that Edufide won't raise their prices?</h2>
								<p class="text-normal eftextalign">We are so confident about our pricing that we contractually peg our annual price changes to official inflation.
                  We think this is fair and can not get more black and white.</p>
								<p class="text-normal eftextalign">That said, in our pricing model, the price for NEW CLIENTS can go up to a certain point. The chart below is a rough illustration of how it works.</p>                  
							</header>
						</div>
					</div>

          <div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
            <figure>
              <img
                src="images/art/im5.png"
                alt
              />
            </figure>
						</div>
					</div>


					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">How does Edufide guarantee the same level of quality and service as other vendors?</h2>
								<p class="text-normal eftextalign">Being hyper-efficient about everything we do is the key. As we are growing we are working closely with our clients to make sure the right level of service is provided.</p>
							</header>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">Why are you doing this?</h2>
								<p class="text-normal eftextalign">We are passionate about education. In a way, this is our contribution.
                  We have seen universities "ration" educational products, such collaborative grading solutions and proctoring tools. To us, that is not ok.
                  There has to be a better way.</p>
                <p class="text-normal eftextalign">The bigger question is, why not develop open source products. The simple answer is that, in ed-tech, open source has not worked consistently. Open source platforms almost always stagnate, or atleast can not compete with the best out there. Open source also does not mean free. The way we have designed our products and pricing, our products are cheaper than hosting and maintenance of a similar open source product.</p>
							</header>
						</div>
					</div>



					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">What is the founder's licence?</h2>
								<p class="text-normal eftextalign">Founders licence is a special licence that gives certain institutions a deeper integration with our team. 
									They give direct input into products and their features, and are our beta testers. 
									In return, we provide very favourable pricing to the founder's licence holders.
                </p>
							</header>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 inner-top-xs inner-right-xs">
							<header>
								<h2 class="eftextalign">This is great, how do I help/join Edufide?</h2>
								<p class="text-normal eftextalign">All you have to do is try our products. If you like them, advocate for their use on your campus. 
                </p>
							</header>
						</div>
					</div>



      </div>
      <!-- /.container -->
    </section>

    <!-- ============================================================= SECTION – SERVICES : END ============================================================= -->
  </main>
</template>

<script>
export default {

  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }

};
</script>

<style>
</style>