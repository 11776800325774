<template>

<div>
  <main class="js-reveal">
    <section class="landing-section">
        <div class="container">
           <h1>How To Create A Course</h1>
            <h2> Course Creation</h2>
            <p>
                Course creation for the Assign2 application requires the instructor to log in to 
                <a href="https://assign2.edufide.com " target="_blank"> https://assign2.edufide.com</a>.
                Click the <strong>New Course button </strong>available at the top right corner of the <strong>Dashboard</strong>. 
                Enter the details that include a <strong>Course Name </strong>and an optional <strong>Code</strong>. 
                The instructor must enter a code to make the course accessible for more than 40 users if required. 
                Click on the <strong>Create</strong> button afterward and the created course can be shown under the heading of
                <strong> Inactive Courses </strong>on the dashboard.

            </p>
            <div class="pb-4">
                <img src="images/create-course/create-course.png" class="mb-2" alt="">
            </div>
            <br>
            <p>
            To enter the <strong> course description </strong> and change the <strong> cover image</strong>, 
            click on the edit icon available under the course name. 
            </p>
            <div class="pb-4">
                <img src="images/create-course/course-title.png" class="mb-2" alt="">
            </div>
            <br>

            <p>
            Once done, click on the course title to enter course details. 
            </p>
            
            <h2> Students Enrollment </h2>
            <p> 
            To enroll students in the course, click on the<strong>  People/people
            icon</strong>  at the navigation panel available on the left side of the page. Follow the given steps to enroll any user:
            </p>
            <ul> 
            <li>
            Click on the <strong> +People</strong>  button. Choose the option <strong> CSV file </strong> from the popped-up panel. 
            </li>
            <li>
            Drag and drop the file or upload it from the system. Whole list will appear in the window.
            </li>
            <li>
            Scroll down the list and provide the required details. Click on each field 
            separately to choose a relevant option.  
            </li>
            <li>
            Provide the person’s role (Student/teacher/TA) in the course and Click <strong>  Invite </strong> to get the enrollment done. 
            </li>
            </ul>
            <div class="article-box">
                <p>
                    <strong> Note: </strong> Assign2 app can support 2 ID’s at a time.
                </p>
            </div>

                <div class="pb-4">
                <img src="images/create-course/enroll-students.png" class="mb-2" alt="">
            </div>
            <br>

            

           <h2> Course Visibility </h2>
            <p>
            On the top right corner of the dashboard, there’s a button with an <strong> eye </strong> on it.
             This option can be used to make the course visible/invisible for the students as needed.
            The course will appear under <strong> Active Courses </strong>on the dashboard upon making it 
            visible.
            </p>

            <h2>Module Addition </h2>
            <p>
            To add main sections to the course, go back to the dashboard. Click on the <strong> Module  </strong> button and give it a  <strong> title </strong> . 
            </p>

            <div class="pb-4">
                <img src="images/create-course/create-module.png" class="mb-2" alt="">
            </div>
            <br>
            <p>
            Instructors can add multiple activities under a single module to give students detailed instructions 
            about every task. To add an activity, click on the Add button as shown
            </p>
            <div class="pb-4">
                <img src="images/create-course/add-activity.png" class="mb-2" alt="">
            </div>
            <br>
             
             <p>
            Give a title to the activity and add a quick description to make the activity clear to students.
             Select an activity type from one of the following:
            </p>

            <ul>
            <li>
            <strong>Assign2 assignment: </strong>This option needs to be selected to create an assignment
            </li>
            <li>
            <strong>Text label:</strong> This option is useful to provide instructions to students for any module or activity.
            </li>
            </ul>

            <p>
            Modules and activities can be rearranged if required.
            Click and hold the dots icon available on the left of each module/activity title and just drag and drop. Activities can only be 
            rearranged if present within the same module. 
            </p>
            <div class="article-box">
                <p>
                <strong>Note:</strong> Just like the whole course, each module and activity has its separate option for visibility in front of it.    
                </p>
            </div>
            <p>
            Clicking on the activity title will direct the instructor to the Assign2 app to set up an assignment. 
            Follow the <a href=""> Create assignment </a>guide for the next step. 
            <!-- Link needs to be added yet--> 
            </p>
            <div class="pb-4">
                <img src="images/create-course/course-img.png" class="mb-2" alt="">
            </div>
            <br>

        </div>
    </section>
  </main>
</div>

</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style scoped>
h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    padding: 50px 0 30px;
    text-transform: uppercase;
}
.landing-section p {
    font-size: 16px;
    color: #333333;
    text-align: left;
    margin-bottom: 25px;
}
.landing-section p a {
    color: #2e75bf;
    border-bottom: 0;
    text-decoration: none;
}
.landing-section h2 {
    margin: 0;
    padding-top: 0;
    font-size: 26px;
    color: #525252;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    padding-bottom: 25px;
}
.landing-section img {
    width: 60%;
}
.article-box {
    padding: 20px 24px;
    margin-bottom: 30px;
    background: #f4f4f4;
    border-left: 2px solid #dddddd;
}
.article-box ul li {
    margin-bottom: 10px;
}
.article-box ul li:last-of-type {
    margin-bottom: 0;
}
ul {
    margin: 0;
    padding: 0 0 0 30px;
}
.landing-section ul li {
    font-size: 16px;
    color: #333333;
    text-align: left;
    list-style: disc;
    margin-bottom: 20px;
}
.landing-section ul li a {
    color: #2e75bf;
    padding: 0 !important;
    font-size: 16px !important;
    display: inline-block !important;
}
h3 {
    padding: 0;
    font-size: 20px;
    margin: 0 0 20px;
    color: #525252;
    font-weight: 500;
    text-align: left;
}
.landing-section a {
    display: inline;
    font-size: 16px;
    color: #2e75bf;
    text-align: left;
    padding-bottom: 15px;
}
h4 {
    margin: 0;
    font-size: 18px;
    color: #525252;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    h1 {
        font-size: 24px;
        line-height: 30px;
        padding: 30px 0 20px;
    }
    .landing-section p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    ul {
        padding: 0 0 0 15px;
    }
    .landing-section ul li {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .landing-section ul li a {
        font-size: 14px !important;
    }
    .landing-section h2 {
        font-size: 20px;
        line-height: 22px;
        padding-bottom: 15px;
    }
    .landing-section p a {
        padding-bottom: 0;
    }
    .landing-section h3 {
        padding-top: 0;
    }
    .landing-section h4 {
        font-size: 14px;
    }
}

</style>