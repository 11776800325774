<template>

    <section class="submit-assign-section">
        <div class="containe">
            <div class="content-area">
                <h1>Creating In-Class Paper (PDF) Exams to be Graded Online Using Assign2</h1>
                <p>
                    Instructors can use Assign2 to grade exams or assignments completed by students on paper 
                    in class. The streamlined grading workflows in Assign2 can be a time-saving method 
                    for delivering in-person, long-form assessments or exams.
                </p>
<div class="ytcontainer">
<iframe class="ytvideo" width="640" height="360" src="https://www.youtube.com/embed/bTRsnHPdmmk" title="Creating an in-person exam on Assign2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
                <div class="card">
                    <h5>Contents:</h5>
                    <ul>
                        <li>
                            <a href="#creatingPDF">
                                <span>1</span>
                                Creating Paper-based PDF Assignments
                            </a>
                        </li>
                        <li>
                            <a href="#printingDistributing">
                                <span>2</span>
                                Printing Assignments
                            </a>
                        </li>
                        <li>
                            <a href="#scanningUploading">
                                <span>3</span>
                                Scanning and Uploading Assessments
                            </a>
                        </li>
                        <li>
                            <a href="#dealingPartial">
                                <span>4</span>
                                Partially Scanned Exams
                            </a>
                        </li>
                        <li>
                            <a href="#assigningExam">
                                <span>5</span>
                                Unassigned exams
                            </a>
                        </li>
                        <li>
                            <a href="#markingExams">
                                <span>6</span>
                                Marking exams
                            </a>
                        </li>
                        <li>
                            <a href="#dashboardOverview">
                                <span>7</span>
                                Dashboard Statistics Overview
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- // -->
                <div class="pt-55" id="creatingPDF">
                    <h2>Creating PDF assessments:</h2>
                    <p>
                        <strong>
                        The starting point for this type of assessment is to have the questions prepared 
                        in an existing PDF template file.

                        </strong>
                    </p>
                    <div>
                        <div class="flex-box">
                            <span>1.</span>
                            <p>
                                With that in hand, instructors should begin by 
                                adding an Assign2 tool to their course, 
                                ensuring that the 'Grade' points selected matches what the eventual points 
                                total will be for the assessment. Once the tool has been added, click the 
                                tool link in the course and select <strong>In Person Exam</strong>:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-1a.png" width="584" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>2.</span>
                            <p>
                                Next, upload the PDF version of your exam file by drag-dropping or browsing. 
                                This file should contain all the questions and space for students to complete 
                                their answers:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-2a.png" width="589" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>3.</span>
                            <p>
                                Once uploaded, the PDF is opened in a preview mode that first shows the cover 
                                page for the eventual student copies:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-3a.png" width="907" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>4.</span>
                            <p>
                                Scrolling down, instructors will then create the question placeholders to be 
                                linked to the Assign2 grading interface. To add a question, click 
                                <strong>Add Question</strong> then drag-and-drop the blue question marker over 
                                top of the question in the underlying template, then enter the point-value for 
                                the question to match the assessment item:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-4a.png" width="805" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>5.</span>
                            <p>
                                Then repeat this process for every question in the assessment and once 
                                complete, scroll down and click <strong>Save</strong>.
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-5a.png" width="797" alt="">
                    </div>
                </div>
                <!-- /// -->
                <div class="pt-55" id="printingDistributing">
                    <h2>Printing and Distributing Assessments to Students:</h2>
                    <p>
                        To provide an assessment to students, instructors must now download and print copies 
                        for their students. When printing, instructors should:
                    </p>
                    <ul class="list-view">
                        <li>
                            select their printer's option to "Fit to page" or "Scale to fit - Print entire 
                            image" to ensure that the complete Assign2 barcode gets added to each page; AND
                        </li>
                        <li>
                            ensure they print the exam in portrait orientation.
                        </li>
                    </ul>

                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>1.</span>
                            <p>
                                From the dashboard page, open the left nav menu using the icon at the top left, 
                                then select <strong>Download exams</strong>:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-6a.png" width="487" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>2.</span>
                            <p>
                                Click <strong>Generate extra exams:</strong>
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-7a.png" width="865" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>3.</span>
                            <p>
                                On the 'Generate Exams' overlay, specify a file prefix and enter the number of 
                                exams required for printing, then click <strong>Generate</strong>:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-8a.png" width="608" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>4.</span>
                            <p>
                                This will produce a single zip file that can be downloaded and printed in a 
                                single job, creating the specified number of copies. This operation can be 
                                performed as many times as required - eg. for students in different sections, 
                                for students completing deferred exams, etc. Using a different file prefix for 
                                these cases can be useful in keeping the various printed versions separate. 
                                <strong>Note that the printed copies generated from exam file can only be used once</strong> - 
                                the barcode printed on each exam is how the system identifies and collates scanned, 
                                completed copies and associates them with the students who have completed them):
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-9a.png" width="732" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>5.</span>
                            <p>
                                Once printed, these exams would now be given to students in-class who will 
                                fill in their name, and student ID number on their copy and complete 
                                their assessment:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-10.png" width="883" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <p class="ml-23">
                                Once exams have been created and generated these counts will be added to the 
                                assessment dashboard to the right of the count of students in a course:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-overview-1.png" width="865" alt="">
                    </div>
                </div>
                <!-- /// -->
                <div class="pt-55" id="scanningUploading">
                    <h2>Scanning and Uploading Assessments:</h2>
                    <p>
                        Once the student's completed work has been collected, instructors will need to scan 
                        and upload the paper copies for grading:
                    </p>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>1.</span>
                            <p>
                                Scanning can be completed using standard office printer/scanners including 
                                continuous feed devices that output single PDF files. 
                                <strong>Scanning should occur at 200dpi resolution or higher and should be done on a darker setting.</strong> 
                                The resulting PDF file containing <strong>any number of individual student copies</strong> 
                                can be uploaded by opening the left nav menu via the icon at top left, then selecting 
                                <strong>Upload exams</strong>:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-11b.png" width="570" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>2.</span>
                            <p>
                                PDF files can be drag-dropped (or selected by browsing) through the dialog on 
                                the right. <strong>Please note</strong>: this process can be completed as many 
                                times as required based on the different scanned files. Once <strong>Upload</strong> 
                                is clicked, files are added to the files list on the left and the system begins 
                                processing them with progress tracked in the center:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-12a.png" width="883" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>3.</span>
                            <p>
                                Once the upload has finished scanning, the upload counts will be added to 
                                <strong>'Fully Scanned'</strong> counts on the dashboard:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-overview-3.png" width="780" alt="">
                    </div>
                    <!-- // -->
                    <p class="ml-23">
                        Scanning problems will be identified either from:
                    </p>
                    <!-- // -->
                    <div class="ml-50">
                        <div class="flex-box">
                            <span>1.</span>
                            <p>
                                the <strong>Upload Exam File</strong> tab - from the <strong>Upload Exam File</strong> 
                                tab, open the failed pages interface by clicking on the document icon: 
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-issues-1d.png" width="793" alt="">
                    </div>
                    <!-- // -->
                    <div class="ml-50">
                        <div class="flex-box">
                            <span>2.</span>
                            <p>
                                or from the main <strong>Dashboard</strong> - from the <strong>Dashboard</strong>, 
                                open <strong>Partially Scanned</strong>: 
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-issues-1e (1).png" width="534" alt="">
                    </div>
                </div>
                <!-- /// -->
                <div class="pt-55" id="dealingPartial">
                    <h2>Dealing with Partial Exam Uploads:</h2>
                    <p>
                        Images of any scanned but unmatched problem pages can be reviewed by clicking their 
                        link to try and resolve any issues manually by clicking the <strong>Partially Scanned</strong> 
                        icon. This page list all of the scanned pages for any incomplete or partial exams with a list 
                        of all pages within each specific exam also displayed, allowing the identification of any 
                        missing pages. Clicking on the page name will display a specific page if further review is 
                        necessary. You may have to go back to the original paper exam to verify the student and the 
                        associated exam:
                    </p>
                    <img src="images/create-print-exam/ass2-pdf-issues-1f.png" width="796" alt="">
                    <p>
                        If a barcode is missing or obscured you can view the page by clicking its link, and 
                        enter the barcode by clicking <strong>Enter Barcode</strong>: 
                    </p>
                    <img src="images/create-print-exam/ass2-pdf-issues-1g.png" width="796" alt="">
                    <p>
                        Enter the barcode, and click <strong>Process Page</strong>:
                    </p>
                    <img src="images/create-print-exam/ass2-pdf-issues-1h.png" width="560" alt="">
                </div>
                <!-- /// -->
                <div class="pt-55" id="assigningExam">
                    <h2>Assigning Exams to Students:</h2>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>1.</span>
                            <p>
                                If students don't correctly bubble in their Student ID, their exams can be manually 
                                associated with their student ID by clicking the link for 
                                <strong>Not Assigned</strong>:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-issues-1e.png" width="534" alt="">
                    </div>
                    <!-- // -->
                    <div>
                        <div class="flex-box">
                            <span>2.</span>
                            <p>
                                To link unassigned exams to particular students, the completed exam is 
                                displayed in a preview window. If an exam author can be identified, select 
                                their name from the dropdown list and click <strong>Assign Exam</strong>:
                            </p>
                        </div>
                        <img src="images/create-print-exam/ass2-pdf-issues-3a.png" width="797" alt="">
                    </div>
                </div>
                <!-- /// -->
                <div class="pt-55" id="markingExams">
                    <h2>Marking Student Exams:</h2>
                    <p>
                        The Assign2 grading interface is accessed through the top left nav tray by clicking 
                        <strong>Exam Marking</strong>. Any exams that have <strong>ANY</strong> of their 
                        pages scanned will show up in the grading interface. The grading functions and 
                        workflows are the exact same as for online Assign2 assessments:
                        
                    </p>
                    <img src="images/create-print-exam/ass2-pdf-14a.png" width="255" alt="">
                    <p>
                        If multiple choice questions have been added to the students answer 
                        sheets, these are scored automatically.
                    </p>
                </div>
                <!-- /// -->
                <div class="pt-55" id="dashboardOverview">
                    <h2>Dashboard Overview:</h2>
                    <p>
                        The dashboard allows instructors to see their grading progress at a glance and overall 
                        details about their assessment:
                    </p>
                    <img src="images/create-print-exam/ass2-overview-3a.png" width="764" alt="">
                    <p>
                        Specific count details:
                    </p>
                    <div class="count-details">
                        <div class="count-box">
                            <h3>Scanned</h3>
                            <p>Count of submitted scanned exams</p>
                        </div>
                        <!-- // -->
                        <div class="count-box">
                            <h3>Marked</h3>
                            <p>Running total of marked exams</p>
                        </div>
                        <!-- // -->
                        <div class="count-box">
                            <h3>Students</h3>
                            <p>Number of students listed in the LMS course</p>
                        </div>
                        <!-- // -->
                        <div class="count-box">
                            <h3>Exams Generated</h3>
                            <p>Number of exams generated for printing</p>
                        </div>
                        <!-- // -->
                        <div class="count-box">
                            <h3>Fully Scanned <span>(only appears when exams are scanned)</span></h3>
                            <p>Number of complete scans matched to students</p>
                        </div>
                        <!-- // -->
                        <div class="count-box">
                            <h3>Partially Scanned <span>(only appears if these exist)</span></h3>
                            <p>Incomplete exams where pages were missed in scanned or the barcode could not be read</p>
                        </div>
                        <!-- // -->
                        <div class="count-box">
                            <h3>Not assigned <span>(only appears if these exist)</span></h3>
                            <p>Exams that cannot be matched up to a student in the course</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
};

</script>

<style scoped>
.pt-none {
    padding-top: 0;
}
.mt-25 {
    margin-top: 25px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-23 {
    margin-left: 23px;
}
.ml-50 {
    margin-left: 50px;
}
.r-color {
    color: #ff0000;
}
.flex-box {
    display: flex;
}
.flex-box span {
    margin-right: 8px;
}
.submit-assign-section {
    background: #f4f4f5;
    padding: 50px 15px 50px;
    min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
    width: 1140px;
    margin: 0 auto;
}
.submit-assign-section .content-area {
    text-align: left;
}
.submit-assign-section .content-area h1 {
        padding: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
    font-size: 13px;
    color: #92969b;
    font-weight: 300;
    margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
    font-size: 16px;
    color: #2c3e50;
    line-height: 26px;
}
.submit-assign-section .content-area p span {
    color: #2e75bf;
}
.submit-assign-section .content-area .card {
    max-width: 320px;
    padding: 10px 15px;
    background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
    padding: 0;
}
.submit-assign-section .content-area .list-view {
    margin-bottom: 15px;
}
.submit-assign-section .content-area .list-view li {
    font-size: 16px;
    color: #2c3e50;
    list-style: disc;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 10px;
}
.submit-assign-section .content-area .card ul li a {
    font-size: 15px;
    color: #2e75bf;
    font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
    color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
    color: #2c3e50;
    margin-right: 3px;
}
.submit-assign-section .content-area h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    text-decoration: underline;
}
.submit-assign-section .content-area h3 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 10px;
}
.submit-assign-section .content-area img {
    margin-top: 10px;
    margin-left: 23px;
    margin-bottom: 40px;
    border: 1px solid #d8dbdf;
}
.pt-55 {
    padding-top: 55px;
}
.count-details {
    width: 100%;
    max-width: 900px;
    background: #ffffff;
    border: 1px solid #cfd4d6;
}
.count-details .count-box {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #cfd4d6;
}
.count-details .count-box h3 {
    width: 50%;
    font-size: 15px;
    margin-bottom: 0;
    padding: 6px 10px 4px;
}
.count-details .count-box h3 span {
    font-weight: 400;
}
.count-details .count-box p {
    width: 50%;
    font-size: 15px;
    margin-bottom: 0 !important;
    padding: 7px 10px 5px !important;
    border-left: 1px solid #cfd4d6;
}
.ytcontainer {
    position: relative;
    width: 100%;
    /* height: 100%; */
    height: 370px;
    padding-bottom: 0px;
}
.ytvideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 360px;
}
@media (min-width: 920px) and (max-width: 1150px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 919px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .count-details .count-box {
        flex-direction: column;
    }
    .count-details .count-box h3 {
        width: 100%;
    }
    .count-details .count-box p {
        width: 100%;
        border-left: 0;
    }
}
@media (min-width: 340px) and (max-width: 767px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .submit-assign-section .content-area h2 {
        line-height: 30px;
    }
    .submit-assign-section .content-area h1 {
        font-size: 22px;
        line-height: 34px;
    }
    .ml-23 {
        margin-left: 0;
    }
    .ml-50 {
        margin-left: 0;
    }
    .pt-55 {
        padding-top: 20px;
    }
    .count-details .count-box {
        flex-direction: column;
    }
    .count-details .count-box h3 {
        width: 100%;
    }
    .count-details .count-box p {
        width: 100%;
        border-left: 0;
    }
}
</style>