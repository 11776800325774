<template>

    <section class="submit-assign-section">
        <div class="containe">
            <div class="content-area">
                <h1>Submitting Assignments Using Assign2 (For Students)</h1>
                <p>
                    This article describes how students can use the Assign2 external tool to complete 
                    online assessments. The tool allows students to complete solutions for long form 
                    problems on paper and then photograph/scan them to upload as their submission to 
                    the assessment.
                </p>
                <div class="ytcontainer">
                    <iframe class="ytvideo" width="640" height="360" src="https://www.youtube.com/embed/68y78QZpm_g" title="Submitting an assignment on Assign2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="card">
                    <h5>Contents:</h5>
                    <ul>
                        <li>
                            <a href="#submittingAssign2">
                                <span>1</span>
                                Submitting Assign2 Assessments
                            </a>
                        </li>
                        <li>
                            <a href="#unsubmittingWork">
                                <span>2</span>
                                Unsubmitting Assignments
                            </a>
                        </li>
                        <li>
                            <a href="#viewingSubmission">
                                <span>3</span>
                                Viewing Grades and Feedback
                            </a>
                        </li>
                        <li>
                            <a href="#lateSubmissions">
                                <span>4</span>
                                Late Submissions
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- // -->
                <div class="pt-55" id="submittingAssign2">
                    <h2>Submitting an assignment</h2>
                    <p>
                        Students can submit multiple files for each question using their devices 
                        Student file submissions are automatically re-sized on submission.
                        <strong>The accepted
                        file types for Assign2 are any image file (.png, .jpg, etc.) and PDF. 
                        It is recommended that images are in the portrait orientation and that the width
                        does not exceed 2000 pixels.</strong>
                    </p>
                    <div>
                        <div class="flex-box">
                            <span>1.</span>
                            <h3>Click on the Assign2 activity in your course.</h3>
                        </div>
                        <img src="images/assign2-submit-img-1.png" width="389" alt="">
                    </div>
                    <div>
                        <div class="flex-box">
                            <span>2.</span>
                            <h3>Take a photo/scan of your submission.</h3>
                        </div>
                        <img src="images/assign2-submit-img-2.png" width="389" alt="">
                    </div>
                    <div>
                        <div class="flex-box">
                            <span>3.</span>
                            <h3>Upload the image files into Assign2 by click on the upload area or by dragging-and-dropping your file into the box.</h3>
                        </div>
                        <img src="images/assign2-submit-img-3.png" width="408" alt="">
                    </div>
                    <div>
                        <div class="flex-box">
                            <span>4.</span>
                            <h3>
                                Clicking on a thumbnail allows you to preview your upload. If the image 
                                uploaded is not the correct orientation, use the buttons (from order of 
                                left to right on the image below) to adjust it: <br>
                                - Counterclockwise <br>
                                - Horizontally flip <br>
                                - Clockwise
                            </h3>
                        </div>
                        <img src="images/assign2-submit-img-4.png" width="408" alt="">
                    </div>
                    <div>
                        <div class="flex-box">
                            <span>5.</span>
                            <h3>
                                Click <strong>Save</strong> to finalize the upload of your files. Any saved files will 
                                remain in the answer submission area if you are not ready to complete 
                                the assessment.  To complete the assessment, click <strong>Submit</strong> to finalize 
                                the submission for the entire assignment and review that your answers 
                                are appearing in the 'Confirm Submission' screen:
                            </h3>
                        </div>
                        <img src="images/assign2-submit-img-5.png" width="408" alt="">
                    </div>
                    <div>
                        <div class="flex-box">
                            <span>6.</span>
                            <h3>
                                Click <strong>Submit</strong> again to finalize your assignment submission.
                            </h3>
                        </div>
                    </div>
                    <div>
                        <div class="flex-box pt-none">
                            <span>7.</span>
                            <h3>
                                If it is before the dues date or late submissions are allowed,
                                the following message will appear when your assignment has been 
                                successfully submitted for grading.
                            </h3>
                        </div>
                        <img src="images/assign2-submit-img-6.png" width="849" alt="">
                    </div>
                    <div>
                        <div class="flex-box">
                            <span>8.</span>
                            <h3>
                                If it is after the due date <strong><i>and late submissions are not allowed,</i></strong>
                                you will see an alert that the 'Assignment is past due date and does not allow late 
                                submissions.' If you have already made a submission, this can be ignored; if you have 
                                uploaded and saved files, these will have been automatically submitted at the deadline.
                            </h3>
                        </div>
                        <img src="images/assign2-submit-img-7.png" width="760" alt="">
                    </div>
                </div>
                <!-- // -->
                <div class="pt-55" id="unsubmittingWork">
                    <h2>Unsubmitting your work</h2>
                    <p>
                        You can withdraw your submission and re-submit it again anytime before the submission deadline.
                        <span>
                            <strong>Note</strong>: After the submission due date has passed, you WILL NOT be able to review or edit your
                            submission so ensure you have posted the solutions in the correct question response areas 
                            within the assignment.
                        </span> 
                    </p>
                    <p>
                        To withdraw your submission, click <strong>Unsubmit</strong>. If your browser 
                        resolution is too low, you may not see the button and will need to pan to the 
                        right or zoom out to see it.
                    </p>
                    <img src="images/assign2-submit-img-8.png" width="849" alt="">
                </div>
                <!-- // -->
                <div class="pt-55" id="viewingSubmission">
                    <h2>Viewing your submission after it has been graded</h2>
                    <p>
                        Once your instructor has released the grades, you may be able to view your graded 
                        submission in Assign2 and any associated feedback and marks breakdown. 
                    </p>
                    <div class="flex-box pt-none">
                        <span>1.</span>
                        <h3>
                            Click on the Assign2 activity in the course.
                        </h3>
                    </div>
                    <div class="flex-box pt-none">
                        <span>2.</span>
                        <h3>
                            Scroll and view the grading for your assignment.
                        </h3>
                    </div>
                    <img src="images/assign2-submit-img-9.png" width="408" alt="">
                </div>
                <!-- // -->
                <div class="pt-55" id="lateSubmissions">
                    <h2>Late submissions</h2>
                    <p>
                        If your instructor is allowing late submissions, you will see an alert while completing your submission: 
                    </p>
                    <img src="images/assign2-submit-img-11.png" width="762" alt="">
                    <p class="mt-25">
                        If your instructor is NOT allowing late submissions, you will see an alert to that affect and not be able to submit:
                    </p>
                    <img src="images/assign2-submit-img-12.png" width="760" alt="">
                </div>
            </div>
        </div>
    </section>


</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
};

</script>

<style scoped>
.ytcontainer {
    position: relative;
    width: 100%;
    /* height: 100%; */
    height: 370px;
    padding-bottom: 0px;
}
.ytvideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 360px;
}
.flex-box {
    display: flex;
    padding-top: 30px;
}
.flex-box span {
    margin-right: 5px;
    line-height: 28px;
}
.pt-none {
    padding-top: 0;
}
.mt-25 {
    margin-top: 25px;
}
.submit-assign-section {
    background: #f4f4f5;
    padding: 50px 15px 50px;
    min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
    width: 1140px;
    margin: 0 auto;
}
.submit-assign-section .content-area {
    text-align: left;
}
.submit-assign-section .content-area h1 {
        padding: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
    font-size: 13px;
    color: #92969b;
    font-weight: 300;
    margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
    font-size: 16px;
    color: #2c3e50;
    line-height: 26px;
}
.submit-assign-section .content-area p span {
    color: #ff0000;
}
.submit-assign-section .content-area .card {
    max-width: 300px;
    padding: 10px 15px;
    background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
    padding: 0;
}
.submit-assign-section .content-area .card ul li a {
    font-size: 15px;
    color: #2e75bf;
    font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
    color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
    color: #2c3e50;
    margin-right: 3px;
}
.submit-assign-section .content-area h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    text-decoration: underline;
}
.submit-assign-section .content-area h3 {
    font-size: 16px;
    color: #2c3e50;
    margin-bottom: 0;
    line-height: 28px;
}
.submit-assign-section .content-area img {
    margin-top: 5px;
    margin-left: 20px;
    border: 1px solid #d8dbdf;
}
.pt-55 {
    padding-top: 55px;
}
@media (min-width: 920px) and (max-width: 1150px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 919px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
}
@media (min-width: 340px) and (max-width: 767px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .submit-assign-section .content-area h2 {
        line-height: 30px;
    }
    .submit-assign-section .content-area h1 {
        font-size: 22px;
        line-height: 34px;
    }
}
</style>