<template>
  <main>
    <!-- ============================================================= SECTION – HERO ============================================================= -->

    <section id="contact-form">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header class="text-center">
              <h1 class="text-center">Get in touch</h1>
              <!-- <p>Do you want to know more? We’d love to hear from you!</p> -->
            </header>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 outer-top-md inner-right-sm">
                <h2>Leave a Message</h2>

                <div class="row">
                  <div class="col-md-6">
                    <v-text-field
						v-model="formData.name"
                      type="text"
                      name="name"
                      placeholder="Name (Required)"
                    >
                    </v-text-field>
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->

                <div class="row">
                  <div class="col-md-6">
                    <v-text-field
						v-model="formData.email"
                      type="email"
                      name="email"
                      placeholder="Email (Required)"
                    ></v-text-field>
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->

                <div class="row">
                  <div class="col-md-6">
                    <v-text-field
						v-model="formData.subject"
                      type="text"
                      name="subject"
                      placeholder="Subject"
                    ></v-text-field>
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->

                <div class="row">
                  <div class="col-md-12">
                    <v-textarea
						v-model="formData.message"
                      name="message"
                      placeholder="Enter your message ..."
                    ></v-textarea>
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->

                <button v-on:click="postForm" class="btn btn-submit">Send message</button>

                <div id="response">{{emailret}}</div>
              </div>
              <!-- ./col -->

              <div class="col-md-6 outer-top-md inner-left-sm border-left">
                <h2>Contacts</h2>

                <h3>Edufide Inc</h3>
                <ul class="contacts" style="text-align: left">
                  <li>
                    <i class="icon-location contact"></i>Edmonton, Alberta,
                    Canada
                  </li>
                  <li><i class="icon-mobile contact"></i>1 833 EDU FIDE</li>
                  <li>
                    <a href="mailto:info@edufide.com"
                      ><i class="icon-mail-1 contact"></i> info@edufide.com</a
                    >
                  </li>
                </ul>
                <!-- /.contacts -->
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      formData: {
		  name: "",
		  email: "",
		  subject: "",
		  message: "",
	  },
	  emailret: ""
    };
  },
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  },
  methods: {
	  postForm: function () {
		  console.log(this.formData)
		  axios.post("https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/", this.formData). then(({data}) => {
			//   console.log(data)
			this.formData.name = ""
			this.formData.email = ""
			this.formData.subject = ""
			this.formData.message = ""
			this.emailret = "Message sent. Someone will contact you shortly."
		  })
	  }

  },
};
</script>

<style>
</style>