import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('section',{attrs:{"id":"contact-form"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-md-9 mx-auto text-center"},[_c('header',{staticClass:"text-left"},[_c('h1',{staticClass:"text-center mt-2"},[_vm._v("Use Assign2 for free")]),_c('p',[_vm._v(" Let us show you how Assign2 can help you grade 2-4 times faster than traditional grading. Educators can use Assign2 for free with the following different ways: ")]),_vm._m(0),_c('p',[_vm._v("Book a walk through below, we will help you set up.")]),_vm._m(1),_vm._m(2),_vm._m(3),_c(VBtn,{staticClass:"primary mb-2",attrs:{"href":"freeregister"}},[_vm._v("Register Interest")]),_vm._m(4),_c('p',[_vm._v("Follow the instructions below to create your course.")])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 outer-top-md inner-right-sm"},[_c('h2',[_vm._v("Book a walk-through")]),_c('p',[_vm._v(" Quick, 15 minute, online demo of Assign2. We will walk through setting up your free course. ")]),_c('vue-calendly',{attrs:{"url":"https://calendly.com/edufide/15demo?hide_event_type_details=1&hide_gdpr_banner=1"}})],1),_vm._m(5)])])])])]),_c(VSnackbar,{attrs:{"timeout":_vm.snackbar.timeout},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar.modal = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar.modal),callback:function ($$v) {_vm.$set(_vm.snackbar, "modal", $$v)},expression:"snackbar.modal"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("1. LTI External Tool from your LMS Course")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("2. Campus wide intragtion with your LMS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" As a promotion for "),_c('strong',[_vm._v("MathFest 2022")]),_vm._v(" Edufide will provide Assign2 free to your entire campus for a full academic term. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Register your interest for this promotion "),_c('strong',[_vm._v(" before 5pm on August 12th 2022")]),_vm._v(" by visiting our Booth 313 OR click below ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("3. Use Assign2 Course Manager")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 outer-top-md inner-left-sm border-left demorighvideopanel"},[_c('h2',[_vm._v("Assign2 Course Manager Instructions")]),_c('div',{staticClass:"text-left"},[_c('p',[_vm._v(" Assign2 Course Manager was developed recently to provide access to Assign2 without the need of a Learning Management System (LMS). This system is new and under active development for improvements. You can sign up to use Assign2 by following these steps: ")]),_c('p',[_vm._v(" 1. Signup for an account at "),_c('a',{attrs:{"href":"https://assign2.edufide.com"}},[_vm._v("https://assign2.edufide.com")]),_vm._v(". ")]),_c('p',[_vm._v(" 2. Follow instructions on "),_c('a',{attrs:{"href":"https://www.edufide.com/docs-create-course"}},[_vm._v("How to create a course")]),_vm._v(" article. ")]),_c('p',[_vm._v(" 3. Contact us at support@edufide.com if you need assistance. ")])])])
}]

export { render, staticRenderFns }