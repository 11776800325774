<template>
  <div>
      	<main>

		<!-- ============================================================= SECTION – HERO ============================================================= -->

		<section id="hero">
			<div id="owl-main" class="owl-carousel owl-one-item">

				<div class="item img-bg-center edufideblue" style="background-image: url(images/art/slider01.jpg);">
					<div class="container">
						<div class="caption vertical-center text-center">

							<h1 class="fadeInDown-1 light-color">Stream2</h1>
							<p class="fadeInDown-2 light-color">Stream2 is a simple video streaming service for educational institutions featuring video recording and live lecture streaming embedded right into your LMS courses. </p>
							<div class="fadeInDown-3">
								<a href="contact.html" class="btn btn-large">Get a demo</a>
							</div><!-- /.fadeIn -->

						</div><!-- /.caption -->
					</div><!-- /.container -->
				</div><!-- /.item -->

			</div><!-- /.owl-carousel -->
		</section>

		<!-- ============================================================= SECTION – HERO : END ============================================================= -->

		<!-- ============================================================= SECTION – SERVICES ============================================================= -->

		<section id="services">
			<div class="container inner-top inner-bottom-sm">

				<div class="row inner-top-sm text-center">

					<div class="col-md-4 inner-bottom-xs">
						<div class="icon">
							<i class="icon-thumbs-up-1 icn lg"></i>
						</div><!-- /.icon -->
						<h2>Easy to use</h2>
						<p class="text-small">Stream2 has a clean, simple interface for quick uploads that convert automatically to standard video formats.</p>
					</div><!-- /.col -->

					<div class="col-md-4 inner-bottom-xs">
						<div class="icon">
							<i class="icon-play-circle2 icn lg"></i>
						</div><!-- /.icon -->
						<h2>Live Streaming</h2>
						<p class="text-small">Live streaming is intrinsicly built-in to Stream2. Streams can be recorded, embedded into courses, and broadcast with a variety of broadcast suites, including OBS.</p>
					</div><!-- /.col -->

					<div class="col-md-4 inner-bottom-xs">
						<div class="icon">
							<i class="icon-align-left icn lg"></i>
						</div><!-- /.icon -->
						<h2>Automatic subtitles</h2>
						<p class="text-small">Closed captioning is added to every piece of content as soon as it is uploaded.</p>
					</div><!-- /.col -->

				</div><!-- /.row -->

				<div class="row text-center">

					<div class="col-md-4 inner-bottom-xs">
						<div class="icon">
							<i class="icon-chart-line icn lg"></i>
						</div><!-- /.icon -->
						<h2>Adaptive streaming</h2>
						<p class="text-small">The Stream2 media player automatically adapts to users&apos; connection quality and device to deliver the best possible playback experience.</p>
					</div><!-- /.col -->

					<div class="col-md-4 inner-bottom-xs">
						<div class="icon">
							<i class="icon-list-1 icn lg"></i>
						</div><!-- /.icon -->
						<h2>Video management</h2>
						<p class="text-small">Media can be uploaded into personal or course libraries and then curated into any number of playlists. Even live streams can be included in playlists</p>
					</div><!-- /.col -->

					<div class="col-md-4 inner-bottom-xs">
						<div class="icon">
							<i class="icon-cc-nc icn lg"></i>
						</div><!-- /.icon -->
						<h2>Economical</h2>
						<p class="text-small">Stream2 was carefully designed to keep streaming cost low. For heavy usage situations we provide smart caching of media.</p>
					</div><!-- /.col -->

				</div><!-- /.row -->

			</div><!-- /.container -->
		</section>

		<!-- ============================================================= SECTION – SERVICES : END ============================================================= -->

		<!-- ============================================================= SECTION – HOWITWORKS ============================================================= -->

		<section id="howitworks">
			<div class="container inner-top inner-bottom-sm">

				<div class="row">
					<div class="col-lg-8 col-md-9 mx-auto text-center">
						<header>
							<h1>How it works</h1>
							<p>Stream2 adds an intuitive management interface directly inside your LMS.</p>
						</header>
					</div><!-- /.col -->
				</div><!-- /.row -->

				<div class="row inner-top-sm text-center">

					<div class="col-md-3 inner-bottom-xs">
						<div class="icon-overlay icn-link" style="display:none;">
							<img src="images/art/work01.jpg">
						</div>
						<h2>Upload</h2>
						<p class="text-small">Add videos, make recordings, and configure livestreams directly via the LMS text editor using our easy-to-use interface.</p>
					</div><!-- /.col -->

					<div class="col-md-3 inner-bottom-xs">
						<div class="icon-overlay icn-link" style="display:none;">
							<img src="images/art/work01.jpg">
						</div>
						<h2>Embed</h2>
						<p class="text-small">Select videos and playlists from a course or personal library to embed the video in any of the content areas of a course.</p>
					</div><!-- /.col -->

					<div class="col-md-3 inner-bottom-xs">
						<div class="icon-overlay icn-link" style="display:none;">
							<img src="images/art/work01.jpg">
						</div>
						<h2>Stream</h2>
						<p class="text-small">Stream course lectures live using industry standard broadcasting tools and publish recordings directly into courses.</p>
					</div><!-- /.col -->

					<div class="col-md-3 inner-bottom-xs">
						<div class="icon-overlay icn-link" style="display:none;">
							<img src="images/art/work01.jpg">
						</div>
						<h2>Analyze</h2>
						<p class="text-small">Monitor viewing statistics and usage trends using our advanced analytics tools. </p>
					</div><!-- /.col -->

				</div><!-- /.row -->

			</div><!-- /.container -->
		</section>

		<!-- ============================================================= SECTION – HOWITWORKS : END ============================================================= -->

		<!-- ============================================================= SECTION – PRICING TABLES ============================================================= -->

		<section id="pricing-tables">
			<div class="container inner">

				<div class="row">
					<div class="col-lg-8 col-md-9 mx-auto text-center">
						<header>
							<h1>Transparent, comprehensive pricing
								
							</h1>
							<p>Our services are finely tuned using intelligent cloud provisioning and there is no potential for ballooning costs.</p>
						</header>
					</div><!-- /.col -->
				</div><!-- /.row -->

				<div class="row pricing col-4-custom">


	
						<div class="col-xl-3 col-md-6 inner-top-sm">
							<div class="plan">
	
								<header>
									<h2>Up to 5000 FTEs</h2>
	
									<div class="price">
										<span class="currency">$</span>
										<span class="amount">0.70</span>
										<span class="period">/ user / Yr</span>
									</div><!-- /.price -->
									<div>
											<span class="amount"><small>Minimum of $1,500</small></span>
									</div>
									<!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
								</header>
	
								<ul class="features">
									<li><i class="fas fa-dollar-sign"></i>3,000 Max</li>
									<li><i class="icon-hdd"></i> $0.075/GB Storage</li>
									<li><i class="icon-switch"></i> $0.15/GB Bandwidth</li>
									<li><i class="icon-shuffle"></i> $0.15/hour Processing</li>
								</ul><!-- /.features -->
	
							</div><!-- /.plan -->
						</div><!-- /.col -->
	
						<div class="col-xl-3 col-md-6 inner-top-sm">
							<div class="plan">
	
								<header>
									<h2>Up to 15000 FTEs</h2>
	
									<div class="price">
										<span class="currency">$</span>
										<span class="amount">0.60</span>
										<span class="period">/ user / Yr</span>
									</div><!-- /.price -->
									<div>
											<span class="amount"><small>Minimum of $3,500</small></span>
									</div>
									<!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
								</header>
	
								<ul class="features">
									<li><i class="fas fa-dollar-sign"></i>7,500 Max</li>
									<li><i class="icon-hdd"></i> $0.075/GB Storage</li>
									<li><i class="icon-switch"></i> $0.15/GB Bandwidth</li>
									<li><i class="icon-shuffle"></i> $0.15/hour Processing</li>
								</ul><!-- /.features -->
	
							</div><!-- /.plan -->
						</div><!-- /.col -->
	
						<div class="col-xl-3 col-md-6 inner-top-sm">
							<div class="plan">
	
								<header>
									<h2>15000+ FTEs</h2>
	
									<div class="price">
										<span class="currency">$</span>
										<span class="amount">0.50</span>
										<span class="period">/ user / Yr</span>
	
									</div><!-- /.price -->
									<div>
											<span class="amount"><small>Minimum of $7,500</small></span>
									</div>
									<!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
								</header>
	
								<ul class="features">
									<li><i class="fas fa-dollar-sign"></i>35,000 Max</li>
									<li><i class="icon-hdd"></i> $0.075/GB Storage</li>
									<li><i class="icon-switch"></i> $0.15/GB Bandwidth</li>
									<li><i class="icon-shuffle"></i> $0.15/hour Processing</li>
								</ul><!-- /.features -->
	
							</div><!-- /.plan -->
						</div><!-- /.col -->
	
					</div><!-- /.row -->
					<div class="row">
						<div class="col-lg-8 col-md-9 mx-auto text-left">
							<h5 class="font-italic"><small>Published price structure may change, but
									will be honored for existing contracted clients. Our price increases for current clients are capped at 2 times
									the US inflation per year.</small></h5>
						</div><!-- /.col -->
					</div><!-- /.row -->

			</div><!-- /.container -->
		</section>

		<!-- ============================================================= SECTION – PRICING TABLES : END ============================================================= -->




	</main>
  </div>
</template>

<script>
export default {

  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
}
</script>

<style>

</style>