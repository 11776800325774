<template>
  	<main>

		<!-- ============================================================= SECTION – HERO ============================================================= -->

		<section id="hero" class="light-bg img-bg img-bg-softer" style="background-image: url(images/art/image-background04.jpg);">
			<div class="container inner">
				<div class="row">
					<div class="col-lg-12">
						<header>
							<h1>MoodleMoot UK and Ireland</h1>
							<p>Please come back in sepetmber to redeem your promo codes or contact us.</p>
						</header>
					</div><!-- /.col -->
				</div><!-- ./row -->
			</div><!-- /.container -->
		</section>

		<!-- ============================================================= SECTION – HERO : END ============================================================= -->

		<div class="container inner">
			<div class="row">

				<div class="col-lg-12 inner-right-md">

					<!-- ============================================================= SECTION – CONTACT FORM ============================================================= -->


					<!-- ============================================================= SECTION – CONTACT FORM : END ============================================================= -->


					<!-- ============================================================= SECTION – CONTACTS ============================================================= -->

					<section id="contacts" class="inner-bottom-sm">

						<h2>Contacts</h2>
						<p></p>

						<div class="row">
							<div class="col-md-12">
								<h3>Edufide Inc</h3>
								<ul class="contacts">
									<!-- <li><i class="icon-location contact"></i> 84 Street, City, State 24813</li> -->
									<li><i class="icon-mobile contact"></i> 1 833 EDU FIDE</li>
									<li><a href="mailto:info@edufide.com"><i class="icon-mail-1 contact"></i> info@edufide.com</a></li>
								</ul>
							</div>
						</div>

					</section>

					<!-- ============================================================= SECTION – CONTACTS : END ============================================================= -->

				</div><!-- ./col -->

				<div class="col-lg-4">

					<!-- ============================================================= SECTION – CONTACT NAMES ============================================================= -->



					<!-- ============================================================= SECTION – CONTACT NAMES : END ============================================================= -->

				</div><!-- /.col -->

			</div><!-- /.row -->
		</div><!-- /.container -->

	</main>
</template>

<script>
export default {
    mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
}
</script>

<style>

</style>