<template>
<div>
  <main class="js-reveal">
    <section class="landing-section">
        <div class="container">
            <h1>Manage Student Assignments in Assign2</h1>
            <p>
                This guide covers details about how an instructor can manage Assignments in
                 the Assign2 application including assignment 
                visibility, due dates, release grades, and giving feedback to students. 
            </p>
            <p>
            The Instructor should make sure to enroll all students in the course at first. 
            To seek help on how to enroll students go to the <a href="" >Course Creation </a>guide. 
            <!-- Link needs to be added here -->
            </p>
            <p>
            After Adding the assignment, the system directs the instructor to the dashboard where it shows the Assessment Details.
             Click on the <strong>Setting icon </strong> to enter/edit different options for managing the student’s assignments. 
            </p>
            <div class="pb-4">
                <img src="images/manageAssign/setting1.png" class="mb-2" alt="">
            </div>
            <br>
            <h2> Manage Settings </h2>
            <p>
            Following are the setting options for assignments and their details. 
            </p>
            <h3>Manage Deadlines </h3>
            <ul> 
            <li>
            <strong>Due date and time: </strong>Update the due date and time as needed. Both time and date are <strong>universal.</strong>
             For example, if an instructor working in Toronto (Eastern Time) sets a due time for 23:00, students in Mountain Time Zone
              will see it due at 21:00 instead.
            </li>
            <li>
            <strong>Set open date: </strong>After the assignment is created, it is possible to update the settings to set an open date and 
            time for the activity to use as a summative exam (or a future homework assignment).
             This will allow students to access it whenever the specified start time is reached. 
            </li>
            <li>
            <strong>Allow late submission: </strong>Toggle this button on to allow submission after the cut-off time.
            </li>
            <li>
            <strong>Late Penalty:</strong> In case of allowing late submissions, the instructor may set a late penalty percentage per hour or day.
            </li>
            </ul>

            <h3>Assignment/Grades Visibility</h3>

            <ul>
            <li>
            <strong>Open to Students: </strong> The Instructor must toggle this on to make assignments accessible to students. 
            </li>
            <li>
            <strong>Students can view results: </strong>Toggle this on to allow students to view the marks they received on each question, 
            the total grade for the assignment, and any feedback or annotations by the instructor.
            </li>
            </ul>

            <p>
            Once done setting all the options, click on the button <strong>Save Settings</strong>. 
            <div class="pb-4">
                <img src="images/manageAssign/setting.png" class="mb-2" alt="">
            </div>
            <br>
            </p>
            <div class="article-box">
                <p>
                <strong>Note:</strong> Students do not automatically receive a notification that this has been done so the instructor will 
                need to let them know that their results are now available for review in Assign2.
                 When they click on the assignment link again it will take them directly to their results. 
                </p>
            </div>

            <h2>Manage Student Submissions </h2>
            <p>
            To manage submissions, open up the navigation menu and click on the <strong>Students </strong>tab.
             Students are already populated in Assign2 while creating the course. 
             However, if the list has some changes to be made at any point, the instructor can click on the<strong> Update Users from LMS </strong>
             button available at the top right corner on the Students page. 
            </p>
            <p>
            The system provides options to instructors to <strong>search </strong>and <strong>sort </strong>students as needed to find their 
            submissions
            </p>
            <p>
            A list of students displaying their<strong> names </strong>and <strong> IDs</strong> along with their assignment details is 
            available in a table that has several features as described below: 

            </p>

            <div class="pb-4">
                <img src="images/manageAssign/manage.png" class="mb-2" alt="">
            </div>

            <br>
             <ul>
             <li>
             <strong>Assigned Exam: </strong>This column displays “exam not assigned” by default unless a student submits an assessment. 
             After submission, the status of a particular student changes to the location of the submitted assessment. 
             </li>
             <li>
             <strong>Marks:</strong> Marks for submitted and graded assignments appears under this column in front of every student.
             </li>
             <li>
             <strong>Actions:</strong> The instructor can perform several actions for each student separately that include:
             
             <ul>
             
             <li>
             <strong>Preview </strong>of a student’s submissions can be done by clicking the magnifying glass icon beside their assigned exam. 
             </li>
             <li>
             Review the full history of the submission and grading actions for all markers working on an assessment by selecting <strong>View Activity Logs</strong>.
             </li>
             <li>
             The instructor can also <strong>upload a student's submission</strong> in case one couldn’t do it for some reason so that it can be graded online.
             </li>
             <li>
             <strong>Overridden dates and times</strong> can be added for a particular student. This must be added after the questions are created. These can be added initially as placeholders but the number of questions can’t be edited after any individual student overrides have been added. Adding an override will also adjust the submission count for these students on the dashboard page (even though they will not have accessed the assessment).
             </li>
             </ul>
             </li>
             </ul>

             <div class="pb-4">
                <img src="images/manageAssign/override.png" class="mb-2" alt="">
            </div>
            <br>

            <h2>Release Grades </h2>
            <p>
            Once all the assignments are marked, go to the dashboard and double-check that the <strong>Fully Marked </strong>column matches the number of <strong>Total 
            Exams</strong>. To push the grades to students, click on the <strong> file icon </strong> beside fully marked. An overlay will confirm if you would like to 
            proceed and give the option to include or exclude late penalties. Click on the <strong>Push Grades </strong> button to confirm the action.
            </p>
            <div class="pb-4">
                <img src="images/manageAssign/marked.png" class="mb-2" alt="">
            </div>
            <br>
           
           
           
        </div>
    </section>
  </main>
</div>
</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style scoped>
h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    padding: 50px 0 30px;
    text-transform: initial;
}
.landing-section p {
    font-size: 16px;
    color: #333333;
    text-align: left;
    margin-bottom: 25px;
}
.landing-section p a {
    color: #2e75bf;
    border-bottom: 0;
    text-decoration: none;
}
.landing-section h2 {
    margin: 0;
    padding-top: 0;
    font-size: 26px;
    color: #525252;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    padding-bottom: 25px;
}
.landing-section img {
    width: 60%;
}
.article-box {
    padding: 20px 24px;
    margin-bottom: 30px;
    background: #f4f4f4;
    border-left: 2px solid #dddddd;
}
.article-box ul li {
    margin-bottom: 10px;
}
.article-box ul li:last-of-type {
    margin-bottom: 0;
}
ul {
    margin: 0;
    padding: 0 0 0 30px;
}
.landing-section ul li {
    font-size: 16px;
    color: #333333;
    text-align: left;
    list-style: disc;
    margin-bottom: 20px;
}
.landing-section ul li a {
    color: #2e75bf;
    padding: 0 !important;
    font-size: 16px !important;
    display: inline-block !important;
}
h3 {
    padding: 0;
    font-size: 20px;
    margin: 0 0 20px;
    color: #525252;
    font-weight: 500;
    text-align: left;
}
.landing-section a {
    display: inline;
    font-size: 16px;
    color: #2e75bf;
    text-align: left;
    padding-bottom: 15px;
}
h4 {
    margin: 0;
    font-size: 18px;
    color: #525252;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    h1 {
        font-size: 24px;
        line-height: 30px;
        padding: 30px 0 20px;
    }
    .landing-section p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    ul {
        padding: 0 0 0 15px;
    }
    .landing-section ul li {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .landing-section ul li a {
        font-size: 14px !important;
    }
    .landing-section h2 {
        font-size: 20px;
        line-height: 22px;
        padding-bottom: 15px;
    }
    .landing-section p a {
        padding-bottom: 0;
    }
    .landing-section h3 {
        padding-top: 0;
    }
    .landing-section h4 {
        font-size: 14px;
    }
}

</style>