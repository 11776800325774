<template>
  <section class="submit-assign-section">
    <div class="containe">
      <div class="content-area">
        <h1>Integrating Assign2 with Moodle</h1>
        <p>
          The Assign2 integration for Moodle is built on LTI 1.3, providing a
          seamless experience for instructors and students alike. With this
          integration, instructors can easily access and pull the course roster
          from Assign2, simplifying the process of setting up assessments and
          assignments. Once assessments are complete, grades can be pushed back
          into the Moodle gradebook, streamlining the grading process and saving
          instructors valuable time. This integration allows for a more efficient and
          effective workflow for instructors using Assign2 in conjunction with
          Moodle.
        </p>
        <div class="card">
          <h5>Contents:</h5>
          <ul>
            <li>
              <a href="#createexternaltool">
                <span>1</span>
                Add external tool in Moodle
              </a>
            </li>
          </ul>
        </div>
        <!-- /// -->
        <div class="pt-55" id="createexternaltool">
          <h2>Add external tool in Moodle</h2>
          <p>
            1. As as admin, click on the <strong>Site administration</strong> button on the admin menu.
          </p>
          <p>
            2. Click on the <strong>Plugins</strong> link.
          </p>
          <p>
            3. Under <strong>External tool</strong>, click on <strong>Manage tools</strong> link.
          </p>
          <p>
            4. Click on the <strong>configure a tool manually</strong> link.
          </p>
            <p>5. On the Tool Settings page fillout the following information:</p>
            <ul>
                <li>
                    <strong>Tool Name:</strong> Assign2
                </li>
                <li>
                    <strong>Tool URL:</strong> https://app.assign2.edufide.com/lti/1.3/launch 
                    <p>
                        <i>Note: This is the default URL, If you have a custom dedicated Assign2 tenant, please use that instead. </i>
                    </p>
                </li>
                <li>
                    <strong>Tool description:</strong> Assign2 is a powerful assessment grading platform that allows you to provide rich feedback to your students while saving you time.
                    <p>
                        <i>Note: This is the default description for Assign2 and can be customized</i>
                    </p>
                </li>
                <li>
                    <strong>LTI version:</strong> LTI 1.3
                </li>
                <li>
                    <strong>OpenID Connect Initiation Url:</strong> https://app.assign2.edufide.com/lti/1.3/auth
                    <p>
                        <i>Note: This is the default URI. If you have a custom dedicated Assign2 tenant, please use that instead.</i>
                    </p>
                </li>
                <li>
                    <strong>Public key type:</strong> Keyset URL
                </li>
                <li>
                    <strong>Public keyset:</strong> https://app.assign2.edufide.com/lti/1.3/.well-known/jwks.json
                    <p>
                        <i>Note: This is the default URI. If you have a custom dedicated Assign2 tenant, please use that instead.</i>
                    </p>
                </li>
                <li>
                    <strong>Initiate login URL:</strong> https://app.assign2.edufide.com/lti/1.3/auth
                    <p>
                        <i>Note: This is the default URI. If you have a custom dedicated Assign2 tenant, please use that instead.</i>
                    </p>
                </li>
                <li>
                    <strong>Redirection URI(s):</strong> https://app.assign2.edufide.com/lti/1.3/redirect
                    <p>
                        <i>Note: This is the default URI. If you have a custom dedicated Assign2 tenant, please use that instead.</i>
                    </p>
                </li>
                <li>
                    <strong>Tool configuration usage:</strong> Select "Show in activity chooser and as a preconfigured tool"
                </li>
                <li>
                    <strong>Default launch container:</strong> Select "New window"
                </li>
                <li>
                        <div class="mb-20 mt-5">
                            <img
                            src="images/docs-moodle-integration/settings1.png"
                            width="800"
                            alt=""
                            />
                        </div>
                </li>
      
                <li>
                  <br>
                  <p>Click<strong> "Show more..."</strong></p>
                  
                  <strong>Icon URL:</strong> https://s3.amazonaws.com/assign2-public/favicon-64x64.png<br>
                  <strong>Secure icon URL:</strong> https://s3.amazonaws.com/assign2-public/favicon-64x64.png<br>
                </li>
                <li>
                  <br>
                  <p>Under <strong>Services</strong></p>
                  <strong>IMS LTI Assignment and Grade Services:</strong> Select "Use this service for grade sync and column management" <br>
                  <strong>IMS LTI Names and Role Provisioning:</strong> Select "Use this service to retrieve members' information as per privacy settings" <br>
                </li>
                <li>
                  <br>
                  <p>Under <strong> Privacy</strong></p>
                  
                  <strong>Share launcher's name with tool:</strong> Select "Always"
                  <strong>Share launcher's email with tool:</strong> Select "Always"
                  <strong>Accept grades from the tool:</strong> Select "Always"
                </li>
                <li>
                        <div class="mb-20 mt-5">
                            <img
                            src="images/docs-moodle-integration/settings2.png"
                            width="800"
                            alt=""
                            />
                        </div>
                </li>                
            </ul>
            <p class="mt-5">
                5. Click on the <strong>Save Changes</strong> button.
            </p>
        </div>


      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  },
};
</script>

<style scoped>
.pt-none {
  padding-top: 0;
}
.mt-25 {
  margin-top: 25px;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px;
}
.ml-23 {
  margin-left: 23px;
}
.ml-50 {
  margin-left: 50px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.r-color {
  color: #ff0000;
}
.flex-box {
  display: flex;
}
.flex-box span {
  margin-right: 8px;
}
.submit-assign-section {
  background: #f4f4f5;
  padding: 50px 15px 50px;
  min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
  width: 1140px;
  margin: 0 auto;
}
.submit-assign-section .content-area {
  text-align: left;
}
.submit-assign-section .content-area h1 {
  padding: 0;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
  font-size: 13px;
  color: #92969b;
  font-weight: 300;
  margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
  font-size: 16px;
  color: #2c3e50;
  line-height: 26px;
}
.submit-assign-section .content-area p span {
  color: #2e75bf;
}
.submit-assign-section .content-area .card {
  max-width: 320px;
  padding: 10px 15px;
  background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
  padding: 0;
}
.submit-assign-section .content-area .list-view {
  margin-bottom: 15px;
}
.submit-assign-section .content-area .list-view li {
  font-size: 15px;
  color: #2c3e50;
  list-style: disc;
  line-height: 30px;
}
.submit-assign-section .content-area .card ul li a {
  font-size: 15px;
  color: #2e75bf;
  font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
  color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
  color: #2c3e50;
  margin-right: 3px;
}
.submit-assign-section .content-area h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  text-decoration: underline;
}
.submit-assign-section .content-area h3 {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
.submit-assign-section .content-area img {
  margin-left: 23px;
  margin-bottom: 20px;
  border: 1px solid #d8dbdf;
}
.pt-55 {
  padding-top: 55px;
}
.count-details {
  width: 100%;
  max-width: 900px;
  background: #ffffff;
  border: 1px solid #cfd4d6;
}
.count-details .count-box {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #cfd4d6;
}
.count-details .count-box h3 {
  width: 50%;
  font-size: 15px;
  margin-bottom: 0;
  padding: 6px 10px 4px;
}
.count-details .count-box h3 span {
  font-weight: 400;
}
.count-details .count-box p {
  width: 50%;
  font-size: 15px;
  margin-bottom: 0 !important;
  padding: 7px 10px 5px !important;
  border-left: 1px solid #cfd4d6;
}
@media (min-width: 920px) and (max-width: 1150px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 919px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
  .submit-assign-section .content-area img {
    margin-left: 0;
    max-width: 100%;
  }
  .count-details .count-box {
    flex-direction: column;
  }
  .count-details .count-box h3 {
    width: 100%;
  }
  .count-details .count-box p {
    width: 100%;
    border-left: 0;
  }
}
@media (min-width: 340px) and (max-width: 767px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
  .submit-assign-section .content-area img {
    margin-left: 0;
    max-width: 100%;
  }
  .submit-assign-section .content-area h2 {
    line-height: 30px;
  }
  .submit-assign-section .content-area h1 {
    font-size: 22px;
    line-height: 34px;
  }
  .ml-23 {
    margin-left: 0;
  }
  .ml-50 {
    margin-left: 0 !important;
  }
  .pt-55 {
    padding-top: 20px;
  }
  .count-details .count-box {
    flex-direction: column;
  }
  .count-details .count-box h3 {
    width: 100%;
  }
  .count-details .count-box p {
    width: 100%;
    border-left: 0;
  }
  .ml-15 {
    margin-left: 0 !important;
  }
}
</style>