<template>

    <main>
      <!-- ============================================================= SECTION – HERO ============================================================= -->

			<section id="hero">
				<div id="owl-main" class="owl-carousel owl-one-item">
					
					<div class="item img-bg-center edufideblue" style="background-image: url(images/art/slider01.jpg);">
						<div class="container">
							<div class="caption vertical-center text-center">
								
								<h1 class="fadeInDown-1 lmedium-bg light-color text-center" style="">Introducing Assign2</h1>
								<p class="fadeInDown-2  medium-bg light-color">A hyper-efficient collaborative grading tool for online, in-class and paper based assessments</p>
								<div class="fadeInDown-3  mt-4 light-color" style="">
                  <!-- <h5>Assign2 will be officially released to public on June 17th 2022</h5>
                  <h5>Registration will open at the Canadian Mathematical Society meeting in St John's NL on June 4th 2022</h5> -->
								</div>
							<div class="fadeInDown-3">
                <a v-on:click="showAlert" class="btn btn-large">Watch Video</a>
                <a href="demo" class="btn btn-large ml-5">Book Demo</a>
              </div>
							</div><!-- /.caption -->
						</div><!-- /.container -->
					</div><!-- /.item -->

				</div><!-- /.owl-carousel -->
			</section>

    <v-dialog
      v-model="videoDialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title class="text-h5">
        </v-card-title>

        <v-card-text>
          <iframe id="ytVideo" style="width: 100%; height: 400px;" src="https://www.youtube.com/embed/AIuwrVPHumM?enablejsapi=1&origin=https://edufide.com&rel=0" title="Introducting Assign2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
<!-- <iframe src="https://player.vimeo.com/video/727541166?h=386d520b0c" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>           -->
          <!-- <video id="myVideo" style="width: 100%; height: auto;" controls>
          <source src="https://edufide-public.s3.amazonaws.com/Assign2-explainer-final.mp4" type="video/mp4">
          </video> -->
        </v-card-text>


      </v-card>
    </v-dialog>      
      						<!-- <div class="item img-bg-center" style="height: 600px; background-image: url(images/art/im2.png);">
            
            </div> -->
<!-- background-image: url(images/art/im2.png); -->

      <!-- ============================================================= SECTION – HERO : END ============================================================= -->

      <!-- ============================================================= SECTION – PRODUCT ============================================================= -->

      <section id="product" style="padding-bottom: 30px">
        <div class="container inner-top" style="">
          <div class="row">
            <div class="col-md-6 inner-right-xs text-right">
              <figure>
                <img
                  src="images/art/female-teacher-addressing-university-students-classroom-79849284.jpg"
                  alt
                />
              </figure>
            </div>

            <div class="col-md-6 inner-top-xs inner-left-xs">
              <h2>Designed for educators by educators</h2>
              <p>Assign2 was designed in collaboration with educators at one of top universities in 
                Canada. We focused on creating the most efficient assessment 
                grading tool possible. Development of new features such as peer-assessment and automated grading continues.</p>
              <!-- <a href="#" class="txt-btn">Check out our products and services</a> -->
            </div>
          </div>

          <div class="row inner-top-md">
            <div class="col-md-6 order-md-2 inner-left-xs">
              <figure>
                <img
                  src="images/art/im2.jpg"
                  alt
                />
              </figure>
            </div>
            <div class="col-md-6 order-md-1 inner-top-xs inner-right-xs">
              <h2>Grading Efficiency</h2>
              <p>Assign2 provides a user-friendly grading interface designed to mark large number of exams quickly. Exams can be marked with reusable scored comments and rubrics. The grading interface can be customised with extensive use of keyboard shortcuts. Rubrics and comments libraries can be shared with-in the grading team. Assign2 ensures that grading collisions do not occur when multiple graders are marking the same question.</p>
              <!-- <a href="#" class="txt-btn">Learn more about our values</a> -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->

          <div class="row inner-top-md">
            <div class="col-md-6 inner-right-xs text-right">
              <figure>
                <img src="images/art/im4.jpg" alt />
              </figure>
            </div>
            <!-- /.col -->

            <div class="col-md-6 inner-top-xs inner-left-xs">
              <h2>Better learning outcomes</h2>
              <p>Once graded, assessment can be returned to students for review and feedback. Assign2 provides statistics for how the students performed and
                how well the assessment itself was designed. Over time, this leads to better assessments and overall student outcomes.
              </p>
              <!-- <a href="#" class="txt-btn">Read of about sustainability</a> -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </section>


    <section id="services">
      <div class="container inner-top inner-bottom-sm">
        <div class="row inner-top-sm text-center">
          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="fas fa-search icn lg"></i>
            </div>
            <h2>Student Matching</h2>
            <p
              class="text-small"
            >Assign2 matches students automatically to scanned exams, saving valuable grading time</p>
          </div>

          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="icon-dot-3 icn lg" style="margin-top: -20px"></i>
              <i class="icon-dot-3 icn lg" style="margin-top: -50px; margin-bottom: -15px"></i>
            </div>
            <!-- /.icon -->
            <h2>Multiple Choice Exams</h2>
            <p
              class="text-small"
            >Assign2 scores multiple choice exams automatically, reducing the need for proprietary bubble sheets and scanners</p>
          </div>
          <!-- /.col -->

          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="icon-flow-tree icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>Integrated and secure</h2>
            <p
              class="text-small"
            >Assign2 integrates with most popular Learning Management Systems over LTI</p>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row text-center">
          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="icon-cc-nc icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>Scalable and Economical</h2>
            <p
              class="text-small"
            >With expensive tools universities have to make tough choices sometimes. Assign2 was designed for campus-wide scalability, which costs a fraction of our competitors</p>
          </div>
          <!-- /.col -->

          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="fas fa-chart-bar icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>Proven Track Record</h2>
            <p
              class="text-small"
            >Assign2 has been used to grade more than 1.5 million assessment items</p>
          </div>
          <!-- /.col -->

          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="fas fa-users icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>Driving Change</h2>
            <p
              class="text-small"
            >Assign2 supports innovative features such as two-stage group exams, peer-assessment and soon automated grading, helping drive better teaching and learning on your campus</p>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>

    <section id="pricing-tables">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header>
              <h1>Transparent, comprehensive pricing, no surprises</h1>
              <p></p>
            </header>
          </div> -->
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row pricing col-4-custom">
          <div class="col-xl-4 col-md-6 inner-top-sm">
            <div class="plan">
              <header>
                <h2>Free Class</h2>

                <div class="price">
                  <span class="currency">$</span>
                  <span class="amount">0</span>
                  <span class="period">/ Class</span>
                </div>
                <!-- /.price -->

                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">
                <li>
                  <i class="fas fa-users"></i>1 class
                  <br />
                  <small>per instructor</small>
                </li>
                <li>
                  <i class="icon-switch"></i>No LMS integration
                </li>
              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>          
          <div class="col-xl-4 col-md-6 inner-top-sm">
            <div class="plan">
              <header>
                <h2>Single Class</h2>

                <div class="price">
                  <span class="currency">Contact us</span>
                  <!-- <span class="amount">1</span>
                  <span class="period">/ Student / Class</span> -->
                </div>
                <!-- /.price -->

                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">
                <li>
                  <i class="fas fa-users"></i>per class
                  <br />
                  <small>per class</small>
                </li>
                <li>
                  <i class="icon-switch"></i>LTI integration
                </li>
              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>
          <!-- /.col -->


          <!-- /.col -->


          <!-- /.col -->

          <div class="col-xl-4 col-md-6 inner-top-sm">
            <div class="plan">
              <header>
                <h2>Campus Wide</h2>

                <div class="price">
                  <span class="currency">Contact us</span>
                  <!-- <span class="amount">1</span> -->
                  <!-- <span class="period">/ FTE / Yr</span> -->
                </div>
                <!-- /.price -->
                <div>
                  <span class="amount">
                    <!-- <small>Valid for signups before Fall 2022</small> -->
                  </span>
                </div>
                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">
                <!-- <li>
                  <i class="fas fa-dollar-sign"></i>35,000 Max
                </li> -->
                <li>
                  <i class="icon-docs"></i>Unlimited usage campus wide
                  <br />
                  <small>no limits on usage</small>
                </li>
                <li>
                  <i class="icon-switch"></i>LMS + LTI integration
                </li>

              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
        <div class="row">
          <div class="col-lg-12 col-md-9 mx-auto text-left">
            <h5 class="font-italic dark-color">
Published price structure may change, but will be honoured for existing contracted clients. Our price changes for current clients are tied to the US inflation per year.              </small>
            </h5>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>

    </main>

</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Header from "@/components/Header.vue";
import Main from "@/components/Main.vue";




export default {
  name: "home",
  components: {
    HelloWorld,
    Header,
    Main
  },
  data() {
    return {
      videoDialog: false,
      player: null
    };
  },  
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  },
  methods: {
    showAlert: function () {
      // alert('test')
      this.videoDialog = true

    }
  },
  watch: {
    videoDialog () {
      if (this.videoDialog) {
        if (this.player) {
          this.player.playVideo();
        } else {
          let onPlayerReady = () => {
            this.player.playVideo();
          }
          setTimeout(() => {
            this.player = new YT.Player('ytVideo', {
                events: {
                  // call this function when player is ready to use
                  'onReady': onPlayerReady
                }
            });
          }, 500)

        }
      } else {
          if (this.player) {
            this.player.pauseVideo();
          }

      }

    }
  },  
};
</script>

<style scoped>
.price {
  height: 50px;
}
.features {
  height: 190px;
}
</style>