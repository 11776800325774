<template>
<div>
  <main class="js-reveal">
    <section class="landing-section">
        <div class="container">
            <h1> Create In-Class Paper/PDF Exam</h1>
            <p>
                In addition to student submitted assignments, you can also create in-class exams. This article will show you how to create, administer and grade an in-class exam.
            </p>
            <h2> Create PDF Assessments</h2>
            <p>
            The instructor must have a <strong>question paper </strong>prepared in a <strong>PDF format </strong>to
             create an assessment in Assign2. The paper must contain all the 
            questions and space for students to write down their respective answers. 
            </p>
            <p>
            From the course manager, add an exam under a module, and click the exam title to direct to the Assign2 app. 
            From the Assessment types, choose <strong>In Person Exam</strong> option. 
            </p>
            <div class="pb-4">
                <img src="images/createPaper/create-paper.png" class="mb-2" alt="">
            </div>
            <p>
            Upload or drag-drop the prepared PDF file in the opened window. Click on <strong> Create Quiz </strong>button to continue.
            </p>
            <div class="pb-4">
                <img src="images/createPaper/upload-file.png" class="mb-2" alt="">
            </div>
            <p>
            Once uploaded, the PDF is opened in a preview mode that first shows the cover page by default. 
            Students can fill out their details in the given form. 
            </p>
            <div class="pb-4">
                <img src="images/createPaper/page1.png" class="mb-2" alt="">
            </div>
            <br> 
            <p>
            Scrolling down, instructors will then create the <strong>Question placeholders </strong>to be linked to the Assign2 grading interface.
             To add a question, click <strong>Add Question </strong>then drag and drop the blue question marker over top of the question 
             in the underlying template, then enter the <strong>maximum score </strong>for the question to match the assessment item.
             Repeat this process for every question in the assessment. Once complete, scroll down and click <strong>Save </strong>.
            </p>

            <div class="pb-4">
                <img src="images/createPaper/questions.png" class="mb-2" alt="">
            </div>
            <br> 

            <div class="article-box">
                <p>
                    Note: On the <strong> last page </strong> of the paper, there is a button named <strong> Add multiple choice </strong> available under Add question button. 
                    Click on that to add Multiple choice questions.
                </p>
            </div>

            <div class="pb-4">
                <img src="images/createPaper/mcq.png" class="mb-2" alt="">
            </div>
            <br> 


            <h2> Downloading and Printing Assessment </h2>
            <p>
            Upon uploading the exam Pdf file, an option for <strong>Download Exam </strong>appears in the navigation menu available on the left side of the page.
             Click on that to proceed to the printing option. 
            </p>
            <p>
            From the top right corner click on <strong>Generate Extra Exams</strong> and enter the number of printed
             copies in the popped-up window as required. Click <strong>Generate.</strong>
            </p>

            <div class="pb-4">
                <img src="images/createPaper/print.png" class="mb-2" alt="">
            </div>
            <br> 

            
            <p>
            This will generate a zip file that can be downloaded and printed in a single run, creating a specified number of copies. The option stays there even after printing for reuse some other time for students in different sections, for students completing deferred exams, etc. However, the
            use of prefixes may help the instructor to keep various versions separate. 
            </p>

            <p>
            The system will print the<strong> barcode</strong> on each copy that later helps the system to identify and collate scanned completed 
            copies and associates them with the students who have completed them.
            </p>

             <div class="pb-4">
                <img src="images/createPaper/download.png" class="mb-2" alt="">
            </div>
            <br> 

            <div class="article-box">
                <p>
                <strong> Note: </strong>  The printed copies generated 
                from the exam file can only be used once because of the barcodes. 
                </p>
            </div>
            <p>
            The printed exam is then given to students who need to fill out their Names, and ID’s. Bubble-in IDs must be filled clearly to avoid any
             trouble in scanning for marking purposes. 
            </p>


            <h2>Scanning and Uploading Assessments </h2>
            <p>
            Once the students get done writing down their answers, collect the print-outs again and <strong>scan </strong>them to upload the
             copy back to the system for grading. 
            </p>
            <p>
            Scanning of the documents can be completed using standard office printer/scanners including continuous feed devices that output single PDF files. Scanning should occur at 200dpi resolution or higher and should be done on a darker setting. The resulting PDF file containing any number of individual student copies can be uploaded by opening the navigation 
            menu via the icon at the top left, then selecting <strong>Upload exams</strong>.
            </p>
            <p>
            Scanned PDF files can be drag-dropped or browsed by clicking on the dialog panel on the right of the screen. Multiple files can be uploaded as per need. Once Upload is clicked, files are added to the files list on the left and the system 
            begins processing them with progress tracked in the center.
            </p>
            <p>
            The system may identify problems in the scanned exams in case students have failed to bubble their Student ID properly or may have entered an invalid ID by mistake. The problematic page will be shown in the counter section named <strong> Failed Page</strong>. 
            </p>

             <div class="pb-4">
                <img src="images/createPaper/upload-scan.png" class="mb-2" alt="">
            </div>
            <br> 

            <p>
            Following are the cases where the instructor needs to solve problems manually to complete the upload process successfully. 
            </p>

             <div class="pb-4">
                <img src="images/createPaper/failed-pages.png" class="mb-2" alt="">
            </div>
            <br> 

            <h3> 1. Partially scanned </h3>


            <p>
            Click on the icon available in front of the Partially Scanned option to review and resolve issues in any scanned but unmatched problem pages. This will list down all the pages for any incomplete or partial exams 
            along with a list of all pages within a specific exam.
            </p>
            <ul>
                <li>
                <strong> Missing pages: </strong>
                This Listing allows the identification of any missing pages. Click on the page name  to display a specific page for further review if required. The instructor may have to go back to the 
                original paper exam to verify the student and the associated exam.
                </li>
                <li>
                <strong> Missing barcodes: </strong>
                : If a barcode is missing or obscured, the instructor can view the page by clicking its link. Click on the <strong>Enter Barcode </strong>button in front of the page link,
                 type the respective barcode and click on Process Page. 
                 </li>
            </ul>

        <div class="pb-4">
                <img src="images/createPaper/partially.png" class="mb-2" alt="">
            </div>
            <br> 


            <h3> 2. Assigning Exams to Students </h3>
            
            <p>
            If students don't correctly bubble in their Student ID, their exams can be manually associated with their names by clicking the icon in front of <strong>Not Assigned</strong>. To link unassigned exams to a particular student, the completed exam is displayed in a preview window. Identify the exam author and select the name from the dropdown list
             displaying students. Click <strong>Assign Exam</strong> to get the process done. 
            </p>
            <h2> Marking Student Exams </h2>
            <p>
            The Assign2 grading interface is accessed through the top left nav tray by clicking Exam<strong> Marking</strong>.
             Any exams that have ANY of their pages scanned will show up in the grading interface. 
             The grading functions and workflows are the same as for online Assign2 assessments. 
            </p>
            <br>
            
            

           
            </div>
    </section>
  </main>
</div>
</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style scoped>
h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    padding: 50px 0 30px;
    text-transform: initial;
}
.landing-section p {
    font-size: 16px;
    color: #333333;
    text-align: left;
    margin-bottom: 25px;
}
.landing-section p a {
    color: #2e75bf;
    border-bottom: 0;
    text-decoration: none;
}
.landing-section h2 {
    margin: 0;
    padding-top: 0;
    font-size: 26px;
    color: #525252;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    padding-bottom: 25px;
}
.landing-section img {
    width: 60%;
}
.article-box {
    padding: 20px 24px;
    margin-bottom: 30px;
    background: #f4f4f4;
    border-left: 2px solid #dddddd;
}
.article-box ul li {
    margin-bottom: 10px;
}
.article-box ul li:last-of-type {
    margin-bottom: 0;
}
ul {
    margin: 0;
    padding: 0 0 0 30px;
}
.landing-section ul li {
    font-size: 16px;
    color: #333333;
    text-align: left;
    list-style: disc;
    margin-bottom: 20px;
}
.landing-section ul li a {
    color: #2e75bf;
    padding: 0 !important;
    font-size: 16px !important;
    display: inline-block !important;
}
h3 {
    padding: 0;
    font-size: 20px;
    margin: 0 0 20px;
    color: #525252;
    font-weight: 500;
    text-align: left;
}
.landing-section a {
    display: inline;
    font-size: 16px;
    color: #2e75bf;
    text-align: left;
    padding-bottom: 15px;
}
h4 {
    margin: 0;
    font-size: 18px;
    color: #525252;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    h1 {
        font-size: 24px;
        line-height: 30px;
        padding: 30px 0 20px;
    }
    .landing-section p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    ul {
        padding: 0 0 0 15px;
    }
    .landing-section ul li {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .landing-section ul li a {
        font-size: 14px !important;
    }
    .landing-section h2 {
        font-size: 20px;
        line-height: 22px;
        padding-bottom: 15px;
    }
    .landing-section p a {
        padding-bottom: 0;
    }
    .landing-section h3 {
        padding-top: 0;
    }
    .landing-section h4 {
        font-size: 14px;
    }
}

</style>