<template>
<div>
  <main class="js-reveal">
    <section class="landing-section">
        <div class="container">
            <h1>Grading Student Work in Assign2</h1>
            <p>
            Assign2 provides an efficient interface for instructors to mark the assessments. The instructor can mark the assignments by using the <strong>Marking </strong>tab in the navigation menu. The <strong>grading interface </strong>will appear that displays the following panels and options.
            </p>
            <!-- <p>
            Once the assignments are submitted by students, the instructor can go to the navigation menu and select the <strong>Marking </strong>
            tab from there.  The <strong>grading interface </strong>will appear that displays the following panels and options.
            </p> -->
            <h2> Top Panel </h2>
            <h3> Markup tools </h3>
            <p>
            The panel at the top of the page provides multiple tools for marking. This panel includes: 
            </p>
             <div class="pb-4">
                <img src="images/markAssign/top.png" class="mb-2" alt="">
            </div>
            <br>
            <ul>
            <li>
            <strong>Color Picker: </strong> Graders can change the color of the Pencil and the Highlighter tools by selecting from the color picker.
            </li>
            <li>
            <strong>Pencil tool:</strong> To mark/write down something just like a regular pen. 
            </li>
            <li>
            <strong>Brush tool: </strong>To highlight anything in the document. 
            </li>
            <li>
            <strong>Correct flag:</strong> To tick mark the question.
            </li>
            <li>
            <strong>Incorrect flag: </strong>To cross mark any question.
            </li>
            <li>
            <strong>Rotate tool:</strong> The instructor can use it in case the orientation of the file submitted by the student is wrong. 
            </li>
             <div class="pb-4">
                <img src="images/markAssign/rotate.png" class="mb-2" alt="">
            </div>
            <br>
            
            <li>
            <strong>Delete tool: </strong>Graders can remove markings, symbols, and comments by selecting the item to delete and then clicking on the <strong>Delete</strong> icon.
            </li>
            <li>
            <strong>Commenting tool:</strong> Any feedback or annotations can be added by the instructor using this tool.
             The comment section supports plain text, Tex formatted comments, or images. 
            <p>
            Instructors can <strong>save </strong>the comments for re-use purposes and can also <strong>share </strong>with other graders for the same
            question in the course.
              Saved comments require  <strong>Title </strong>. A grade can be added as well. Negative grades can also be added. 
            </p>
            <p>
            Saved comments appear under the scoring field on the left panel. To add a comment (and associated grade), click the <strong> + </strong>
            icon and then click on the desired spot on the assessment. 
            </p>
             
            <p>
            The instructor can <strong>edit </strong>or <strong>delete</strong> any comment by clicking on the icons available in front of each 
            comment name in the left panel.  Editing the score of a comment after it has already been used to score assessments will
             update all grades already entered. (It may be necessary to refresh your screen to see these changes.) 
            </p>
            </li>
            <div class="pb-4">
                <img src="images/markAssign/comment.png" class="mb-2" alt="">
            </div>
            <br>

            
            </ul>

            <h3>Settings  </h3>
        
            <p>
            Click on the <strong>Settings</strong> icon on the top right to set up different options for marking each question separately.
             Quick scores can also be added or removed in the settings popped-up panel.  Click on the <strong>Save Settings </strong>button to continue marking.
            </p>
             <div class="pb-4">
                <img src="images/markAssign/settings.png" class="mb-2" alt="">
            </div>
            <br>
            <h3> Keyboard Shortcuts  </h3>
            <p>
            The system provides the option for keyboard shortcuts to ease the instructor. The instructor can manage these shortcuts as needed by 
            clicking on the <strong>Keyboard</strong> icon on the right of the top panel. 
            </p>
             <div class="pb-4">
                <img src="images/markAssign/shortcuts.png" class="mb-2" alt="">
            </div>
            <br>

            <h2>Left Panel</h2>
            <p>
            On the Left side Panel, the <strong>Total score </strong> acheived by the student for a question is displayed along with the name of the 
            instructor who is marking the assessment. 
            </p>
             <div class="pb-4">
                <img src="images/markAssign/left.png" class="mb-2" alt="">
            </div>
            <br>
            <p>
            Below the marks, all the comments added and saved by instructors are displayed.
            </p>

            <h2>Middle Panel </h2>
            <p>
            The assessments are displayed in the middle of the screen for marking. 
            <strong>Quick grade </strong>buttons also appear in the same panel on the right side of the submission.
             These are similar to comments. The instructor chooses one of the buttons that has a positive or negative score and 
             clicks on the spot where it needs to be added. The total of these quick grades will appear on the left panel. 
            </p>
            <div class="pb-4">
                <img src="images/markAssign/middle.png" class="mb-2" alt="">
            </div>
            <br>

            <h2> Right Panel </h2>

            <p>
            The right side panel lists all the submissions for an assessment which the instructors can filter using different criteria. 
            The grading interface allows multiple graders to score and markup either specific questions across all student submissions or
             entire student assignments at a time. Marking is always blind unless the <strong>Show student name </strong> is selected.
            </p>
            <table>
            <tr>
            <td>
             <div class="pb-4">
                <img src="images/markAssign/right1.png" class="mb-2" alt="">
            </div>
            <br>
            </td>
            <td>
             <div class="pb-4">
                <img src="images/markAssign/right2.png" class="mb-2" alt="">
            </div>
            <br>
            </td>
            </tr>
            </table>

            <h2>Bottom panel </h2>

            <p>
            The instructor can update the board for the <strong>next/ previous</strong> unmarked <strong>question</strong> or <strong>student </strong>
            from the bottom of the grading interface. 
            </p>
             <div class="pb-4">
                <img src="images/markAssign/bottom.png" class="mb-2" alt="">
            </div>
            <br>
            <p>
            Once done with marking all the submissions, the instructor can go back to the dashboard and <strong>Push grades </strong>to students by clicking on the file icon beside the number of fully marked assessments. 
            </p>
            <div class="pb-4">
                <img src="images/markAssign/marked.png" class="mb-2" alt="">
            </div>
            <br>


           
            
            
            
        </div>
    </section>
  </main>
</div>
</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style scoped>
h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    padding: 50px 0 30px;
    text-transform: initial;
}
.landing-section p {
    font-size: 16px;
    color: #333333;
    text-align: left;
    margin-bottom: 25px;
}
.landing-section p a {
    color: #2e75bf;
    border-bottom: 0;
    text-decoration: none;
}
.landing-section h2 {
    margin: 0;
    padding-top: 0;
    font-size: 26px;
    color: #525252;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    padding-bottom: 25px;
}
.landing-section img {
    width: 60%;
}
.article-box {
    padding: 20px 24px;
    margin-bottom: 30px;
    background: #f4f4f4;
    border-left: 2px solid #dddddd;
}
.article-box ul li {
    margin-bottom: 10px;
}
.article-box ul li:last-of-type {
    margin-bottom: 0;
}
ul {
    margin: 0;
    padding: 0 0 0 30px;
}
.landing-section ul li {
    font-size: 16px;
    color: #333333;
    text-align: left;
    list-style: disc;
    margin-bottom: 20px;
}
.landing-section ul li a {
    color: #2e75bf;
    padding: 0 !important;
    font-size: 16px !important;
    display: inline-block !important;
}
h3 {
    padding: 0;
    font-size: 20px;
    margin: 0 0 20px;
    color: #525252;
    font-weight: 500;
    text-align: left;
}
.landing-section a {
    display: block;
    font-size: 14px;
    color: #2e75bf;
    text-align: left;
    padding-bottom: 15px;
}
h4 {
    margin: 0;
    font-size: 18px;
    color: #525252;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    h1 {
        font-size: 24px;
        line-height: 30px;
        padding: 30px 0 20px;
    }
    .landing-section p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    ul {
        padding: 0 0 0 15px;
    }
    .landing-section ul li {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .landing-section ul li a {
        font-size: 14px !important;
    }
    .landing-section h2 {
        font-size: 20px;
        line-height: 22px;
        padding-bottom: 15px;
    }
    .landing-section p a {
        padding-bottom: 0;
    }
    .landing-section h3 {
        padding-top: 0;
    }
    .landing-section h4 {
        font-size: 14px;
    }
}

</style>