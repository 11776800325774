var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',{staticClass:"js-reveal"},[_c('section',{staticClass:"landing-section"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v("Submitting Assignments Using Assign2 (For Students)")]),_c('p',[_vm._v(" This guide is for students and describes how they can use the Assign2 tool to submit their assessments online. The tool allows students to complete their assignments on paper and upload the files after scanning. ")]),_c('h2',[_vm._v("Upload and Submit")]),_c('p',[_vm._v(" The first step for students to submit their assignment to the Assign2 app is to take images/scan their hand-written documents. Students are allowed to upload multiple files for a single assignment. ")]),_c('p',[_vm._v(" Go to the Assignment link to open and access the assignment. Under each question lies a panel that asks for a drag and drop or upload of a file. "),_c('Strong',[_vm._v(" Upload the answer files ")]),_vm._v(" into the panel. ")],1),_vm._m(0),_c('p',[_vm._v(" Students can benefit from the following options upon uploading files. ")]),_vm._m(1),_vm._m(2),_c('br'),_vm._m(3),_vm._m(4),_c('br'),_vm._m(5),_vm._m(6),_c('br'),_c('h2',[_vm._v(" View Marked Submission ")]),_c('p',[_vm._v(" Once your instructor has released the grades, students can view their graded submission in Assign2 as: ")]),_vm._m(7),_c('br'),_vm._m(8),_c('h2',[_vm._v("Late submissions ")]),_vm._m(9),_vm._m(10),_c('br')])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-box"},[_c('p',[_c('strong',[_vm._v(" Supported files:")]),_vm._v(" The accepted file types for Assign2 are any image file (.png, .jpg, etc.) and PDF. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('strong',[_vm._v(" Delete: ")]),_vm._v(" In case one has uploaded the wrong or extra file. ")]),_c('li',[_c('strong',[_vm._v(" Save: ")]),_vm._v(" Students can only save their files and progress in case they need to edit or upload more later. ")]),_c('li',[_c('strong',[_vm._v(" Edit: ")]),_vm._v(" If the image uploaded is not in the correct orientation, use the following buttons (from an order of left to right on the image below) to adjust it. "),_c('br'),_c('br'),_c('ul',[_c('li',[_vm._v("Counterclockwise ")]),_c('li',[_vm._v(" Horizontally flip")]),_c('li',[_vm._v("Clockwise ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4"},[_c('img',{staticClass:"mb-2",attrs:{"src":"images/submitAssign/edit.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('strong',[_vm._v(" Preview: ")]),_vm._v(" Upon uploading image files, their thumbnails appear in the panel. Students can click on them to enlarge them. Also, click on the Submit button to preview the whole assignment. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4"},[_c('img',{staticClass:"mb-2",attrs:{"src":"images/submitAssign/submit.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_c('strong',[_vm._v(" Submit:")]),_vm._v(" Scroll down the previewed files and click on "),_c('strong',[_vm._v(" Submit ")]),_vm._v(" button again to confirm submission. ")]),_c('div',{staticClass:"article-box"},[_c('p',[_vm._v(" A message "),_c('strong',[_vm._v(" “Your assignment has been submitted” ")]),_vm._v(" will appear to acknowledge successful submission. ")])]),_c('li',[_c('strong',[_vm._v(" Unsubmit:")]),_vm._v(" "),_c('p',[_vm._v(" Students are allowed to withdraw their submission and re-submit it again any time before the submission deadline. Ensure to post the solutions in the right question response within the assignment because once the submission due date has passed, the system will not review or edit the files. ")]),_c('p',[_vm._v(" To withdraw your submission, click on the "),_c('strong',[_vm._v(" Unsubmit")]),_vm._v(" button. If the browser resolution is too low, one may not see the button and will need to pan to the right to see it. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4"},[_c('img',{staticClass:"mb-2",attrs:{"src":"images/submitAssign/unsubmit.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Click on the Assign2 activity in the course. ")]),_c('li',[_vm._v(" Scroll and view the marks assigned to each question in front of it separately. ")]),_c('li',[_vm._v(" Click on the tab named "),_c('strong',[_vm._v(" Grades ")]),_vm._v(" available in the navigation tray on the left to view the Grader report. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('tr',[_c('td',[_c('div',{staticClass:"pb-4"},[_c('img',{staticClass:"mb-2",attrs:{"src":"images/submitAssign/grade2.png","alt":""}})])]),_c('td',[_c('div',{staticClass:"pb-4"},[_c('img',{staticClass:"mb-2",attrs:{"src":"images/submitAssign/grade1.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" If the instructor allows late submissions, an alert will appear while completing the submission that says "),_c('strong',[_vm._v("“The assessment is overdue, Late penalties may apply”. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" If the instructor does not allow late submissions, the "),_c('strong',[_vm._v("“Assignment is past due date and does not allow late submission” ")]),_vm._v("alert will appear after the due date. ")])
}]

export { render, staticRenderFns }