<template>
<div>
  <main class="js-reveal">
    <section class="landing-section">
        <div class="container">
            <h1>Assign2 Help</h1>
            <p>
                Thank you for your interest in Assign2. Now that you have access to the platform,
                here are some helpful tips to help you navigate and experiment with the features
                we offer.
            </p>
            <h2>1. Creating a course</h2>
            <div class="pb-4">
                <img src="images/landing/my-courses-new.png" class="mb-2" alt="">
            </div>
            <p>
                Your Assign2 home page is the My Courses page, where you can view existing courses
                and create new ones. Your Assign2 home page is the My Courses page, where you can 
                view existing
            </p>
            <p>
                Click the <strong>Create a course</strong> button and enter the name of your course.
                If your course has sections, you can specify that here. the name of your course. 
                If your course has sections,
            </p>
            <div class="article-box">
                <p>
                    If you've signed in through your institution's LMS (Learning Management System),
                    If your course has sections, you can specify that here. the name of your course. 
                    you may see a button to <strong>Import a course</strong> instead.
                </p>
            </div>
            <h2>2. Enrolling students</h2>
            <div class="pb-4">
                <img src="images/landing/course-enroll-students.png" class="mb-2" alt="">
            </div>
            <p>
                To enroll students you'll need to upload a spreadsheet in CSV format. This CSV file must
                contain a column with student email addresses, but it is recommended to also include columns
                with first and last names, student numbers and section names. You may also wish to include
                yourself on the student roster so you can test and see what students see.
            </p>
            <p>
                This file can likely be generated and exported from your LMS. If not, your institution's
                IT department maybe be able to provide you with the student list.
            </p>
            <div class="article-box">
                <p>
                    If you imported the course from your LMS you will see a button to
                    <strong>Import students</strong> (without needing a CSV file) instead.
                </p>
            </div>
            <p>It is also easy for students to get started with Assign2.</p>
            <ul>
                <li>
                    Students are not notified when you enroll them into your course. Students are 
                    only able to see the course once an assessment has been distributed.
                </li>
                <li>
                    Students do not need to create an account until you distribute the first assessment
                    (you will be guided on how to do that further down this page). After distribution,
                    students who are new to Assign2 will be prompted to claim their account to access
                    Assign2.
                </li>
                <li>
                    This link for students provides more details: 
                    <a href="javascript:">Claiming your account (signing in for the first time)</a>
                </li>
            </ul>
            <h2>3. Creating an assessment</h2>
            <p>
                Assign2 supports two types of assessments: Administered
                (in-class paper-based assessments) and Assigned (remote assessments). 
                For more information on the different assessment types, see 
                <a href="javascript:">Choosing an assessment type.</a>
            </p>
            <h3>a. Administered assessment</h3>
            <div class="pb-4">
                <img src="images/landing/administered-setup-template.jpg" class="mb-2" alt="">
            </div>
            <div>
                <a href="javascript:">See full video transcript and instructions</a>
            </div>
            <ul>
                <li>
                    Start by clicking <strong>Create assessment</strong>. Name your assessment and choose which 
                    students and instructional team members should have access to the assessment.
                    If your course has sections, this is where you would designate which section 
                    the assessment is for.
                </li>
                <li>Select the <strong>Administered</strong> assessment type.</li>
                <li>
                    Set up your assessment template as a PDF file. This can be an assessment 
                    you’ve already created and used in the past, but may require some modification
                    for Assign2’s formatting. Assign2 places a QR code on every page as a way 
                    to organize student submissions and streamline grading. Your template must have
                    a cover page where students can write their names and other information, but 
                    this page will not be visible for grading so it should not have any questions
                    on it. See <a href="javascript:">Creating an assessment template</a> for details.
                </li>
            </ul>
            <div class="article-box">
                <p>
                    If you're using bubble sheets for multiple choice questions, you do not need 
                    to define the locations of the MC question prompts as the bubble sheet will 
                    be graded automatically.
                </p>
            </div>
            <div class="pb-4">
                <img src="images/landing/administered-setup-questions.jpg" class="mb-2" alt="">
            </div>
            <p>
                Once students have submitted work and the due date has passed, you’ll see the 
                submissions organized by question in the Grading panel. This is a helpful
                organizational tool that allows a team of graders to easily divide up work 
                and grade one question or one student at a time.
            </p>
            <p>
                On the right hand side of the screen you can enter a <strong>score</strong> 
                for the student's response, <strong>move through booklets</strong> and enter
                <strong>tags</strong> to bookmark or for reference later on.
            </p>
            <h2>5. Returning student work</h2>
            <div class="pb-4">
                <img src="images/landing/results.png" class="mb-2" alt="">
            </div>
            <p>
                When grading is complete, you can click into the <strong>Results</strong> tab 
                of the assessment to see a <strong>graph of student performance</strong>, return 
                student work, export the grades to a <strong>CSV file</strong>, and export the graded
                booklets for your files.
            </p>
            <div class="article-box">
                <p>
                    If the course is connected to your LMS, you will also be able to export grades
                    directly to the LMS gradebook.
                </p>
            </div>
            <p>
                To return student work, including with your grading and feedback, click 
                <strong>Send grades to students</strong>. Each student will receive an email 
                notification with a link to view their work in their Assign2 account. 
                They are able to see all the markup and feedback you’ve left on their assessment.
            </p>
            <p>
                Once a student has completed multiple assessments, they’re able to access a
                <strong>Performance report</strong> in their Assign2 account. This report 
                allows the student to see their progress across assessments in a course, as
                well as highlighting their strongest and weakest performance points throughout.
                This information helps students to focus their learning, and gain a deeper 
                understanding of their progress.
            </p>
            <div class="article-box">
                <p>
                    For help with or more details about a specific LMS integration,
                    check out the Help Center sections below:
                </p>
                <ul>
                    <li><a href="javascript:">Exporting grades to Blackboard's gradebook</a></li>
                    <li><a href="javascript:">Exporting grades to Canvas's gradebook</a></li>
                    <li><a href="javascript:">Exporting grades to Brightspace's gradebook</a></li>
                    <li><a href="javascript:">Exporting grades to Moodle's gradebook</a></li>
                </ul>
            </div>
            <h2>Congratulations!</h2>
            <h4>You’ve now successfully navigated your first Assign2 assessment!</h4>
        </div>
    </section>
  </main>
</div>
</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style>
h1 {
    margin: 0;
    font-size: 40px;
    font-weight: 800;
    text-align: left;
    line-height: 40px;
    padding: 50px 0 30px;
    text-transform: uppercase;
}
.landing-section p {
    font-size: 16px;
    color: #333333;
    text-align: left;
    margin-bottom: 25px;
}
.landing-section p a {
    color: #2e75bf;
    border-bottom: 0;
    text-decoration: none;
}
.landing-section h2 {
    margin: 0;
    padding-top: 0;
    font-size: 26px;
    color: #525252;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    padding-bottom: 25px;
}
.landing-section img {
    width: 100%;
}
.article-box {
    padding: 20px 24px;
    margin-bottom: 30px;
    background: #f4f4f4;
    border-left: 2px solid #dddddd;
}
.article-box ul li {
    margin-bottom: 10px;
}
.article-box ul li:last-of-type {
    margin-bottom: 0;
}
ul {
    margin: 0;
    padding: 0 0 0 30px;
}
.landing-section ul li {
    font-size: 16px;
    color: #333333;
    text-align: left;
    list-style: disc;
    margin-bottom: 20px;
}
.landing-section ul li a {
    color: #2e75bf;
    padding: 0 !important;
    font-size: 16px !important;
    display: inline-block !important;
}
h3 {
    padding: 0;
    font-size: 20px;
    margin: 0 0 20px;
    color: #525252;
    font-weight: 500;
    text-align: left;
}
.landing-section a {
    display: block;
    font-size: 14px;
    color: #2e75bf;
    text-align: left;
    padding-bottom: 15px;
}
h4 {
    margin: 0;
    font-size: 18px;
    color: #525252;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    h1 {
        font-size: 24px;
        line-height: 30px;
        padding: 30px 0 20px;
    }
    .landing-section p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    ul {
        padding: 0 0 0 15px;
    }
    .landing-section ul li {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .landing-section ul li a {
        font-size: 14px !important;
    }
    .landing-section h2 {
        font-size: 20px;
        line-height: 22px;
        padding-bottom: 15px;
    }
    .landing-section p a {
        padding-bottom: 0;
    }
    .landing-section h3 {
        padding-top: 0;
    }
    .landing-section h4 {
        font-size: 14px;
    }
}

</style>