<template>

    <section class="submit-assign-section">
        <div class="containe">
            <div class="content-area">
                <h1>This is the title</h1>
                <p>
                    Some description
                </p>
                <div class="card">
                    <h5>Contents:</h5>
                    <ul>
                        <li>
                            <a href="#item1">
                                <span>1</span>
                                Item 1
                            </a>
                        </li>
                        <li>
                            <a href="#item2">
                                <span>2</span>
                                Item 2
                            </a>
                        </li>
                        <li>
                            <a href="#item3">
                                <span>3</span>
                                Item 3
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- /// -->
                <div class="pt-55" id="item1">
                    <h2>Item 1</h2>
                    <p>
                        Once students have completed assignments, work can be graded by clicking the 
                        <strong>Marking</strong> option from the navigation dropdown at top left.
                    </p>
                    <div class="mb-20">
                        <img src="images/marking-submissions/assign2_EnterMarking.png" width="828" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
};

</script>

<style scoped>
.pt-none {
    padding-top: 0;
}
.mt-25 {
    margin-top: 25px;
}
.ml-15 {
    margin-left: 15px !important;
}
.ml-20 {
    margin-left: 20px;
}
.ml-23 {
    margin-left: 23px;
}
.ml-50 {
    margin-left: 50px !important;
}
.mb-20 {
    margin-bottom: 20px;
}
.r-color {
    color: #ff0000;
}
.flex-box {
    display: flex;
}
.flex-box span {
    margin-right: 8px;
}
.submit-assign-section {
    background: #f4f4f5;
    padding: 50px 15px 50px;
    min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
    width: 1140px;
    margin: 0 auto;
}
.submit-assign-section .content-area {
    text-align: left;
}
.submit-assign-section .content-area h1 {
        padding: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
    font-size: 13px;
    color: #92969b;
    font-weight: 300;
    margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
    font-size: 16px;
    color: #2c3e50;
    line-height: 26px;
}
.submit-assign-section .content-area p span {
    color: #2e75bf;
}
.submit-assign-section .content-area .card {
    max-width: 320px;
    padding: 10px 15px;
    background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
    padding: 0;
}
.submit-assign-section .content-area .list-view {
    margin-bottom: 15px;
}
.submit-assign-section .content-area .list-view li {
    font-size: 15px;
    color: #2c3e50;
    list-style: disc;
    line-height: 30px;
}
.submit-assign-section .content-area .card ul li a {
    font-size: 15px;
    color: #2e75bf;
    font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
    color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
    color: #2c3e50;
    margin-right: 3px;
}
.submit-assign-section .content-area h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    text-decoration: underline;
}
.submit-assign-section .content-area h3 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 10px;
}
.submit-assign-section .content-area img {
    margin-left: 23px;
    margin-bottom: 20px;
    border: 1px solid #d8dbdf;
}
.pt-55 {
    padding-top: 55px;
}
.count-details {
    width: 100%;
    max-width: 900px;
    background: #ffffff;
    border: 1px solid #cfd4d6;
}
.count-details .count-box {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #cfd4d6;
}
.count-details .count-box h3 {
    width: 50%;
    font-size: 15px;
    margin-bottom: 0;
    padding: 6px 10px 4px;
}
.count-details .count-box h3 span {
    font-weight: 400;
}
.count-details .count-box p {
    width: 50%;
    font-size: 15px;
    margin-bottom: 0 !important;
    padding: 7px 10px 5px !important;
    border-left: 1px solid #cfd4d6;
}
@media (min-width: 920px) and (max-width: 1150px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 919px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .count-details .count-box {
        flex-direction: column;
    }
    .count-details .count-box h3 {
        width: 100%;
    }
    .count-details .count-box p {
        width: 100%;
        border-left: 0;
    }
}
@media (min-width: 340px) and (max-width: 767px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .submit-assign-section .content-area h2 {
        line-height: 30px;
    }
    .submit-assign-section .content-area h1 {
        font-size: 22px;
        line-height: 34px;
    }
    .ml-23 {
        margin-left: 0;
    }
    .ml-50 {
        margin-left: 0 !important;
    }
    .pt-55 {
        padding-top: 20px;
    }
    .count-details .count-box {
        flex-direction: column;
    }
    .count-details .count-box h3 {
        width: 100%;
    }
    .count-details .count-box p {
        width: 100%;
        border-left: 0;
    }
    .ml-15 {
        margin-left: 0 !important;
    }
}
</style>