<template>
<div>
  <main class="js-reveal">
    <section class="landing-section">
        <div class="container">
            <h1>Submitting Assignments Using Assign2 (For Students)</h1>
            <p>
            This guide is for students and describes how they can use the Assign2 tool to submit their assessments online. The tool allows students to 
            complete their assignments on paper and upload the files after scanning. 
                </p>

            <h2>Upload and Submit</h2>

            <p>
            The first step for students to submit their assignment to the Assign2 app is to take images/scan their hand-written documents. 
            Students are allowed to upload multiple files for a single assignment.  
            </p>
            <p>
            Go to the Assignment link to open and access the assignment. Under each question lies a panel that asks for a drag
             and drop or upload of a file. <Strong> Upload the answer files </strong> into the panel.
            </p>
             <div class="article-box">
                <p>
                <strong> Supported files:</strong> The accepted file types for 
                Assign2 are any image file (.png, .jpg, etc.) and PDF.
                </p>
            </div>
            <p>
            Students can benefit from the following options upon uploading files.
            </p>


            <ul>
            <li>
            <strong> Delete: </strong> In case one has uploaded the wrong or extra file.
            </li>
            <li>
            <strong> Save: </strong>  Students can only save their files and progress in case they need to edit or upload more later.
            </li>
            <li>
            <strong> Edit: </strong>  If the image uploaded is not in the correct orientation, use the following buttons (from an order of left to right on the image below) to adjust it. 
            <br>
            <br>
            <ul>
            <li>Counterclockwise </li>
            <li> Horizontally flip</li>
            <li>Clockwise </li>
            </ul>


            </li>
            </ul>
            <div class="pb-4">
                <img src="images/submitAssign/edit.png" class="mb-2" alt="">
            </div>

            <br>
            <ul>
            <li>
            <strong> Preview: </strong> Upon uploading image files, their thumbnails appear in the panel. Students can click on 
            them to enlarge them. Also, 
            click on the Submit button to preview the whole assignment. 
            </li>
            </ul> 

            <div class="pb-4">
                <img src="images/submitAssign/submit.png" class="mb-2" alt="">
            </div>
            <br>
            
            <ul>
            <li>
            <strong> Submit:</strong>  Scroll down the previewed files and click on <strong> Submit </strong> button again to confirm submission. 

            </li>
            <div class="article-box">
                <p>
                A message <strong> “Your assignment has been submitted” </strong> will appear to acknowledge successful submission. 
                </p>
            </div>

            <li> 
           <strong>  Unsubmit:</strong> <p> Students are allowed to withdraw their submission and re-submit it again any time before the submission deadline. 
           Ensure to post the solutions in the right question response within the assignment because once the submission due date has passed, 
           the system will not review or edit the files.  
            </p>
            <p>
            To withdraw your submission, click on the <strong> Unsubmit</strong> button. If the browser resolution is too low, one may not see the button and will need to pan to the right to see it.
            </p>
            </li>
            </ul>
             <div class="pb-4">
                <img src="images/submitAssign/unsubmit.png" class="mb-2" alt="">
            </div>

            <br>

            <h2> View Marked Submission </h2>
            <p>
            Once your instructor has released the grades, students can view their graded submission in Assign2 as: 

            </p>
            <ul>
            <li>
            Click on the Assign2 activity in the course.
            </li>
            <li>
            Scroll and view the marks assigned to each question in front of it separately.
            </li>
            <li>
            Click on the tab named <strong> Grades </strong> available in the navigation tray on the left to view the Grader report.
            </li>
            </ul>
            <br>

            <table>
            <tr>
            <td>
             <div class="pb-4">
                <img src="images/submitAssign/grade2.png" class="mb-2" alt="">
            </div>

            </td>
            <td>
             <div class="pb-4">
                <img src="images/submitAssign/grade1.png" class="mb-2" alt="">
            </div>
            </td>
            </tr>
            </table> 

            <h2>Late submissions </h2>
            <p>
             If the instructor allows late submissions, an alert will appear while
              completing the submission that says <strong>“The assessment is overdue, 
              Late penalties may apply”. </strong>
              </p>
              <p>
               If the instructor does not allow late 
              submissions, the <strong>“Assignment is past due date and does not allow late 
              submission” </strong>alert will appear after the due date. 
            </p>

            

            <br>

            
            


            
        </div>
    </section>
  </main>
</div>
</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style>
h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    padding: 50px 0 30px;
    text-transform: uppercase;
}
.landing-section p {
    font-size: 16px;
    color: #333333;
    text-align: left;
    margin-bottom: 25px;
}
.landing-section p a {
    color: #2e75bf;
    border-bottom: 0;
    text-decoration: none;
}
.landing-section h2 {
    margin: 0;
    padding-top: 0;
    font-size: 26px;
    color: #525252;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    padding-bottom: 25px;
}
.landing-section img {
    width: 60%;
}
.article-box {
    padding: 20px 24px;
    margin-bottom: 30px;
    background: #f4f4f4;
    border-left: 2px solid #dddddd;
}
.article-box ul li {
    margin-bottom: 10px;
}
.article-box ul li:last-of-type {
    margin-bottom: 0;
}
ul {
    margin: 0;
    padding: 0 0 0 30px;
}
.landing-section ul li {
    font-size: 16px;
    color: #333333;
    text-align: left;
    list-style: disc;
    margin-bottom: 20px;
}
.landing-section ul li a {
    color: #2e75bf;
    padding: 0 !important;
    font-size: 16px !important;
    display: inline-block !important;
}
h3 {
    padding: 0;
    font-size: 20px;
    margin: 0 0 20px;
    color: #525252;
    font-weight: 500;
    text-align: left;
}
.landing-section a {
    display: block;
    font-size: 14px;
    color: #2e75bf;
    text-align: left;
    padding-bottom: 15px;
}
h4 {
    margin: 0;
    font-size: 18px;
    color: #525252;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    h1 {
        font-size: 24px;
        line-height: 30px;
        padding: 30px 0 20px;
    }
    .landing-section p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    ul {
        padding: 0 0 0 15px;
    }
    .landing-section ul li {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .landing-section ul li a {
        font-size: 14px !important;
    }
    .landing-section h2 {
        font-size: 20px;
        line-height: 22px;
        padding-bottom: 15px;
    }
    .landing-section p a {
        padding-bottom: 0;
    }
    .landing-section h3 {
        padding-top: 0;
    }
    .landing-section h4 {
        font-size: 14px;
    }
}

</style>