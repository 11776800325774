<template>
  <div>
    <main class="js-reveal">
      <!-- ============================================================= SECTION – HERO ============================================================= -->

      <section id="hero">
        <div id="owl-main" class="owl-carousel owl-one-item">
          <div
            class="item img-bg-center edufideblue"
            style="background-image: url(images/art/slider01.jpg);"
          >
            <div class="container">
              <div class="caption vertical-center text-center">
                <h1 class="fadeInDown-1 light-color">Move2Moo</h1>
                <p
                  class="fadeInDown-2 light-color"
                >Facilitate your move between Learning Management Systems with Move2Moo's proven content conversion technology.</p>
                <h5>500,000+ courses converted to Moodle</h5>
                <div class="fadeInDown-3">
                  <a href="/#/contact" class="btn btn-large">Get a demo</a>
                </div>
                <!-- /.fadeIn -->
              </div>
              <!-- /.caption -->
            </div>
            <!-- /.container -->
          </div>
          <!-- /.item -->
        </div>
        <!-- /.owl-carousel -->
      </section>

      <section id="clients">
        <div class="container inner">
          <div class="row">
            <div class="col-lg-8 col-md-9 mx-auto text-center">
              <header>
                <h1>Clients</h1>
                <p>Some of the clients who have migrated their LMS content with Move2Moo</p>
              </header>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->

          <div class="row thumbs gap-lg inner-top-sm">
            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>Carleton University</h4>
              </div>
              <!-- /.info -->
            </div>
            <!-- /.col -->
            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>University of East London</h4>
              </div>
              <!-- /.info -->
            </div>
            <!-- /.col -->
            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>Queen Mary - University of London</h4>
              </div>
              <!-- /.info -->
            </div>
            <!-- /.col -->
            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>King's College London</h4>
              </div>
              <!-- /.info -->
            </div>
            <!-- /.col -->
            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>University of Northern British Columbia</h4>
              </div>
              <!-- /.info -->
            </div>
            <!-- /.col -->
            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>University of Lethbridge</h4>
              </div>
              <!-- /.info -->
            </div>

            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>Bridgewater State University</h4>
              </div>
              <!-- /.info -->
            </div>

            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>Norquest College</h4>
              </div>
              <!-- /.info -->
            </div>

            <!-- <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>Glendate College</h4>
              </div>
            </div> -->

            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>Cedarville University</h4>
              </div>
              <!-- /.info -->
            </div>

            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>University of California - Fullerton</h4>
              </div>
              <!-- /.info -->
            </div>

            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>Kumamoto University</h4>
              </div>
              <!-- /.info -->
            </div>
            <div class="col-lg-3 col-md-4 col-6 thumb">
              <div class="info">
                <h4>University of Hradec Králové</h4>
              </div>
              <!-- /.info -->
            </div>
            
            <!-- /.col -->

            <!-- /.col -->

            <!-- /.col -->

            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container -->
      </section>
      <!-- ============================================================= SECTION – HERO : END ============================================================= -->

      <!-- ============================================================= SECTION – SERVICES ============================================================= -->

      <!-- ============================================================= SECTION – SERVICES : END ============================================================= -->

      <!-- ============================================================= SECTION – HOWITWORKS ============================================================= -->

      <!-- ============================================================= SECTION – HOWITWORKS : END ============================================================= -->

      <!-- ============================================================= SECTION – PRICING TABLES ============================================================= -->

      <!-- ============================================================= SECTION – PRICING TABLES : END ============================================================= -->
    </main>
  </div>
</template>

<script>
export default {
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  }
};
</script>

<style>
h5 {
  color: white;
}
</style>