<template>
<div>
  <main class="js-reveal">
    <section class="landing-section">
        <div class="container">
            <h1>Create Homework Assignment</h1>
            <h2>Configuring Assignment</h2>


            <p>
                The link to the created assignment is displayed under the module of the course.
                 Click the assignment title to open the link and choose from different assessment types. 
                 Select <strong>Homework</strong> option
            </p>
            
            <div class="pb-4">
                <img src="images/create-assignment/select-assessment.jpg" class="mb-2" alt="">
            </div>

            <p>
                It will direct the instructor to the Assignment Setup page. 
                The instructor will be asked to set up the following options: 
            </p>
            
            <ul>
                <li>
                    <strong>Assignment Description:</strong> This panel is to add the general instructions for students.
                     A long-form problem that is related to all/specific questions can also be added. 
                </li>
                <li>
                   <strong>Description Preview:</strong> 
                   This will automatically display how students will see any TeX notation written in the description.
                </li>
                <li>
                    <strong>Due Date and Time: </strong> By clicking these options, the instructor can set boundaries for submission cut-off.
                </li>
            
            
            <li>
                    <strong>Allow late submissions: </strong> Toggle this button on to allow submission after the cut-off time. 
            </li>
            <li>
                    <strong>Open to Students: </strong> The Instructor must toggle this on to make assignments accessible to students.  
            </li>
            </ul>

            <p>
            Alternatively, AFTER the assignment is created, it is possible to update the settings to set an 
            open date and time for the activity to use as a summative exam (or a future homework assignment.) 
            This will allow students to access it whenever the specified start time is reached. 
            </p>
            <p>
            Students can begin adding submissions by clicking the Assign2 link from the eClass course as 
            soon as the assignment is set to open for them.
            </p>
            <div class="pb-4">
                <img src="images/create-assignment/assignment-setup.jpg" class="mb-2" alt="">
            </div>
            <br>

            <h2> Creating Questions: </h2>
            <p>
            Right under the Assignment Setup, there is a <strong> Question Setup </strong> section.
            The instructor can add questions one by one as required.
            This section deals with the following options: 
            </p>
            <ul>
            <li>
            <strong> Question Text: </strong> Questions including any mathematical notations/ equations
             are added in this panel. This allows users to use technical languages such as TeX. 
            </li>
            <li>
            <strong>Preview:</strong> It shows how the text will appear to other users. 
            </li>
            <li>
            <strong> Marks: </strong> This is to add maximum marks for each question separately.
             The combined score for all marks must be the same as added to the external tool activity setting while creating the assessment. 
            </li>
            </ul>

            <p>
             To add more questions, a button displayed as + is there on the last added question.
            Once everything is added in all the sections, click the <strong>Save Assessment </strong> button to complete the setup. 
             </p>
             <p>
             It is also possible to configure the Assign2 tool as a submission device only (and deliver assignments
              to students as linked files through the eClass course). In this case, the only configuration required would be
               the due date/time and setting of the required number of questions and scores.
            </p>
            <div class="pb-4">
                <img src="images/create-assignment/question-setup.jpg" class="mb-2" alt="">
            </div>
            <br>

            <!-- <div class="article-box">
                <p>
                    <strong> Note: </strong> Once an assessment type is selected and saved, it <strong> cannot </strong>
                     be changed. A new assignment would need to be added if the wrong option was chosen. 
                </p>
            </div> -->

            <h2> Navigating Assign2 Assessments </h2>
            <p>
            Saving the assignment will direct the instructor to the <strong> Dashboard </strong>. Users can navigate through other
             tabs including <strong> Students </strong> and <strong> Marking </strong> by clicking the navigation drawer located at the top left corner of the page as shown below:
            </p>

             
            <table >
            <tr >
            <td>
            
                <img src="images/create-assignment/nav1.png" class="mb-2" alt="">
            </td>
            <br>
            <td>
                <img src="images/create-assignment/nav2.png" class="mb-2" alt="">
            </td>
            </tr>
            </table>
            <br>
             <p>
             The <strong> Dashboard </strong> displays the total number of submissions and marked assignments. 
             Assessment details relative to each question can also be tracked. 
            </p>
            <p>The table header of the <strong> Assessment details </strong>section on the dashboard contains a button 
            for accessing and updating the assignment/ questions if needed. 
             </p>

              <div class="pb-4">
                <img src="images/create-assignment/dashboard.jpg" class="mb-2" alt="">
            </div>
            <br>
            <p>
            The <strong> Student tab </strong> displays the information for each student. From this Section,
             the instructor can perform several actions for each student separately that include: 
            </p>
            <ul>
                <li>View files uploaded by a student</li>
                <li>View activity log for a student</li>
                <li>Upload work on behalf on a student</li>
                <li>Override due date for the student</li>
                <li>Download student's submission</li>
            </ul>
            <br>
            <p>
                The buttons for each action are 
              there against each student's name as shown in the image below: 
            </p>
            <div class="pb-4">
                <img src="images/create-assignment/student.jpg" class="mb-2" alt="">
            </div>
            <br>
             
            <!-- <div class="article-box">
            <p>
             <strong> Note: </strong> A demo student can also be added to the assignment list for testing purposes. 
            </p>
            </div> -->

            <p>
            Students can submit multiple files for each question using their desktop, laptop, or mobile device
             - so they would complete solutions to the problems on paper, photograph them, and upload the 
             photos. Student file submissions are automatically re-sized and rotated on submission.
             </p>
            <p><u>Accepted file types:</u> images ( PNG, JPG, etc) and PDF.
            <p>For further details about ensuring students have access to assignments see Managing
             Student Assessments in Assign2. When delivering assignments to students, instructors can 
             also provide them the link to a student-facing guide for using the tool: Submitting Assignment 
             Using Assign2 (For Students).
             </p>



 


        

           
           
        </div>
    </section>
  </main>
</div>
</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style scoped>
h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    padding: 50px 0 30px;
    text-transform: initial;
}

.landing-section p {
    font-size: 16px;
    color: #333333;
    text-align: left;
    margin-bottom: 25px;
}
.landing-section p a {
    color: #2e75bf;
    border-bottom: 0;
    text-decoration: none;
}
.landing-section h2 {
    margin: 0;
    padding-top: 0;
    font-size: 26px;
    color: #525252;
    font-weight: 500;
    text-align: left;
    line-height: 28px;
    padding-bottom: 25px;
}
.landing-section img {
    width: 60%;
}
.article-box {
    padding: 20px 24px;
    margin-bottom: 30px;
    background: #f4f4f4;
    border-left: 2px solid #dddddd;
}
.article-box ul li {
    margin-bottom: 10px;
}
.article-box ul li:last-of-type {
    margin-bottom: 0;
}
ul {
    margin: 0;
    padding: 0 0 0 30px;
}
.landing-section ul li {
    font-size: 16px;
    color: #333333;
    text-align: left;
    list-style: disc;
    margin-bottom: 20px;
}
.landing-section ul li a {
    color: #2e75bf;
    padding: 0 !important;
    font-size: 16px !important;
    display: inline-block !important;
}
h3 {
    padding: 0;
    font-size: 20px;
    margin: 0 0 20px;
    color: #525252;
    font-weight: 500;
    text-align: left;
}
.landing-section a {
    display: block;
    font-size: 14px;
    color: #2e75bf;
    text-align: left;
    padding-bottom: 15px;
}
h4 {
    margin: 0;
    font-size: 18px;
    color: #525252;
    font-weight: 600;
    text-align: left;
    padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    h1 {
        font-size: 24px;
        line-height: 30px;
        padding: 30px 0 20px;
    }
    .landing-section p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    ul {
        padding: 0 0 0 15px;
    }
    .landing-section ul li {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .landing-section ul li a {
        font-size: 14px !important;
    }
    .landing-section h2 {
        font-size: 20px;
        line-height: 22px;
        padding-bottom: 15px;
    }
    .landing-section p a {
        padding-bottom: 0;
    }
    .landing-section h3 {
        padding-top: 0;
    }
    .landing-section h4 {
        font-size: 14px;
    }
}

</style>