<template>
<div>
  <main class="js-reveal">
    <!-- ============================================================= SECTION – HERO ============================================================= -->

    <section id="hero">
      <div id="owl-main" class="owl-carousel owl-one-item">
        <div
          class="item img-bg-center edufideblue"
          style="background-image: url(images/art/slider01.jpg);"
        >
          <div class="container">
            <div class="caption vertical-center text-center">
              <h1 class="fadeInDown-1 light-color">Smart Exam Monitor</h1>
              <p
                class="fadeInDown-2 light-color"
              >Smart Exam Monitor (SEM) lets you monitor your students while they complete their exams and quizzes from home.</p>
              <div class="fadeInDown-3">
                <a href="contact" class="btn btn-large">Get a demo</a>
              </div>
              <!-- /.fadeIn -->
            </div>
            <!-- /.caption -->
          </div>
          <!-- /.container -->
        </div>
        <!-- /.item -->
      </div>
      <!-- /.owl-carousel -->
    </section>

    <!-- ============================================================= SECTION – HERO : END ============================================================= -->

    <!-- ============================================================= SECTION – SERVICES ============================================================= -->

    <section id="services">
      <div class="container inner-top inner-bottom-sm">
        <div class="row inner-top-sm text-center">
          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="fas fa-address-card icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>Confirms Identity</h2>
            <p
              class="text-small"
            >Using a template of your campus student IDs, SEM confirms a valid ID using computer vision. SEM can accept most card type IDs.</p>
          </div>
          <!-- /.col -->

          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="fas fa-user icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>Confirms Student</h2>
            <p
              class="text-small"
            >Using facial recognition, SEM matches the student to the ID provided.</p>
          </div>
          <!-- /.col -->

          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="icon-flow-tree icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>Integrated and secure</h2>
            <p
              class="text-small"
            >SEM integrates with your LMS via LTI, there are no plugins to install.</p>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row text-center">
          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="fab fa-chrome icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>No downloads</h2>
            <p
              class="text-small"
            >SEM works in Chrome browser and does not require any special software downloads by the student.</p>
          </div>
          <!-- /.col -->

          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="fas fa-video icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>Live Monitoring</h2>
            <p
              class="text-small"
            >SEM allows you to monitor your exams while they are happening. Events (such as cell phone use) are also tagged near real time.</p>
          </div>
          <!-- /.col -->

          <div class="col-md-4 inner-bottom-xs">
            <div class="icon">
              <i class="fas fa-brain icn lg"></i>
            </div>
            <!-- /.icon -->
            <h2>AI Based</h2>
            <p
              class="text-small"
            >SEM uses multiple AI and Machine Learning systems in the background to monitor a user's Video, Audio and Screen to automatically tag events for review.</p>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>

    <!-- ============================================================= SECTION – SERVICES : END ============================================================= -->

    <!-- ============================================================= SECTION – HOWITWORKS ============================================================= -->

    <section id="howitworks">
      <div class="container inner-top inner-bottom-sm">
        <div class="row">
          <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header>
              <h1>How it works</h1>
              <p>Setup is as simple as adding an LTI tool to your LMS</p>
            </header>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row inner-top-sm text-center">
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/work01.jpg"> -->
            </div>
            <h2>Integrate</h2>
            <p
              class="text-small"
            >Adding the tool is as simple as adding an LTI to your LMS or course</p>
          </div>
          <!-- /.col -->

          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-669646034-2048x2048.jpg"> -->
            </div>
            <h2>Setup</h2>
            <p
              class="text-small"
            >Multiple configurable exam sessions can be set up within a course.</p>
          </div>
          <!-- /.col -->
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Deliver</h2>
            <p
              class="text-small"
            >Students are monitored using cutting edge browser based technology. There is no complicated setup or software to download.</p>
          </div>
          <!-- /.col -->
          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-125965566-2048x2048.jpg"> -->
            </div>
            <h2>Monitor</h2>
            <p
              class="text-small"
            >Exams can be monitored as they happen, including the video and desktop of students. Any tagged events are highlighted.</p>
          </div>
          <!-- /.col -->

          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Report</h2>
            <p
              class="text-small"
            >Each exam gets a full package of monitoring data. This includes ID check, pre-exam video of students' environment and AI analyzed video of the exam. </p>
          </div>
          <!-- /.col -->

          <div class="col-md-2 inner-bottom-xs">
            <div class="icon-overlay icn-link">
              <!-- <img src="images/art/istockphoto-960164282-2048x2048.jpg"> -->
            </div>
            <h2>Paper</h2>
            <p
              class="text-small"
            >Ability to deliver and monitor paper exams is coming soon to SEM.</p>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>

    <!-- ============================================================= SECTION – HOWITWORKS : END ============================================================= -->

    <!-- ============================================================= SECTION – PRICING TABLES ============================================================= -->

    <section id="pricing-tables">
      <div class="container inner">
        <div class="row">
          <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header>
              <h1>Transparent, comprehensive pricing, no surprises</h1>
              <p></p>
            </header>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row pricing col-4-custom">
          <div class="col-xl-4 col-md-4 inner-top-sm">
            <div class="plan">
              <header>
                <h2>Basic Monitoring (no AI)</h2>

                <div class="price">
                  <span class="currency">Contact us for details</span>
                  <!-- <span class="currency">less than $</span>
                  <span class="amount">1</span>
                  <span class="period">/ Exam</span> -->
                </div>
                <!-- /.price -->

                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">
                <li>
                  <i class="fas fa-address-card"></i>ID Check
                </li>
                <li>
                  <i class="fas fa-user"></i>ID Matching
                </li>
                <li>
                  <i class="fas fa-video"></i>Recorded Video
                </li>
                <li>
                  <i class="fas fa-microphone"></i>Recorded Audio
                </li>
                <li>
                  <i class="fas fa-desktop"></i>Recorded Screen
                </li>

              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>
          <!-- /.col -->

          <div class="col-xl-4 col-md-4 inner-top-sm">
            <div class="plan">
              <header>
                <h2>Smart Monitoring (AI Based)</h2>
                <div class="price">
                  <span class="currency">Contact us for details</span>
                  <!-- <span class="currency">less than $</span>
                  <span class="amount">1</span>
                  <span class="period">/ Exam</span> -->
                </div>
                <!-- /.price -->
                <div>
                  <span class="amount">
                    <!-- <small>Minimum of $1,500</small> -->
                  </span>
                </div>
                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">

                <li>
                  <i class="fas fa-address-card"></i>ID Check
                </li>
                <li>
                  <i class="fas fa-user"></i>ID Matching
                </li>
                <li>
                  <i class="fas fa-video"></i>Tags suspicious Events (multiple people, cell phone use)
                </li>
                <li>
                  <i class="fas fa-microphone"></i>Transcribes any Audio
                </li>
                <li>
                  <i class="fas fa-desktop"></i>Monitor Screen (navigation away from exam etc.)
                </li>
              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>

                    <div class="col-xl-4 col-md-4 inner-top-sm" style="margin-bottom: 0px;">
            <div class="plan">
              <header>
                <h2>Smart Monitoring (AI Based)</h2>
                <div>
                  <span class="amount">
                    <small>Campus wide</small>
                  </span>
                </div>                

                <div class="price">
                  <span class="currency">Contact us for details</span>
                  <!-- <span class="currency">less than $</span>
                  <span class="amount">1</span>
                  <span class="period">/ Exam</span> -->
                </div>
                <!-- /.price -->
                <!-- <div>
                  <span class="amount">
                    <small>less than $1 / Exam</small>
                  </span>
                </div> -->
                <!-- <a href="assign2price.html" class="btn">Pricing details</a> -->
              </header>

              <ul class="features">

                <li>
                  <i class="fas fa-address-card"></i>ID Check
                </li>
                <li>
                  <i class="fas fa-user"></i>ID Matching
                </li>
                <li>
                  <i class="fas fa-video"></i>Tags suspicious Events (multiple people, cell phone use)
                </li>
                <li>
                  <i class="fas fa-microphone"></i>Transcribes any Audio
                </li>
                <li>
                  <i class="fas fa-desktop"></i>Monitor Screen (navigation away from exam etc.)
                </li>
              </ul>
              <!-- /.features -->
            </div>
            <!-- /.plan -->
          </div>
          <!-- /.col -->

        </div>
        <!-- /.row -->
        <div class="row">
          <div class="col-lg-8 col-md-9 mx-auto text-left">
            <h5 class="font-italic">
              <small>
                Published price structure may change, but
                will be honored for existing contracted clients. Our price increases for current clients are capped at 2 times
                the US inflation per year.
              </small>
            </h5>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>

    <!-- ============================================================= SECTION – PRICING TABLES : END ============================================================= -->
  </main>
</div>
</template>

<script>
export default {
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }


};
</script>

<style>
.currency {
    color: white;

}
.period {
    color: white;
}
.amount {
    color: white;
}
</style>