<template>

    <section class="submit-assign-section">
        <div class="containe">
            <div class="content-area">
                <h1>Creating Online Homework Assignments Using Assign2</h1>
                <p>
                    This article describes how instructors can create online homework assessments using the 
                    Assign2 external tool. Assign2 allows students to capture 
                    long-form written work as image files that can be uploaded for instructors mark online 
                    using a time-saving interface.
                </p>
<div class="ytcontainer">
<iframe class="ytvideo" width="640" height="360" src="https://www.youtube.com/embed/LuUEwU5SXE4" title="Creating an Assignment on Assign2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
                <div class="card">
                    <h5>Contents:</h5>
                    <ul>
                        <li>
                            <a href="#creatingAssignments">
                                <span>1</span>
                                Creating Assignments
                            </a>
                        </li>
                        <li>
                            <a href="#configuringAssignments">
                                <span>2</span>
                                Configuring Assignments
                            </a>
                        </li>
                        <li>
                            <a href="#creatingQuestions">
                                <span>3</span>
                                Creating Questions
                            </a>
                        </li>
                        <li>
                            <a href="#navigatingAssessments">
                                <span>4</span>
                                Navigating Assessments
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- // -->
                <div class="pt-55" id="creatingAssignments">
                    <h2>Creating Online Homework Assignments</h2>
                    <p>
                        A new Assign2 tool activity must be added to the course for each assignment that 
                        will be set up in the course. This process looks different in various learning management systems (LMS). Assign2 usually apprears under External Tools.
                    </p>
                </div>
                <!-- // -->
                <div class="pt-55" id="configuringAssignments">
                    <h2>Configuring Assignments</h2>
                    <p>
                        Once added to the course, click the tool link to select which of the different 
                        assignment types will be used and to configure the details:
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-tool_link.png" width="708" alt="">
                    </div>
                    <p>
                        Within the tool, select the <strong>Homework</strong> option:
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-assesment_type.png" width="733" alt="">
                    </div>
                    <p>
                        Once the Assessment type is selected, instructors can set up their assignment:
                    </p>
                    <ul class="list-view">
                        <li>
                            The <strong>Description</strong> field can add general instructions and/or a long 
                            form problem that can be used as the basis of specific questions later on. 
                        </li>
                        <li>
                            The <strong>Description Preview</strong> on the right hand side will automatically 
                            display any TeX notation.
                        </li>
                        <li>
                            The <strong>Due Date</strong> and <strong>Time</strong> set the boundary for 
                            submission cut-off.
                        </li>
                        <li class="ml-20">
                            <strong>IMPORTANT</strong>: Date and Time is based on universal time. e.g. If an 
                            instructor working in Toronto (Eastern time), sets a due date/time for 23:00, 
                            students in Mountain Time Zone will see it due at 21:00 instead.
                        </li>
                        <li>
                            It is possible to toggle <strong>Allow late submissions</strong> to permit 
                            submissions after the cut-off.
                        </li>
                        <li>
                            Assignments are not accessible by students until the 
                            <strong>Open to students</strong> is toggled on.
                        </li>
                        <li>
                            Alternatively, <strong>AFTER</strong> the assessment is created, it is possible to 
                            update the settings to <span>set an open date and time for the activity</span> to 
                            use it is a summative exam (or a future homework assignment.) This will allow 
                            students to access whenever the specified start time is reached. 
                        </li>
                    </ul>
                    <div>
                        <img src="images/docs-create-assignment/assign2-setup.png" width="958" alt="">
                    </div>
                    <h3>Notes:</h3>
                    <p>
                        - Once an assessment type is selected and saved, it <strong>cannot</strong> be changed 
                        so a new assignment would need to be added if the wrong option was chosen.
                    </p>
                </div>
                <!-- // -->
                <div class="pt-55" id="creatingQuestions">
                    <h2>Creating Questions</h2>
                    <p>
                        Individual questions are added under <strong>Question Setup</strong>. The question 
                        test is added on the left with any accompanying mathematical notation appearing in 
                        the preview on the right. The combined scores of all questions <strong>must</strong> 
                        match the total set for the grade of the external tool activity settings (see above).
                    </p>
                    <p>
                        Once all questions have been added, click <strong>Update Assessment</strong> to 
                        complete the setup. It is also possible to configure the Assign2 tool as a submission 
                        device only (and deliver assignments to students as linked files through the LMS 
                        course). In this case, the only configuration required would be the due date/time 
                        and setting the required number of questions and scores.
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-question.png" width="958" alt="">
                    </div>
                    <p>
                        To access and update questions after creating an assessment, instructors should click 
                        the <strong>Edit Exam</strong> option on the dashboard:
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-edit_exam.png" width="958" alt="">
                    </div>
                </div>
                <!-- // -->
                <div class="pt-55" id="navigatingAssessments">
                    <h2>Navigating Assign2 Assessments</h2>
                    <p>
                        Once an assignment configuration and questions are saved, instructors can navigate 
                        within the assessment using 3 different tabs: <span>Students</span>, 
                        <span>Marking</span>, or <span>Dashboard</span>. Users can switch between tabs from 
                        the top left nav drawer:
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-tabs.png" width="483" alt="">
                    </div>
                    <div>
                        <img src="images/docs-create-assignment/assign2-tabs2.png" width="483" alt="">
                    </div>
                </div>
                <!-- // -->
                <div class="pt-55">
                    <h2>Students Tab</h2>
                    <p>
                       The <strong>Students</strong> tab will display the students information as seen below:
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-student_tab.png" width="958" alt="">
                    </div>
                    <!-- <p>
                        For further details about the specific actions available on the <strong>Students</strong> 
                        tab, and especially to ensure that students have access to assignments see 
                        <span>Managing Student Assessments in Assign2</span>.
                    </p> -->
                    <p>
                        When delivering assignments to students, instructors can provide them the link to a 
                        student-facing guide for using the tool: <span> <a href="docs-submit-assignment">Submitting Assignment Using Assign2 (For Students)</a> </span>. 
                        Students will access their assignment submission area by clicking the Assign2 link 
                        from the LMS course and begin adding submissions when assignments are set to 
                        <strong>Open for students</strong>. Students will complete solutions to the problems on paper, 
                        photograph them, and upload the photo(s)/file(s) for each question using their desktop, laptop, 
                        or mobile device in <strong>any of the accepted image file types: PNG, JPG, and PDF files.</strong> 
                        File submissions are automatically re-sized and rotated on submission.
                    </p>
                    <!-- <h3>Note:</h3>
                    <p>
                        any <span>DemoStudents</span> existing in courses will also be added to the student 
                        roster in Assign2 <i>but these accounts cannot make submissions</i>.
                    </p> -->
                </div>
                <!-- // -->
                <div class="pt-55">
                    <h2>Marking Tab</h2>
                    <p>
                        Once students have completed assignments, work can be graded by clicking the Marking 
                        tab. The grading interface allows multiple 
                        graders to score and markup either specific questions across all student submissions 
                        or entire student assignments at a time. Grades are entered at the top left either 
                        on a keyboard or with a mobile/tablet friendly keypad:
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-markingPage.png" width="958" alt="">
                    </div>
                    <p>
                        The grading overlay tools offer a number of time-saving workflows to grade assessments 
                        rapidly including the ability to create custom keyboard shortcuts. These features are 
                        covered in detail in <span> <a href="docs-mark-assignment"> Marking Student Submissions in Assign2</a></span>. 
                    </p>
                    <p>
                        Once grades have been entered, scores are reflected on the the students tab 
                        along with links to the student's submission:
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-studentList.png" width="908" alt="">
                    </div>
                </div>
                <!-- // -->
                <div class="pt-55">
                    <h2>Dashboard Tab</h2>
                    <p>
                        After grading, the assignment <strong>Dashboard</strong> shows the overall grading 
                        summary and per question marking progress and statistics:
                    </p>
                    <div>
                        <img src="images/docs-create-assignment/assign2-markSummary1.png" width="958" alt="">
                    </div>
                    <p>
                        The <strong>Dashboard</strong> action menu near the middle right also allows instructors 
                        to refresh the questions grading statistics, make changes to the assignment settings, 
                        and push grades into the LMS. 
                        <!-- For more detail on these functions, see <span>Managing Student Assessments in Assign2</span>. -->
                    </p>
                </div>
            </div>
        </div>
    </section>


</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
};

</script>

<style scoped>
.ytcontainer {
    position: relative;
    width: 100%;
    /* height: 100%; */
    height: 370px;
    padding-bottom: 0px;
}
.ytvideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 640px;
    max-height: 360px;
}
.pt-none {
    padding-top: 0;
}
.mt-25 {
    margin-top: 25px;
}
.ml-20 {
    margin-left: 20px;
}
.submit-assign-section {
    background: #f4f4f5;
    padding: 50px 15px 50px;
    min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
    width: 1140px;
    margin: 0 auto;
}
.submit-assign-section .content-area {
    text-align: left;
}
.submit-assign-section .content-area h1 {
        padding: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
    font-size: 13px;
    color: #92969b;
    font-weight: 300;
    margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
    font-size: 16px;
    color: #2c3e50;
    line-height: 26px;
}
.submit-assign-section .content-area p span {
    color: #2e75bf;
}
.submit-assign-section .content-area .card {
    max-width: 300px;
    padding: 10px 15px;
    background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
    padding: 0;
}
.submit-assign-section .content-area .list-view {
    margin-bottom: 15px;
}
.submit-assign-section .content-area .list-view li {
    font-size: 16px;
    color: #2c3e50;
    list-style: disc;
    line-height: 26px;
    margin-bottom: 10px;
}
.submit-assign-section .content-area .card ul li a {
    font-size: 15px;
    color: #2e75bf;
    font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
    color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
    color: #2c3e50;
    margin-right: 3px;
}
.submit-assign-section .content-area h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    text-decoration: underline;
}
.submit-assign-section .content-area h3 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 10px;
}
.submit-assign-section .content-area img {
    margin-top: 5px;
    margin-bottom: 50px;
    border: 1px solid #d8dbdf;
}
.pt-55 {
    padding-top: 55px;
}
@media (min-width: 920px) and (max-width: 1150px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 919px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
}
@media (min-width: 340px) and (max-width: 767px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .submit-assign-section .content-area h2 {
        line-height: 30px;
    }
    .submit-assign-section .content-area h1 {
        font-size: 22px;
        line-height: 34px;
    }
}
</style>