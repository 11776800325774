<template>
  <div>
      		<main>
			
			<div class="container inner">
				<div class="row">
					
					<div class="col-lg-8 inner-right-md">
						
						<!-- ============================================================= SECTION – HERO ============================================================= -->
					
						<section id="hero">
							<header>
								<h1>We&apos;re Edufide &#x2013; <br>an innovative educational technology company.</h1>
							</header>
						</section>
						
						<!-- ============================================================= SECTION – HERO : END ============================================================= -->
						
						
						<!-- ============================================================= SECTION – WHO WE ARE ============================================================= -->
						
						<section id="who-we-are" class="inner-top-sm">
							<div class="row">
								
								<div class="col-md-6 inner-right-xs inner-bottom-sm">
									<h2>What we do</h2>
									<p>This section is still under construction</p>
									<p>...</p>
								</div><!-- /.col -->
								
								<div class="col-md-6 inner-left-xs inner-bottom-sm">
									<h2>What we believe in</h2>
									<p>This section is still under construction</p>
									<p>...</p>
								</div><!-- /.col -->
								
							</div><!-- ./row -->
						</section>
						
						<!-- ============================================================= SECTION – WHO WE ARE : END ============================================================= -->
						
					</div><!-- ./col -->
					
					<div class="col-lg-4">
						
						<!-- ============================================================= SECTION – TEAM ============================================================= -->
						
						<section id="team" class="light-bg inner-xs inner-left-xs inner-right-xs" style="display: none;">
								
							<h3 class="team-headline sidelines text-center"><span>Who we are</span></h3>
							
							<div class="row thumbs gap-md text-center">
								
								<div class="col-md-6 thumb">
									<figure class="member">
										
										<div class="member-image">
											
											<div class="text-overlay">
												<div class="info">
													<ul class="social">
														<li><a href="#"><i class="icon-s-facebook"></i></a></li>
														<li><a href="#"><i class="icon-s-gplus"></i></a></li>
														<li><a href="#"><i class="icon-s-twitter"></i></a></li>
													</ul><!-- /.social -->
												</div><!-- /.info -->
											</div><!-- /.text-overlay -->
											
											<img src="assets/images/art/human03.jpg">
											
										</div><!-- /.member-image -->
										
										<figcaption class="member-details bordered no-top-border">
											<h3>
												Chris Cavill 
												<span>Creative Director</span>
											</h3>
										</figcaption>
										
									</figure>
								</div><!-- /.col -->
								
								<div class="col-md-6 thumb">
									<figure class="member">
										
										<div class="member-image">
											
											<div class="text-overlay">
												<div class="info">
													<ul class="social">
														<li><a href="#"><i class="icon-s-facebook"></i></a></li>
														<li><a href="#"><i class="icon-s-gplus"></i></a></li>
														<li><a href="#"><i class="icon-s-twitter"></i></a></li>
													</ul><!-- /.social -->
												</div><!-- /.info -->
											</div><!-- /.text-overlay -->
											
											<img src="assets/images/art/human01.jpg">
											
										</div><!-- /.member-image -->
										
										<figcaption class="member-details bordered no-top-border">
											<h3>
												Amber Jones 
												<span>Marketing Director</span>
											</h3>
										</figcaption>
										
									</figure>
								</div><!-- /.col -->
								
							</div><!-- /.row -->
							
							<div class="row thumbs gap-md text-center">
								
								<div class="col-md-6 thumb">
									<figure class="member">
										
										<div class="member-image">
											
											<div class="text-overlay">
												<div class="info">
													<ul class="social">
														<li><a href="#"><i class="icon-s-facebook"></i></a></li>
														<li><a href="#"><i class="icon-s-gplus"></i></a></li>
														<li><a href="#"><i class="icon-s-twitter"></i></a></li>
													</ul><!-- /.social -->
												</div><!-- /.info -->
											</div><!-- /.text-overlay -->
											
											<img src="assets/images/art/human05.jpg">
											
										</div><!-- /.member-image -->
										
										<figcaption class="member-details bordered no-top-border">
											<h3>
												Sophie Adams 
												<span>Technical Director</span>
											</h3>
										</figcaption>
										
									</figure>
								</div><!-- /.col -->
								
								<div class="col-md-6 thumb">
									<figure class="member">
										
										<div class="member-image">
											
											<div class="text-overlay">
												<div class="info">
													<ul class="social">
														<li><a href="#"><i class="icon-s-facebook"></i></a></li>
														<li><a href="#"><i class="icon-s-gplus"></i></a></li>
														<li><a href="#"><i class="icon-s-twitter"></i></a></li>
													</ul><!-- /.social -->
												</div><!-- /.info -->
											</div><!-- /.text-overlay -->
											
											<img src="assets/images/art/human04.jpg">
											
										</div><!-- /.member-image -->
										
										<figcaption class="member-details bordered no-top-border">
											<h3>
												Toby Evans
												<span>Senior Designer</span>
											</h3>
										</figcaption>
										
									</figure>
								</div><!-- /.col -->
								
							</div><!-- /.row -->
							
							<div class="row thumbs gap-md text-center">
								
								<div class="col-md-6 thumb last-bottom">
									<figure class="member">
										
										<div class="member-image">
											
											<div class="text-overlay">
												<div class="info">
													<ul class="social">
														<li><a href="#"><i class="icon-s-facebook"></i></a></li>
														<li><a href="#"><i class="icon-s-gplus"></i></a></li>
														<li><a href="#"><i class="icon-s-twitter"></i></a></li>
													</ul><!-- /.social -->
												</div><!-- /.info -->
											</div><!-- /.text-overlay -->
											
											<img src="assets/images/art/human06.jpg">
											
										</div><!-- /.member-image -->
										
										<figcaption class="member-details bordered no-top-border">
											<h3>
												Amy Williams 
												<span>Designer</span>
											</h3>
										</figcaption>
										
									</figure>
								</div><!-- /.col -->
								
								<div class="col-md-6 thumb last-bottom">
									<figure class="member">
										
										<div class="member-image">
											
											<div class="text-overlay">
												<div class="info">
													<ul class="social">
														<li><a href="#"><i class="icon-s-facebook"></i></a></li>
														<li><a href="#"><i class="icon-s-gplus"></i></a></li>
														<li><a href="#"><i class="icon-s-twitter"></i></a></li>
													</ul><!-- /.social -->
												</div><!-- /.info -->
											</div><!-- /.text-overlay -->
											
											<img src="assets/images/art/human02.jpg">
											
										</div><!-- /.member-image -->
										
										<figcaption class="member-details bordered no-top-border">
											<h3>
												Jennifer Fox
												<span>Project Manager</span>
											</h3>
										</figcaption>
										
									</figure>
								</div><!-- /.col -->
								
							</div><!-- /.row -->
							
						</section>
						
						<!-- ============================================================= SECTION – TEAM : END ============================================================= -->
						
					</div><!-- /.col -->
						
				</div><!-- /.row -->
			</div><!-- /.container -->
			
			<!-- ============================================================= SECTION – VALUES ============================================================= -->
			
			<section id="values" class="tint-bg img-bg-bottom img-bg-softer" style="background-image: url(assets/images/art/slider04.jpg);">
				<div class="container inner-top inner-bottom-sm">
					
					<div class="row">
						<div class="col-lg-8 col-md-9 mx-auto text-center">
							<header>
								<h1>What drives us</h1>
								<p>
									Edufide Inc was founded with one core objective in mind &#x2013; to enhance educational technology while ensuring affordability for academic institutions.</p>
							</header>
						</div><!-- /.col -->
					</div><!-- /.row -->
				
					<div class="row inner-top-sm">
						
						<div class="col-lg-1 col-sm-2 col-3 text-right">
							<div class="icon pos-left">
								<i class="icon-heart-empty-1 icn"></i>
							</div><!-- /.icon -->
						</div><!-- /.col -->
						
						<div class="col-lg-3 col-sm-10 col-9 inner-bottom-xs">
							<h2>Passion</h2>
							<p class="text-small">This section is under construction.</p>
						</div><!-- /.col -->
						
						<div class="col-lg-1 col-sm-2 col-3 text-right">
							<div class="icon pos-left">
								<i class="icon-lamp icn"></i>
							</div><!-- /.icon -->
						</div><!-- /.col -->
					
						<div class="col-lg-3 col-sm-10 col-9 inner-bottom-xs">
							<h2>Creativity</h2>
							<p class="text-small">This section is under construction.</p>
						</div><!-- /.col -->
						
						<div class="col-lg-1 col-sm-2 col-3 text-right">
							<div class="icon pos-left">
								<i class="icon-star-empty-1 icn"></i>
							</div><!-- /.icon -->
						</div><!-- /.col -->
					
						<div class="col-lg-3 col-sm-10 col-9 inner-bottom-xs">
							<h2>Quality</h2>
							<p class="text-small">This section is under construction.</p>
						</div><!-- /.col -->
						
					</div><!-- /.row -->
				
				</div><!-- /.container -->
				
			</section>
			
			<!-- ============================================================= SECTION – OFFICE : END ============================================================= -->
			
			
			<!-- ============================================================= SECTION – REASONS ============================================================= -->
			
			<section id="reasons" class="light-bg">
				<div class="container inner-top inner-bottom-sm">
					<div class="row">
						
						<div class="col-lg-3 inner-right-xs inner-bottom-xs">
							<h2>Why you should work with Edufide</h2>
						</div><!-- /.col -->
						
						<div class="col-lg-3 inner-right-xs inner-bottom-xs">
							<h3>Great value</h3>
							<p>This section is under construction.</p>
						</div><!-- /.col -->
						
						<div class="col-lg-3 inner-right-xs inner-bottom-xs">
							<h3>We like to share</h3>
							<p>This section is under construction.</p>
						</div><!-- /.col -->
						
						<div class="col-lg-3 inner-right-xs inner-bottom-xs">
							<h3>We think you&#x2019;re unique</h3>
							<p>This section is under construction.</p>
						</div><!-- /.col -->
						
					</div><!-- /.row -->
				</div><!-- /.container -->
			</section>
			
			<!-- ============================================================= SECTION – VALUES : END ============================================================= -->
			
			
		</main>
  </div>
</template>

<script>
export default {
    mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
}
</script>

<style>

</style>