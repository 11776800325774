<template>

    <section class="submit-assign-section">
        <div class="containe">
            <div class="content-area">
                <h1>Marking student submissions and exams</h1>
                <p>
                    This article describes how instructors can use the marking interface in Assign2 Online 
                    Assessments.
                </p>
                <div class="card">
                    <h5>Contents:</h5>
                    <ul>
                        <li>
                            <a href="#markingSubmissions">
                                <span>1</span>
                                Marking Submissions
                            </a>
                        </li>
                        <li>
                            <a href="#usingMarkupTools">
                                <span>2</span>
                                Grading Markup Tools
                            </a>
                        </li>
                        <li>
                            <a href="#markingWorkflow">
                                <span>3</span>
                                Marking Workflow Tools
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- /// -->
                <div class="pt-55" id="markingSubmissions">
                    <h2>Marking Student Submissions</h2>
                    <p>
                        Once students have completed assignments, work can be graded by clicking the 
                        <strong>Marking</strong> option from the navigation dropdown at top left.
                    </p>
                    <div class="mb-20">
                        <img src="images/marking-submissions/assign2_EnterMarking.png" width="828" alt="">
                    </div>
                    <p>
                        The grading interface allows multiple graders to score and markup either specific 
                        questions across all student submissions or entire student assignments at a time. 
                        Grades are entered at the top left:
                    </p>
                    <div class="mb-20">
                        <img src="images/marking-submissions/assign2_MarkingInterface.png" width="968" alt="">
                    </div>
                    <p>
                        <strong>New in September 2020:</strong> Instructors can rotate any student submissions 
                        that have been uploaded in the wrong orientation:
                    </p>
                    <div class="mb-20">
                        <img src="images/marking-submissions/assign2_RotateControls.png" width="690" alt="">
                        <img src="images/marking-submissions/assign2_RotateLeft.png" width="720" alt="">
                    </div>
                </div>
                <!-- /// -->
                <div class="pt-55" id="usingMarkupTools">
                    <h2>Using the Markup Tools</h2>
                    <p>
                        The markup tools allow graders to annotate directly onto students’ submissions. These 
                        tools available include the options to draw lines, highlight areas, and add correct 
                        or incorrect flags to students’ submissions. It is also possible to add reusable 
                        comments with the option of designated scores that can be shared across graders. 
                    </p>
                    <p>
                        <strong>New in September 2020:</strong> The following new keyboard shortcuts have 
                        been added to streamline marking tasks using the toolbar:
                    </p>
                    <ul class="list-view">
                        <li>[Shift + t] for text comment</li>
                        <li>[Shift + l] for LaTeX comment</li>
                        <li>[Shift + r] for rotate image</li>
                        <li>[Shift + q] for select question mark</li>
                        <li>[Shift + x] for select  cross X icon</li>
                        <li>[Shift + y] for select tick right icon</li>
                        <li>[Shift + b] for select brush in paint mode</li>
                        <li>[Shift + p] for select pencil in paint mode</li>
                        <li>[Shift + u] for unselect current tool</li>
                        <li>[Shift + f] for toggle flag/unflag selected question</li>
                    </ul>
                    <p>
                        The keyboard shortcuts can be customized to suit individual marker's needs using the 
                        keyboard icon in the top-right, then selecting <strong>Customize Shortcuts</strong>:
                    </p>
                    <div class="mb-20">
                        <img src="images/marking-submissions/assign2_CustomShortcuts.png" width="968" alt="">
                    </div>
                    <p>
                        Specific features of the overlay toolbar are: 
                    </p>
                    <ul class="list-view ml-15">
                        <li><strong>Navigate to Dashboard:</strong></li>
                    </ul>
                    <p class="ml-50">Graders can navigate to the Dashboard page using the back arrow button.</p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_home.png" width="413" alt="">
                    </div>
                    <ul class="list-view ml-15">
                        <li><strong>Color Picker:</strong></li>
                    </ul>
                    <p class="ml-50">
                        Graders can change the color of the pencil and brush (highlighter) tools by selecting 
                        from the color picker.
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_ColourPicker.png" width="938" alt="">
                    </div>
                    <ul class="list-view ml-15">
                        <li><strong>Pencil Tool:</strong></li>
                    </ul>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_Pencil.png" width="906" alt="">
                    </div>
                    <ul class="list-view ml-15">
                        <li><strong>Brush Tool:</strong></li>
                    </ul>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_Brush.png" width="867" alt="">
                    </div>
                    <ul class="list-view ml-15">
                        <li><strong>Correct and Incorrect Flags:</strong></li>
                    </ul>
                    <p class="ml-50">
                        Graders can mark the submissions by placing a checkmark to denote a correct answer or 
                        a cross symbol for the wrong answer.
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_Checkmarks.png" width="877" alt="">
                        <img class="ml-50" src="images/marking-submissions/assign2_WrongFlags.png" width="873" alt="">
                    </div>
                    <ul class="list-view ml-15">
                        <li><strong>Grade Commenting Tools:</strong></li>
                    </ul>
                    <p class="ml-50">
                        Adding Comments allows instructors to re-use common assignment feedback items and 
                        scores on common questions across student submissions. <strong>New in April 2021,</strong> 
                        both text and TeX formatted comments are added through the same icon: 
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_AddComment.png" width="938" alt="">
                    </div>
                    <ul class="list-view ml-15">
                        <li><strong>Saving & Sharing Comments:</strong></li>
                    </ul>
                    <p class="ml-50">
                        By checking <strong>Save,</strong> graders can reuse comments. Checking <strong>Share</strong> 
                        will allow any other other graders in the assignment to also reuse the comment for 
                        this question. Giving the comment a meaningful title is important for ease of re-use and by 
                        entering a score in the <strong>Marks</strong> field, it is possible to automatically add 
                        that score to a question when the comment gets re-used. 
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_SavingComment.png" width="561" alt="">
                    </div>
                    <p class="ml-50">
                        <strong>New in April 2021,</strong> comments can have negative scores added.
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_NegativeComment.png" width="573" alt="">
                    </div>
                    <p class="ml-50">
                        Saved comments are added below the scoring field on the left side. To add a comment 
                        (and associated grade), click the + icon, position the mouse cursor (which switches into 
                        a comment icon) over the submission in the desired spot, the click again to enter it.
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_PlaceComment.png" width="938" alt="">
                    </div>
                    <p class="ml-50">
                        <strong>New in April 2021,</strong> the text or score on a comment can be edited after 
                        creation by clicking the edit icon beside the comment name. Comments can also be 
                        deleted. <strong>Note:</strong> editing the score of a comment after it has already 
                        been used to score assessments will update all grades already entered (though it may 
                        be necessary to refresh your screen to see these change.)
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_EditComment.png" width="938" alt="">
                    </div>
                    <ul class="list-view ml-15">
                        <li><strong>Delete Tool</strong></li>
                    </ul>
                    <p class="ml-50">
                        Graders can remove markings, symbols and comments by selecting the item to delete 
                        then clicking on the delete icon.
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_DeleteElement.png" width="786" alt="">
                    </div>
                    <ul class="list-view ml-15">
                        <li><strong>Quick Grade</strong></li>
                    </ul>
                    <p class="ml-50">
                        The quick grading buttons appear to the right of the student's submission. These are 
                        similar to comments, where you choose one of the buttons that has a positive or 
                        negative score, click somewhere to add it, and it will show the grade 
                        (for example, +3 or -2) and add that to the student's grade.
                    </p>
                    <div class="mb-20">
                        <img class="ml-50" src="images/marking-submissions/assign2_QuickGrade.png" width="938" alt="">
                    </div>
                </div>
                <!-- /// -->
                <div class="pt-55" id="markingWorkflow">
                    <h2>Marking Workflow tools</h2>
                    <p>
                        The right hand column lists all the submissions for an assessment. 
                    </p>
                    <div class="mb-20">
                        <img src="images/marking-submissions/assign2_GradingWorkflow1.png" width="426" alt="">
                    </div>
                    <p>
                        The exams can be sorted using the filters:
                    </p>
                    <div class="mb-20">
                        <img src="images/marking-submissions/assign2_GradingWorkflow2.png" width="416" alt="">
                    </div>
                    <p>
                        Note: Marking is always blind unless <strong>Show student name</strong> is selected.
                    </p>
                </div>
            </div>
        </div>
    </section>


</template>

<script>

export default {
  data() {
    return {};
  },
  mounted() {
    
    let texsvg = document.createElement("script");
    texsvg.setAttribute(
      "src",
      "js/scripts.js"
    );
    document.head.appendChild(texsvg);

  }
};

</script>

<style scoped>
.pt-none {
    padding-top: 0;
}
.mt-25 {
    margin-top: 25px;
}
.ml-15 {
    margin-left: 15px !important;
}
.ml-20 {
    margin-left: 20px;
}
.ml-23 {
    margin-left: 23px;
}
.ml-50 {
    margin-left: 50px !important;
}
.mb-20 {
    margin-bottom: 20px;
}
.r-color {
    color: #ff0000;
}
.flex-box {
    display: flex;
}
.flex-box span {
    margin-right: 8px;
}
.submit-assign-section {
    background: #f4f4f5;
    padding: 50px 15px 50px;
    min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
    width: 1140px;
    margin: 0 auto;
}
.submit-assign-section .content-area {
    text-align: left;
}
.submit-assign-section .content-area h1 {
        padding: 0;
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
    font-size: 13px;
    color: #92969b;
    font-weight: 300;
    margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
    font-size: 16px;
    color: #2c3e50;
    line-height: 26px;
}
.submit-assign-section .content-area p span {
    color: #2e75bf;
}
.submit-assign-section .content-area .card {
    max-width: 320px;
    padding: 10px 15px;
    background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
    padding: 0;
}
.submit-assign-section .content-area .list-view {
    margin-bottom: 15px;
}
.submit-assign-section .content-area .list-view li {
    font-size: 15px;
    color: #2c3e50;
    list-style: disc;
    line-height: 30px;
}
.submit-assign-section .content-area .card ul li a {
    font-size: 15px;
    color: #2e75bf;
    font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
    color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
    color: #2c3e50;
    margin-right: 3px;
}
.submit-assign-section .content-area h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    text-decoration: underline;
}
.submit-assign-section .content-area h3 {
    font-size: 16px;
    color: #2c3e50;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 10px;
}
.submit-assign-section .content-area img {
    margin-left: 23px;
    margin-bottom: 20px;
    border: 1px solid #d8dbdf;
}
.pt-55 {
    padding-top: 55px;
}
.count-details {
    width: 100%;
    max-width: 900px;
    background: #ffffff;
    border: 1px solid #cfd4d6;
}
.count-details .count-box {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #cfd4d6;
}
.count-details .count-box h3 {
    width: 50%;
    font-size: 15px;
    margin-bottom: 0;
    padding: 6px 10px 4px;
}
.count-details .count-box h3 span {
    font-weight: 400;
}
.count-details .count-box p {
    width: 50%;
    font-size: 15px;
    margin-bottom: 0 !important;
    padding: 7px 10px 5px !important;
    border-left: 1px solid #cfd4d6;
}
@media (min-width: 920px) and (max-width: 1150px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 919px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .count-details .count-box {
        flex-direction: column;
    }
    .count-details .count-box h3 {
        width: 100%;
    }
    .count-details .count-box p {
        width: 100%;
        border-left: 0;
    }
}
@media (min-width: 340px) and (max-width: 767px) {
    .submit-assign-section .containe {
        max-width: 100%;
    }
    .submit-assign-section .content-area img {
        margin-left: 0;
        max-width: 100%;
    }
    .submit-assign-section .content-area h2 {
        line-height: 30px;
    }
    .submit-assign-section .content-area h1 {
        font-size: 22px;
        line-height: 34px;
    }
    .ml-23 {
        margin-left: 0;
    }
    .ml-50 {
        margin-left: 0 !important;
    }
    .pt-55 {
        padding-top: 20px;
    }
    .count-details .count-box {
        flex-direction: column;
    }
    .count-details .count-box h3 {
        width: 100%;
    }
    .count-details .count-box p {
        width: 100%;
        border-left: 0;
    }
    .ml-15 {
        margin-left: 0 !important;
    }
}
</style>