<template>
  <main>
    <!-- ============================================================= SECTION – HERO ============================================================= -->

    <section id="contact-form">
      <div class="container">
        <!-- /.row -->

        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 outer-top-md inner-right-sm">
                <h2>Register interest in free Assign2 pilot</h2>

                <div class="row">
                  <div class="col-md-12">
                    <v-text-field
                      v-model="formData.name"
                      type="text"
                      name="name"
                      placeholder="Name (Required)"
                    >
                    </v-text-field>
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->

                <div class="row">
                  <div class="col-md-12">
                    <v-text-field
                      v-model="formData.email"
                      type="email"
                      name="email"
                      placeholder="Email (Required)"
                    ></v-text-field>
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->

                <div class="row">
                  <div class="col-md-12">
                    <v-text-field
                      v-model="formData.university"
                      type="text"
                      name="subject"
                      placeholder="University or College"
                    ></v-text-field>
                  </div>
                  <!-- /.col -->
                </div>
                <!-- /.row -->

                <!-- /.row -->

                <button v-on:click="registerInterest" class="btn btn-submit">
                  Register Interest
                </button>

                <div id="response">{{ emailret }}</div>
              </div>
              <!-- ./col -->

              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container -->
    </section>
    <v-snackbar centered  v-model="snackbar.modal" :timeout="snackbar.timeout">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.modal = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>    
  </main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      snackbar: {
        modal: false,
        timeout: 4000,
        text: "",
      },
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        university: "",
      },
      emailret: "",
    };
  },
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  },
  methods: {
    registerInterest: function () {
      // this.riModal.btnloading = true;
      axios
        .post(
          "https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/",
          {
            name: this.name,
            email: this.email,
            subject: "Free Campus Wide reg",
            message: `Free Campys wide Reg from: ${this.university}`,
          }
        )
        .then(({ data }) => {
          // this.riModal.modal = false;
          // this.riModal.btnloading = false;
          this.snackbar.text = "Thank you for registering your interest. Someone will contact you shortly.";
          this.snackbar.modal = true;
          // console.log(data)
          // this.formData.name = "";
          // this.formData.email = "";
          // this.formData.subject = "";
          // this.formData.message = "";
          // this.emailret = "Message sent. Someone will contact you shortly.";
        });
    },
    postForm: function () {
      console.log(this.formData);
      axios
        .post(
          "https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/",
          this.formData
        )
        .then(({ data }) => {
          //   console.log(data)
          this.formData.name = "";
          this.formData.email = "";
          this.formData.subject = "";
          this.formData.message = "";
          this.emailret = "Message sent. Someone will contact you shortly.";
        });
    },
  },
};
</script>

<style>
</style>