<template>
  <main>
    <!-- ============================================================= SECTION – HERO ============================================================= -->

    <section id="contact-form">
            <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-9 mx-auto text-center">
            <header class="text-left">
              <h1 class="text-center mt-2">Use Assign2 for free</h1>
              <p>
                Let us show you how Assign2 can help you grade 2-4 times faster
                than traditional grading. Educators can use Assign2 for free
                with the following different ways:
              </p>
              <p><strong>1. LTI External Tool from your LMS Course</strong></p>
              <p>Book a walk through below, we will help you set up.</p>
              <p><strong>2. Campus wide intragtion with your LMS</strong></p>
              <p>
                As a promotion for <strong>MathFest 2022</strong>
                Edufide will provide Assign2 free to your entire campus for a
                full academic term.
              </p>
              <p>
                Register your interest for this promotion
                <strong> before 5pm on August 12th 2022</strong> by visiting our
                Booth 313 OR click below
              </p>
              <v-btn class="primary mb-2" href="freeregister">Register Interest</v-btn>
              <p><strong>3. Use Assign2 Course Manager</strong></p>
              <p>Follow the instructions below to create your course.</p>
            </header>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6 outer-top-md inner-right-sm">
                <h2>Book a walk-through</h2>
                <p>
                  Quick, 15 minute, online demo of Assign2. We will walk through
                  setting up your free course.
                </p>
                <vue-calendly
                  url="https://calendly.com/edufide/15demo?hide_event_type_details=1&hide_gdpr_banner=1"
                ></vue-calendly>
              </div>
              <!-- ./col -->

              <div
                class="
                  col-md-6
                  outer-top-md
                  inner-left-sm
                  border-left
                  demorighvideopanel
                "
              >
                <h2>Assign2 Course Manager Instructions</h2>
                <div class="text-left">
                  <p>
                  Assign2 Course Manager was developed recently to provide access to Assign2 without the need of a Learning Management System (LMS).
                  This system is new and under active development for improvements. You can sign up to use Assign2 by following these steps:
                  </p>
                  <p>
                    1. Signup for an account at
                    <a href="https://assign2.edufide.com"
                      >https://assign2.edufide.com</a
                    >.
                  </p>
                  <p>
                    2. Follow instructions on
                    <a href="https://www.edufide.com/docs-create-course"
                      >How to create a course</a
                    >
                    article.
                  </p>
                  <p>
                    3. Contact us at support@edufide.com if you need assistance.
                  </p>
                </div>
                <!-- <iframe
                  id="ytVideo"
                  style="width: 100%; height: 400px"
                  src="https://www.youtube.com/embed/AIuwrVPHumM?enablejsapi=1&origin=https://edufide.com&rel=0"
                  title="Introducting Assign2"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe> -->

                <!-- /.contacts -->
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      <!-- /.container -->
            </div>
    </section>
    <v-snackbar v-model="snackbar.modal" :timeout="snackbar.timeout">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.modal = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      snackbar: {
        modal: false,
        timeout: 4000,
        text: "",
      },
      riModal: {
        modal: false,
        name: "",
        email: "",
        university: "",
        anotheremail: "",
        btnloading: false,
      },
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      emailret: "",
    };
  },
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  },
  methods: {
    registerInterest: function () {
      this.riModal.btnloading = true;
      axios
        .post(
          "https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/",
          {
            name: this.riModal.name,
            email: this.riModal.email,
            subject: "Free Campus Wide reg",
            message: `Free Campys wide Reg from: ${this.riModal.university} otheremail: ${this.riModal.anotheremail}`,
          }
        )
        .then(({ data }) => {
          this.riModal.modal = false;
          this.riModal.btnloading = false;
          this.snackbar.text = "Thank you for Registering your interest";
          this.snackbar.modal = true;
          // console.log(data)
          // this.formData.name = "";
          // this.formData.email = "";
          // this.formData.subject = "";
          // this.formData.message = "";
          // this.emailret = "Message sent. Someone will contact you shortly.";
        });
    },
    postForm: function () {
      console.log(this.formData);
      axios
        .post(
          "https://jkp4c7sryk7a4avtalwplid2km0crwoz.lambda-url.us-east-1.on.aws/",
          this.formData
        )
        .then(({ data }) => {
          //   console.log(data)
          this.formData.name = "";
          this.formData.email = "";
          this.formData.subject = "";
          this.formData.message = "";
          this.emailret = "Message sent. Someone will contact you shortly.";
        });
    },
  },
};
</script>

<style>
@media only screen and (max-width: 760px) {
  .demorighvideopanel {
    margin-top: -70px;
  }
}
</style>