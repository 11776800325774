<template>
  <section class="submit-assign-section">
    <div class="containe">
      <div class="content-area">
        <h1>Replacing proprietary bubblesheets with Assign2</h1>
        <p>
          Assign2 is a simple, easy-to-use online marking tool that enables you
          to grade assignments and tests digitally. It is specifically designed
          to replace the need for proprietary bubblesheets (sometimes known as MCQ or Scantron exams) and provides a more
          efficient and secure way to process such assessments. Assign2 allows you to include bubblesheet questions in your in-person exams, along with other types of questions, in a seamless manner.
        </p>
        <p>
            Assign2 bubblesheet exams are matched to student ID numbers automatically, and each bubblesheet allows for up to 100 multiple choice questions. Bubblesheets are scanned using a standard scanner.
        </p>
        <div class="mb-20">
        <img
            src="images/docs-mcq-only/bubblesheet.png"
            width="600"
            alt=""
        />
        </div>
        <div class="card">
          <h5>Contents:</h5>
          <ul>
            <li>
              <a href="#bubblesheetonly">
                <span>1</span>
                Creating a bubble-sheet only exam
              </a>
            </li>
            <li>
              <a href="#gradingbubblesheets">
                <span>2</span>
                How to grade the bubble-sheets
              </a>
            </li>
            <li>
              <a href="#reviewingstatistics">
                <span>3</span>
                Reviewing MCQ statistics
              </a>
            </li>

          </ul>
        </div>
        <!-- /// -->
        <div class="pt-55" id="bubblesheetonly">
          <h2>Creating a bubble-sheet only exam</h2>
          <p>
            To create a bubble-sheet only exam, simply create an assessment as
            normal. When you get to the assessment creation page, select the <strong>In Person Exam</strong> option.
          </p>
          <div class="mb-20">
            <img
              src="images/docs-mcq-only/selectinpersonexam.png"
              width="600"
              alt=""
            />
          </div>
            <p>
                On the next page, select the <strong>Create MCQ only exam</strong> option. This will create an exam with no other questions, just a bubble-sheet.
            </p>
            <div class="mb-20">
                <img
                src="images/docs-mcq-only/createmcqonlyexam.png"
                width="600"
                alt=""
                />
            </div>
            <p>
                On the next page, add the appropriate total score for all the bubble-sheet questions.
                For example, if you have 10 questions, each worth 2 marks, then the total score should be 20.
            </p>
            <div class="mb-20">
                <img
                src="images/docs-mcq-only/totalmcqscore.png"
                width="600"
                alt=""
                />
            </div>
            <p>
                Click <strong>Save Exam</strong>, and you're done! You can now download, print and distribute the exam to your students.
            </p>
            <p>
                For further information on downloading, printing, and distributing exams, please refer to the <a href="docs-create-print-exam#printingDistributing">Printing and Distributing Assessments to Students</a> page.
            </p>
        </div>
        <!-- /// -->
        <div class="pt-55" id="gradingbubblesheets">
          <h2>How to grade the bubble-sheets</h2>
          <p>
            Review the <a href="docs-create-print-exam#scanningUploading">Scanning and Uploading Assessments</a> page for information on how to scan and upload the bubble-sheets.
          </p>
            <p>
                Once you have uploaded the bubble-sheets, you can mark them using Assign2. To do this, go to the <strong>Dashboard</strong> page, 
                and click the <strong>Edit Multiple Choice Questions</strong> button. 
            </p>
            <div class="mb-20">
                <img
                src="images/docs-mcq-only/editmcq.png"
                width="600"
                alt=""
                />
            </div>

          <p>
            This will open the MCQ setup dialog which will allow you to select the correct response for each question as well as the score for each question.
            An questions that are left blank will not be processed. Once done, click <strong>Save</strong>.
          </p>
            <div class="mb-20">
                <img
                src="images/docs-mcq-only/selectmcqanswers.png"
                width="828"
                alt=""
                />
            </div>
          <p>
            You are now ready to grade the bubble-sheets. To do this, go to the <strong>Dashboard</strong> page, 
            and click the <strong>Score Multiple Choice Questions</strong> button.
          </p>
            <div class="mb-20">
                <img
                src="images/docs-mcq-only/scoremcq.png"
                width="600"
                alt=""
                />
            </div>
            <p>
                Your bubble-sheet exams should now be marked. You can view the results by clicking going to the <strong>Marking</strong> page.
            </p>
            <div class="mb-20">
                <img
                src="images/docs-mcq-only/markingpage.png"
                width="600"
                alt=""
                />
            </div>
            <p>
                <i> Note: that multiple choice answers can be modified at any time by clicking the <strong>Edit Multiple Choice Questions</strong> button on the dashboard. The exams
                can then be re-scored by clicking the <strong>Score Multiple Choice Questions</strong> button. This provides a convenient way to correct mistakes.
                </i>
            </p>

        </div>
        <!-- /// -->
        <div class="pt-55" id="reviewingstatistics">
          <h2>Reviewing MCQ statistics</h2>
          <p>
            You can view the statistics for each Multiple Choice Question by clicking the histogram button on the dashboard.
          </p>
            <div class="mb-20">
                <img
                src="images/docs-mcq-only/histogram.png"
                width="828"
                alt=""
                />
            </div>
          <p>
            On the histogram dialog, click the <strong>View Item Analysis</strong> button to view the statistics for each question.
          </p>
            <div class="mb-20">
                <img
                src="images/docs-mcq-only/itemanalysis.png"
                width="828"
                alt=""
                />
            </div>
            <p>
                The Item Analysis page shows the following information for each question:
            </p>
            <p><strong>Difficulty Index</strong></p>
            <p>
                Difficulty index is a statistical measure used in multiple choice question item analysis to evaluate the level of difficulty of an item. It is typically calculated as the proportion of test takers who answered the item correctly, and it ranges from 0 to 1.
            </p>
            <p>
                If an item has a high difficulty index (closer to 1), it means that a large proportion of test takers answered it correctly, and the item is considered easy. On the other hand, if an item has a low difficulty index (closer to 0), it means that a small proportion of test takers answered it correctly, and the item is considered difficult.
            </p>
            <p>
                In general, items with a difficulty index between 0.3 and 0.7 are considered good items because they are neither too easy nor too difficult. However, the ideal difficulty index may vary depending on the purpose of the test and the target population.
            </p>
            <p>
                By analyzing the difficulty index of multiple choice questions, test developers can evaluate the overall level of difficulty of the test and identify items that may need to be revised or removed. Additionally, they can use the difficulty index to ensure that the test is appropriately challenging for the intended audience.
            </p>

            <p><strong>Discrimination Index</strong></p>
            <p>
                Discrimination index is a statistical measure used in multiple choice question item analysis to evaluate the ability of an item to discriminate between test takers who have different levels of knowledge. It is typically calculated as the difference between the proportion of test takers who answered the item correctly and the proportion of test takers who answered the item incorrectly, and it ranges from 0 to 1. 
            </p>
            <p>
                If an item has a high discrimination index (closer to 1), it means that a large proportion of test takers who answered the item correctly had a higher level of knowledge than those who answered it incorrectly, and the item is considered a good discriminator. On the other hand, if an item has a low discrimination index (closer to 0), it means that a large proportion of test takers who answered the item correctly had a lower level of knowledge than those who answered it incorrectly, and the item is considered a poor discriminator.
            </p>
            <p>
                In general, items with a discrimination index between 0.3 and 0.7 are considered good items because they are neither too easy nor too difficult. However, the ideal discrimination index may vary depending on the purpose of the test and the target population.
            </p>
            <p>
                By analyzing the discrimination index of multiple choice questions, test developers can evaluate the overall ability of the test to discriminate between test takers who have different levels of knowledge and identify items that may need to be revised or removed. Additionally, they can use the discrimination index to ensure that the test is appropriately challenging for the intended audience.
            </p>

            <p><strong>Distribution of responses</strong></p>
            <p>
                The distribution of responses shows the number of students who selected each response option for each question. This can be used to identify questions that may be confusing or ambiguous.
            </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  },
};
</script>

<style scoped>
.pt-none {
  padding-top: 0;
}
.mt-25 {
  margin-top: 25px;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px;
}
.ml-23 {
  margin-left: 23px;
}
.ml-50 {
  margin-left: 50px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.r-color {
  color: #ff0000;
}
.flex-box {
  display: flex;
}
.flex-box span {
  margin-right: 8px;
}
.submit-assign-section {
  background: #f4f4f5;
  padding: 50px 15px 50px;
  min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
  width: 1140px;
  margin: 0 auto;
}
.submit-assign-section .content-area {
  text-align: left;
}
.submit-assign-section .content-area h1 {
  padding: 0;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
  font-size: 13px;
  color: #92969b;
  font-weight: 300;
  margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
  font-size: 16px;
  color: #2c3e50;
  line-height: 26px;
}
.submit-assign-section .content-area p span {
  color: #2e75bf;
}
.submit-assign-section .content-area .card {
  max-width: 320px;
  padding: 10px 15px;
  background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
  padding: 0;
}
.submit-assign-section .content-area .list-view {
  margin-bottom: 15px;
}
.submit-assign-section .content-area .list-view li {
  font-size: 15px;
  color: #2c3e50;
  list-style: disc;
  line-height: 30px;
}
.submit-assign-section .content-area .card ul li a {
  font-size: 15px;
  color: #2e75bf;
  font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
  color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
  color: #2c3e50;
  margin-right: 3px;
}
.submit-assign-section .content-area h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  text-decoration: underline;
}
.submit-assign-section .content-area h3 {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
.submit-assign-section .content-area img {
  margin-left: 23px;
  margin-bottom: 20px;
  border: 1px solid #d8dbdf;
}
.pt-55 {
  padding-top: 55px;
}
.count-details {
  width: 100%;
  max-width: 900px;
  background: #ffffff;
  border: 1px solid #cfd4d6;
}
.count-details .count-box {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #cfd4d6;
}
.count-details .count-box h3 {
  width: 50%;
  font-size: 15px;
  margin-bottom: 0;
  padding: 6px 10px 4px;
}
.count-details .count-box h3 span {
  font-weight: 400;
}
.count-details .count-box p {
  width: 50%;
  font-size: 15px;
  margin-bottom: 0 !important;
  padding: 7px 10px 5px !important;
  border-left: 1px solid #cfd4d6;
}
@media (min-width: 920px) and (max-width: 1150px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 919px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
  .submit-assign-section .content-area img {
    margin-left: 0;
    max-width: 100%;
  }
  .count-details .count-box {
    flex-direction: column;
  }
  .count-details .count-box h3 {
    width: 100%;
  }
  .count-details .count-box p {
    width: 100%;
    border-left: 0;
  }
}
@media (min-width: 340px) and (max-width: 767px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
  .submit-assign-section .content-area img {
    margin-left: 0;
    max-width: 100%;
  }
  .submit-assign-section .content-area h2 {
    line-height: 30px;
  }
  .submit-assign-section .content-area h1 {
    font-size: 22px;
    line-height: 34px;
  }
  .ml-23 {
    margin-left: 0;
  }
  .ml-50 {
    margin-left: 0 !important;
  }
  .pt-55 {
    padding-top: 20px;
  }
  .count-details .count-box {
    flex-direction: column;
  }
  .count-details .count-box h3 {
    width: 100%;
  }
  .count-details .count-box p {
    width: 100%;
    border-left: 0;
  }
  .ml-15 {
    margin-left: 0 !important;
  }
}
</style>