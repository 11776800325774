<template>
  <section class="submit-assign-section">
    <div class="containe">
      <div class="content-area">
        <h1>Managing Student Assignments in Assign2</h1>
        <p>
          This article provides an overview of how instructors can manage their
          Assign2 assignments, ensuring that students have access to the
          assignment, adjusting due dates, releasing grades and feedback to
          students. Before diving into these details, it is recommended to check
          out the article <a href="docs-create-assignment">Creating Online Homework Assignments Using Assign2</a> for a
          comprehensive guide on setting up assignments using the tool. Note
          that this article focuses on the "Homework" type of assessment, not
          the "in-person PDF" type.
        </p>
        <div class="card">
          <h5>Contents:</h5>
          <ul>
            <li>
              <a href="#givingAccess">
                <span>1</span>
                Enabling Student Access to Assignments
              </a>
            </li>
            <li>
              <a href="#managingSubmissions">
                <span>2</span>
                Managing Student Submissions
              </a>
            </li>
            <li>
              <a href="#managingDeadlines">
                <span>3</span>
                Managing Deadlines
              </a>
            </li>
            <li>
              <a href="#releasingGrades">
                <span>3</span>
                Releasing Grades to Students
              </a>
            </li>
            <li>
              <a href="#makingFedback">
                <span>3</span>
                Releasing Feedback to Students
              </a>
            </li>
          </ul>
        </div>
        <!-- /// -->
        <div class="pt-55" id="givingAccess">
          <h2>Giving Students Access to Assignments</h2>
          <p>
            Once your assignment has been saved, it’s important to ensure your
            students can access the assignment. Assignments can be made
            available to students manually by toggling
            <strong>'Open to students':</strong>
          </p>
          <ul class="list-view ml-15">
            <li>
              From the dashboard, click on the <strong>Settings</strong> icon to
              the right of the page:
            </li>
          </ul>
          <div class="mb-20">
            <img
              class="ml-50"
              src="images/managing-assignments/assign2-settings (1).png"
              width="944"
              alt=""
            />
          </div>
          <ul class="list-view ml-15">
            <li>
              Toggle the <strong>Open to students</strong> option on and click
              <strong>Save Settings:</strong>
            </li>
          </ul>
          <div class="mb-20">
            <img
              class="ml-50"
              src="images/managing-assignments/assign2-open.png"
              width="833"
              alt=""
            />
          </div>
          <p>
            Assignments can also be set to open at a specific time through
            <strong>'Set open date'</strong> (available by updating the settings
            <strong><i>after the assignment has been created</i></strong> ) and
            then entering the date and time when the assessment should
            automatically toggle open for student access:
          </p>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-openDate.png"
              width="858"
              alt=""
            />
          </div>
          <p>
            <strong>Note</strong>: Date and time settings are set to the user's
            local time. If you are in a different time zone, please adjust the
            date and time accordingly.
          </p>
        </div>
        <!-- /// -->
        <div class="pt-55" id="managingSubmissions">
          <h2>Managing Student Submissions</h2>
          <p>
            Students are automatically populated in Assign2 when an assessment
            is created. Please note: a known issue prevents students lists from
            populating when access restrictions are set on the external tool
            link. Instructors can hide assessments and release them manually as
            a workaround.
          </p>
          <p>
            Your class list will be displayed in the “Students" tab in your
            dashboard:
          </p>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-dashboardStu.png"
              width="539"
              alt=""
            />
          </div>
          <p>
            If your class list changes (due to students adding or dropping)
            after an assessment is created, you can click the
            <strong>Update Users from LMS</strong> button in the top right
            corner to match up your roster.
          </p>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-updateLMS.png"
              width="968"
              alt=""
            />
          </div>
          <p>The students table has several features:</p>
          <ul class="list-view">
            <li>
              Student submissions appear in the '<strong>Assigned Exam</strong>'
              column - the row will display 'Exam Not Assigned' if they have not
              submitted their assessment.
            </li>
            <li>
              Instructors can locate specific students by searching or sorting
              to find their submission
            </li>
            <li>
              A preview of a student's submission can be viewed by clicking the
              magnifying glass icon beside their assigned exam.
            </li>
            <li>
              The available functions are located in the
              <strong>Actions</strong> column.
            </li>
          </ul>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-action.png"
              width="944"
              alt=""
            />
          </div>
          <ul class="list-view">
            <li>The actions include:</li>
          </ul>
          <ul class="list-view ml-15">
            <li>
              Viewing a student's uploaded files under
              <strong>Preview submission</strong>.
            </li>
            <li>
              Review the full history of the submission and grading actions for
              all markers working on an assessment by selecting
              <strong>View Activity Logs</strong>:
            </li>
          </ul>
          <div class="mb-20">
            <img
              class="ml-40"
              src="images/managing-assignments/assign2-log.png"
              width="920"
              alt=""
            />
          </div>
          <ul class="list-view ml-15">
            <li>
              <strong>Upload a student's submission</strong> in lieu (in case
              they haven't been able tp upload it themselves) so that it can be
              graded online.
            </li>
            <li>Set a different due date and time for a student.</li>
            <li>
              <strong>Note:</strong> Overridden dates and times
              <strong
                >must be added AFTER the questions are created in an
                assessment.</strong
              >
              These can be added initially as placeholders but the number of
              questions <strong>CANNOT</strong>
              be edited after any individual student overrides have been added.
              Adding an override
              <strong
                >will also adjust the submission count for these students on the
                dashboard page</strong
              >
              <strong class="r-color">
                (even though they will not have accessed the assessment)</strong
              >.
            </li>
          </ul>
          <div class="mb-20">
            <img
              class="ml-40"
              src="images/managing-assignments/assign2-override.png"
              width="758"
              alt=""
            />
          </div>
          <p class="ml-15">Overridden students appear in blue:</p>
          <div class="mb-20">
            <img
              class="ml-40"
              src="images/managing-assignments/assign2-overrideBlue.png"
              width="434"
              alt=""
            />
          </div>
        </div>
        <!-- /// -->
        <div class="pt-55" id="managingDeadlines">
          <h2>Managing Deadlines</h2>
          <p>
            If you need to change the date or time the assignment is due or
            change whether or not late submissions are allowed, follow these
            steps:
          </p>
          <ul class="list-view">
            <li>
              Go to the Dashboard page in Assign2 and click on the Settings icon
              to the right of the page.
            </li>
          </ul>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-settings (2).png"
              width="968"
              alt=""
            />
          </div>
          <ul class="list-view">
            <li>
              Update the <strong>Due Date</strong> & <strong>Time</strong> as
              needed and click "Save Settings".
            </li>
            <li>
              You can also change whether late submissions are allowed, make the
              assignment open to students, or make the marked assignment visible
              to students on this page.
            </li>
            <li>
              Date and time settings are set to the instructor's local time.
            </li>
          </ul>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-dueDate.png"
              width="968"
              alt=""
            />
          </div>
        </div>
        <!-- /// -->
        <div class="pt-55" id="releasingGrades">
          <h2>Releasing Grades To Students</h2>
          <p>
            Once all of the assignments are marked instructors can release the
            grades to students by pushing the scores to the LMS gradebook;
            students can also be shown their in-context marks and any added
            feedback for each question directly in Assign2 (<span
              >see below</span
            >).
          </p>
          <p>
            Before releasing grades, ensure that on the Dashboard page, the
            "Fully Marked" column matches the "Total Exams" column.
          </p>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-marked2.png"
              width="885"
              alt=""
            />
          </div>
          <p>
            Once all exams are marked scores must be transferred to the LMS
            by clicking
            <strong>Push Grades</strong>. This will upload each student’s mark
            for the assignment into the gradebook in LMS. Students will see
            their total mark received on the assignment in Gradebook unless you
            have grades set as hidden.
          </p>
          <p>
            On your Dashboard, click the icon to the right of
            <strong>'Fully Marked'</strong> to Push Grades:
          </p>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-push.png"
              width="908"
              alt=""
            />
          </div>
          <p>
            An overlay will confirm if you would like to proceed and give the
            option to inlcude or exclude late penalties. Click
            <strong>“Push Grades”</strong> to confirm the action:
          </p>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-confirmPush.png"
              width="633"
              alt=""
            />
          </div>
        </div>
        <!-- /// -->
        <div class="pt-55" id="makingFedback">
          <h2>Making Grades AND Feedback Visible within Assign2</h2>
          <p>
            Instructors can allow students to view the mark they received on
            each individual question as well as any feedback or annotations you
            provided in Assign2 (in addition to seeing their total score in the
            course gradebook).
            <strong
              >Important note: displaying this contextual feedback to students
              will prevent any further submissions from being made for that
              particular assessment so this action should ONLY be taken after
              all students have completed their work.</strong
            >
          </p>
          <p>To do this:</p>
          <ul class="list-view">
            <li>Go to your Dashboard and click on the Settings icon.</li>
          </ul>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-settings.png"
              width="968"
              alt=""
            />
          </div>
          <ul class="list-view">
            <li>
              Toggle the “<strong>Students can view results</strong>” option on
              and then select “<strong>Save Settings</strong>”.
            </li>
          </ul>
          <div class="mb-20">
            <img
              src="images/managing-assignments/assign2-viewResults.png"
              width="939"
              alt=""
            />
          </div>
          <p>
            <strong>Note</strong>: Students do not automatically receive a
            notification that this has been done so you will need to let them
            know that their results are now available for review in Assign2.
            When they click on the assignment in LMS again it will take them
            directly to their results.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    let texsvg = document.createElement("script");
    texsvg.setAttribute("src", "js/scripts.js");
    document.head.appendChild(texsvg);
  },
};
</script>

<style scoped>
.pt-none {
  padding-top: 0;
}
.mt-25 {
  margin-top: 25px;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px;
}
.ml-23 {
  margin-left: 23px;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.r-color {
  color: #ff0000;
}
.flex-box {
  display: flex;
}
.flex-box span {
  margin-right: 8px;
}
.submit-assign-section {
  background: #f4f4f5;
  padding: 50px 15px 50px;
  min-height: calc(100vh - 182px);
}
.submit-assign-section .containe {
  width: 1140px;
  margin: 0 auto;
}
.submit-assign-section .content-area {
  text-align: left;
}
.submit-assign-section .content-area h1 {
  padding: 0;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}
.submit-assign-section .content-area h6 {
  font-size: 13px;
  color: #92969b;
  font-weight: 300;
  margin: 5px 0 15px 0;
}
.submit-assign-section .content-area p {
  font-size: 16px;
  color: #2c3e50;
  line-height: 26px;
}
.submit-assign-section .content-area p span {
  color: #2e75bf;
}
.submit-assign-section .content-area .card {
  max-width: 320px;
  padding: 10px 15px;
  background: #ffffff;
}
.submit-assign-section .content-area .card h5 {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 5px;
}
.submit-assign-section .content-area .card ul {
  padding: 0;
}
.submit-assign-section .content-area .list-view {
  margin-bottom: 15px;
}
.submit-assign-section .content-area .list-view li {
  font-size: 15px;
  color: #2c3e50;
  list-style: disc;
  line-height: 30px;
}
.submit-assign-section .content-area .card ul li a {
  font-size: 15px;
  color: #2e75bf;
  font-weight: 400;
}
.submit-assign-section .content-area .card ul li a:hover {
  color: #0c55a1;
}
.submit-assign-section .content-area .card ul li a span {
  color: #2c3e50;
  margin-right: 3px;
}
.submit-assign-section .content-area h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  text-decoration: underline;
}
.submit-assign-section .content-area h3 {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
.submit-assign-section .content-area img {
  margin-left: 23px;
  margin-bottom: 20px;
  border: 1px solid #d8dbdf;
}
.pt-55 {
  padding-top: 55px;
}
.count-details {
  width: 100%;
  max-width: 900px;
  background: #ffffff;
  border: 1px solid #cfd4d6;
}
.count-details .count-box {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #cfd4d6;
}
.count-details .count-box h3 {
  width: 50%;
  font-size: 15px;
  margin-bottom: 0;
  padding: 6px 10px 4px;
}
.count-details .count-box h3 span {
  font-weight: 400;
}
.count-details .count-box p {
  width: 50%;
  font-size: 15px;
  margin-bottom: 0 !important;
  padding: 7px 10px 5px !important;
  border-left: 1px solid #cfd4d6;
}
@media (min-width: 920px) and (max-width: 1150px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 919px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
  .submit-assign-section .content-area img {
    margin-left: 0;
    max-width: 100%;
  }
  .count-details .count-box {
    flex-direction: column;
  }
  .count-details .count-box h3 {
    width: 100%;
  }
  .count-details .count-box p {
    width: 100%;
    border-left: 0;
  }
}
@media (min-width: 340px) and (max-width: 767px) {
  .submit-assign-section .containe {
    max-width: 100%;
  }
  .submit-assign-section .content-area img {
    margin-left: 0;
    max-width: 100%;
  }
  .submit-assign-section .content-area h2 {
    line-height: 30px;
  }
  .submit-assign-section .content-area h1 {
    font-size: 22px;
    line-height: 34px;
  }
  .ml-23 {
    margin-left: 0;
  }
  .ml-50 {
    margin-left: 0 !important;
  }
  .pt-55 {
    padding-top: 20px;
  }
  .count-details .count-box {
    flex-direction: column;
  }
  .count-details .count-box h3 {
    width: 100%;
  }
  .count-details .count-box p {
    width: 100%;
    border-left: 0;
  }
  .ml-15 {
    margin-left: 0 !important;
  }
  .ml-40 {
    margin-left: 0 !important;
  }
}
</style>