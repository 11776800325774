<template>
      <footer class="dark-bg">

      <!-- .container -->

      <div class="footer-bottom">
        <div class="container inner clearfix">
          <p class="float-left">&#xA9; 2022 Edufide Inc. All rights reserved.</p>
          <ul class="footer-menu float-right">
            <!-- <li><a href="/">Home</a></li>
            <li><a href="assign2">Assign2</a></li>-->
            <!-- <li><a href="testminder.html">Testminder</a></li> -->
            <!-- <li><a href="stream2">Stream2</a></li>
            <li><a href="beam2.html">Beam 2</a></li>-->
            <li>
              <a href="Edufide_Privacy_Policy.pdf">Privacy Policy</a>
            </li>
            <li>
              <a href="policy-accessibility">Accessibility Policy</a>
            </li>
            <li>
              <a href="EdufideTerms.pdf">Terms and Conditions</a>
            </li>
            <li>
              <router-link to="contact">Contact</router-link>
            </li>
          </ul>
          <!-- .footer-menu -->
        </div>
        <!-- .container -->
      </div>
      <!-- .footer-bottom -->
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>